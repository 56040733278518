/* eslint-disable import/prefer-default-export */
/*
  we want to create a data structure like this
  attachableDocuments = [
    {
      value: 'attachDocument',
      label: 'Documento 1',
      url: 'www.google.cl/descargar-mi-documento.pdf'
    },
    {
      ...
    },
  ]
*/

const invoiceFileTypes = [
  { id: 'PDF', value: 'attachPdf', label: 'PDF 2' },
  { id: 'SignedInvoice', value: 'attachSignedInvoice', label: 'Factura firmada' },
  { id: 'PurchaseOrder', value: 'attachPurchaseOrder', label: 'Orden de compra' },
  { id: 'HES', value: 'attachHes', label: 'HES' },
  { id: 'PaymentStatus', value: 'attachPaymentStatus', label: 'Estado de pago' },
  { id: 'ShippingGuidebook', value: 'attachShippingGuidebook', label: 'Guía de despacho' },
  { id: 'PortalScreenshot', value: 'attachPortalScreenshot', label: 'Screenshot portal' },
  { id: 'Otro', value: 'attachOther', label: 'Otro' },
];

export const documentToAttachableList = (document) => {
  const attachables = [];
  if (document.pdf) {
    attachables.push({
      value: 'attachInvoicePdf',
      label: 'PDF 1',
      url: document.pdf,
    });
  }
  if (document.certificateUrl) {
    attachables.push({
      value: 'attachCessionCertificate',
      label: 'Certificado de cesión',
      url: document.certificateUrl,
    });
  }
  invoiceFileTypes.forEach((fileType) => {
    if (document.invoiceDocuments.some((file) => file.documentType === fileType.id)) {
      attachables.push({
        value: fileType.value,
        label: fileType.label,
        url: document.invoiceDocuments.find((doc) => doc.documentType === fileType.id).url,
      });
    }
  });
  return attachables;
};
