import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import {
  GET_COLLECTION_MAIL_ENUM,
  GET_RATIFICATION_MAIL_ENUM,
  SEND_RATIFICATION_MAIL,
  SEND_COLLECTION_MAIL,
} from '@fingo/lib/graphql';
import { Mail } from '@mui/icons-material';
import useEnum from '../../hooks/useEnum';
import OperativeStepperDialog from '../dialogs/OperativeStepperDialog';
import OperativeMailPreview from '../utils/OperativeMailPreview';

const stepToTitle = {
  0: 'Selecciona tipo de mail',
  1: 'Selecciona contacto',
  2: 'Agrega un comentario',
};

const operationTypeToEmunQuery = {
  ratification: GET_RATIFICATION_MAIL_ENUM,
  collection: GET_COLLECTION_MAIL_ENUM,
};

const sendMailMutationMapper = {
  ratification: SEND_RATIFICATION_MAIL,
  collection: SEND_COLLECTION_MAIL,
};

const OperativeMailButton = ({
  selectedDocuments,
  targetRut,
  operationType,
  documentType,
  setTriggerRefetchDocument,
}) => {
  const ENUM_QUERY = operationTypeToEmunQuery[operationType];
  const SEND_MAIL_MUTATION = sendMailMutationMapper[operationType];
  const [options, selectedOption, setSelectedOption] = useEnum(ENUM_QUERY);
  const [managersAndAttachs, setManagersAndAttachs] = useState([]);
  const [openDialog, toggleDialog, setTrue] = useBooleanState();
  const [currentStep, setCurrentStep] = useState(0);
  const { addAlert } = useSnackBars();
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [sendMail, { loading: loadingMail }] = useMutation(SEND_MAIL_MUTATION, {
    onCompleted: () => {
      setSelectedContactsIds([]);
      setSelectedOption(null);
      toggleDialog();
      setTriggerRefetchDocument(true);
      setCurrentStep(0);
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Mail enviado con éxito',
      });
    },
  });

  const onSubmit = () => sendMail({
    variables: {
      managersAndAttachs,
      contactsIds: selectedContactsIds,
      mailType: selectedOption,
    },
  });

  useEffect(() => {
    if (documentType === 'invoice') {
      setManagersAndAttachs(selectedDocuments.map((doc) => ({
        managerId: doc.collectionManager.id,
        attachInvoicePdf: false,
        attachCessionCertificate: false,
        attachPdf: false,
        attachPurchaseOrder: false,
        attachHes: false,
        attachShippingGuidebook: false,
        attachPortalScreenshot: false,
        attachPaymentStatus: false,
        attachSignedInvoice: false,
      })));
    } else if (documentType === 'ordering') {
      setManagersAndAttachs(selectedDocuments.map((doc) => ({
        managerId: doc.collectionManager.id,
      })));
    }
  }, [selectedDocuments]);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<Mail />}
        sx={{ m: 0.5, height: 30, width: '100%' }}
        onClick={setTrue}
        disabled={selectedDocuments.length === 0}
      >
        <Typography>
          Nuevo mail
        </Typography>
      </Button>
      <OperativeStepperDialog
        open={openDialog}
        onClose={() => {
          setSelectedContactsIds([]);
          setSelectedOption(null);
          toggleDialog();
        }}
        selectedOption={selectedOption}
        selectedContactsIds={selectedContactsIds}
        selectedDocuments={selectedDocuments}
        currentStep={currentStep}
        firstStepOptions={options}
        documentType={documentType}
        managersAndAttachs={managersAndAttachs}
        setSelectedContactsIds={setSelectedContactsIds}
        setCurrentStep={setCurrentStep}
        setOptions={setSelectedOption}
        setManagersAndAttachs={setManagersAndAttachs}
        targetRut={targetRut}
        operationType={operationType}
        lastStepComponent={(
          <OperativeMailPreview
            operationType={operationType}
            managersIds={selectedDocuments.map((doc) => doc.collectionManager.id)}
            mailType={selectedOption}
          />
        )}
        onSubmit={onSubmit}
        loadingSubmit={loadingMail}
        submitButtonText="Enviar correo"
        stepToTitle={stepToTitle}
      />
    </>
  );
};

OperativeMailButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocuments: PropTypes.object.isRequired,
  targetRut: PropTypes.number.isRequired,
  operationType: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  setTriggerRefetchDocument: PropTypes.func.isRequired,
};

export default OperativeMailButton;
