import { gql } from '@apollo/client';

export const SEND_RATIFICATION_MAIL = gql`
mutation SendRatificationMail(
  $contactsIds: [Int]!
  $mailType: String!
  $withCessionCertificate: Boolean! = false
  $managersIds: [Int]!
) {
  sendRatificationMail(
    contactsIds: $contactsIds
    mailType: $mailType
    withCessionCertificate: $withCessionCertificate
    managersIds: $managersIds
  ) {
    success
    message
  }
}
`;

export const SEND_COLLECTION_MAIL = gql`
mutation SendCollectionMail(
  $contactsIds: [Int]!
  $mailType: String!
  $managersAndAttachs: [ManagersAndAttachsType]!
) {
  sendCollectionMail(
    contactsIds: $contactsIds
    mailType: $mailType
    managersAndAttachs: $managersAndAttachs
  ) {
    success
    message
  }
}
`;

export const GET_COLLECTION_MAIL_PREVIEW = gql`
mutation GetCollectionMailPreview(
  $mailType: String!
  $managersIds: [Int]!
) {
  getCollectionMailPreview(
    mailType: $mailType
    managersIds: $managersIds
  ) {
    success
    message
    preview
  }
}
`;

export const GET_RATIFICATION_MAIL_PREVIEW = gql`
mutation GetRatificationMailPreview(
  $mailType: String!
  $managersIds: [Int]!
) {
  getRatificationMailPreview(
    mailType: $mailType
    managersIds: $managersIds
  ) {
    success
    message
    preview
  }
}
`;
