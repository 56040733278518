import { Box } from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/prop-types
const FullHeightBox = ({ children, ...restBoxProps }) => (
  <Box
    height="100%"
    {...restBoxProps}
  >
    {children}
  </Box>
);

export default FullHeightBox;
