/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { backendFiltersTransformation, parseClip } from '../../helpers';
import { DownloadExcelIcon } from '../icons';
import { HtmlTooltip } from '../tooltips';

const FingoTextField = (
  {
    canPasteExcel,
    setColumnFilter,
    setFinderValue,
    onChange,
    ...props
  },
) => {
  const [isExcel, setIsExcel] = useState(false);
  const onChangeExcel = (e) => {
    if (isExcel) {
      setIsExcel(false);
      setColumnFilter({});
    }
  };
  const handlePaste = (e) => {
    const result = parseClip(e.clipboardData.getData('text'), true);
    if (result) {
      setIsExcel(true);
      setFinderValue(result);
      setColumnFilter(backendFiltersTransformation(
        {
          columnField: 'company_MasterEntity_Rut',
          value: result,
          operatorValue: 'isAnyOf',
        },
      ));
      e.preventDefault();
    }
  };

  const innerProps = canPasteExcel ? {
    onPaste: handlePaste,
    InputProps: {
      endAdornment: (
        <HtmlTooltip title={(
          <>
            <Typography color="inherit">Pro-Tip</Typography>
            Pega una columna desde <b>excel</b>{' '}
            para poder filtrar múltiples valores{' '}
            desde el campo <em>rut emisor</em>.
          </>
        )}
        >
          <InputAdornment position="end">
            {isExcel ? <DownloadExcelIcon /> : <Search />}
          </InputAdornment>
        </HtmlTooltip>
      ),
      disableUnderline: true,
    } } : {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      ),
      disableUnderline: true,
    },
  };

  return (
    <TextField
      name="search"
      onDragOver={(e) => { e.preventDefault(); }}
      onChange={(e) => { onChange(e); onChangeExcel(e); }}
      variant="filled"
      fullWidth
      sx={(theme) => ({
        '& input': {
          ...theme.typography.h6,
        },
      })}
      {...innerProps}
      {...props}
    />
  );
};

FingoTextField.propTypes = {
  canPasteExcel: PropTypes.bool,
  setColumnFilter: PropTypes.func,
  setFinderValue: PropTypes.func,
  onChange: PropTypes.func,
};

FingoTextField.defaultProps = {
  canPasteExcel: false,
  setColumnFilter: () => { },
  setFinderValue: () => { },
  onChange: () => { },
};

export default FingoTextField;
