import { Box, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../hooks';
import { AvatarContent } from '../dataDisplay';

const TabNavLink = ({ id, label, path, quantity, index }) => {
  const [isActive, setIsActive] = useState(false);
  const { path: routePath } = useRouteMatch();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const matcher = (match, location) => {
    if (match) return true;
    if (index === 0) {
      return location.pathname === routePath;
    }
    return false;
  };
  return (
    <Link
      id={id}
      key={label}
      component={NavLink}
      to={`${routePath}/${path}`}
      variant="h2"
      sx={(_theme) => ({
        textDecoration: 'none',
        bgcolor: '#CCCCCC',
        color: 'white',
        p: 0.4,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        ml: 2,
        minWidth: 220,
        maxWidth: 280,
        textAlign: 'center',
        '&:first-of-type': {
          ml: 0,
        },
        [_theme.breakpoints.down('md')]: {
          minWidth: 120,
          maxWidth: 120,
          ml: 0.5,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      })}
      activeStyle={{
        backgroundColor: 'white',
        color: theme.palette.primary.main,
      }}
      isActive={(match, location) => {
        const isActiveTemp = matcher(match, location);
        setIsActive(isActiveTemp);
        return isActiveTemp;
      }}
      noWrap
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box>
          {label}
        </Box>
        {quantity !== undefined && !isMobile && (
        <AvatarContent content={quantity} active={isActive} />
        )}
      </Stack>
    </Link>
  );
};

TabNavLink.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  index: PropTypes.number.isRequired,
};

TabNavLink.defaultProps = {
  quantity: undefined,
  id: undefined,
};

export default TabNavLink;
