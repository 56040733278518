import { useState, useEffect, useCallback } from 'react';

const useAvailableHeight = (element) => {
  const [availableHeight, setAvailableHeight] = useState(null);
  const setDefaultHeight = useCallback(() => {
    if (!element) return;
    const parentHeight = element.offsetParent?.offsetHeight || window.innerHeight;
    setAvailableHeight(parentHeight - element.offsetTop);
  }, [element]);
  useEffect(() => {
    if (element) {
      setDefaultHeight();
    }
    window.addEventListener('resize', setDefaultHeight);
    return () => window.removeEventListener('resize', setDefaultHeight);
  }, [element, setDefaultHeight]);
  return availableHeight;
};

export default useAvailableHeight;
