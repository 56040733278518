import React from 'react';
import { FileCopy } from '@mui/icons-material';
import { Badge } from '@mui/material';

const DownloadExcelIcon = () => (
  <Badge badgeContent="xls">
    <FileCopy />
  </Badge>
);

export default DownloadExcelIcon;
