import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, MobileStepper } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { FingoDialog } from '.';

const StepperDialog = ({ open, setOpen, steps, ...dialogProps }) => {
  const [activeStep, setActiveStep] = useState(0);

  const backButtonClickHandler = () => {
    steps[activeStep].backButton?.handler?.();
    if (activeStep === 0) {
      setOpen(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const nextButtonClickHandler = () => {
    steps[activeStep].nextButton?.handler?.();
    if (activeStep === steps.length - 1) {
      setOpen(false);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <FingoDialog
      open={open}
      handleClose={() => { setOpen(false); }}
      title={steps[activeStep].title}
      maxWidth="lg"
      dialogActionButton={(
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          nextButton={(
            <Button
              size="small"
              onClick={nextButtonClickHandler}
              disabled={steps[activeStep].nextButton?.disabled || false}
            >
              {steps[activeStep].nextButton.label} <KeyboardArrowRight />
            </Button>
          )}
          backButton={(
            <Button
              size="small"
              onClick={backButtonClickHandler}
              disabled={steps[activeStep].backButton?.disabled || false}
            >
              <KeyboardArrowLeft /> {steps[activeStep].backButton.label}
            </Button>
          )}
        />
      )}
      {...dialogProps}
    >
      {steps[activeStep].component}
    </FingoDialog>
  );
};

StepperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default StepperDialog;
