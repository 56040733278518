import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Hidden,
} from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import { SiiIcon } from '../../assets';

const FingoQuickFilter = ({ states, setStates, setLoadingSii }) => {
  const arraysEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
  const location = useLocation();
  const history = useHistory();
  const [urlParams] = useState(new URLSearchParams(location.search));

  const buttons = [{
    id: 'rejected',
    key: 'siiStatus',
    title: 'Rechazadas',
    value: ['rejected'],
    EndIcon: SiiIcon,
    color: 'red',
  }, {
    id: 'payed',
    key: 'documentFinanceStatus',
    title: 'Pagadas',
    value: ['6', '7'],
    EndIcon: AttachMoney,
    color: 'green',
  }];
  const [isActive, setIsActive] = useState({});
  const eraser = { ...states, siiStatus: [], documentFinanceStatus: [] };
  const handleOnClick = (key, value) => () => {
    /* Change the external-hook filter */
    if (isActive[key]) {
      setStates({ ...eraser, [key]: [] });
    } else {
      setStates({ ...eraser, [key]: value });
    }
    /* Change the colors of buttons */
    setIsActive({ siiStatus: false, documentFinanceStatus: false, [key]: !isActive[key] });
  };
  const referrer = urlParams.get('referrer');
  useEffect(() => {
    if (referrer) {
      if (referrer.includes('quick-registration')) {
        setStates({ ...eraser, siiStatus: ['rejected'] });
      }
      if (!referrer.includes('logged')) {
        setLoadingSii(true);
      }
      urlParams.delete('referrer');
      history.replace({ search: urlParams.toString() });
    }
  }, [referrer]);
  useEffect(() => {
    if (arraysEqual(states.siiStatus, ['rejected'])) {
      setIsActive({ siiStatus: true, documentFinanceStatus: false });
    }
    if (arraysEqual(states.documentFinanceStatus, ['6', '7'])) {
      setIsActive({ siiStatus: false, documentFinanceStatus: true });
    }
    if (states.siiStatus.length === 0 && states.documentFinanceStatus.length === 0) {
      setIsActive({ siiStatus: false, documentFinanceStatus: false });
    }
  }, [states]);
  return (
    <Hidden mdDown>
      { buttons.map((button) => {
        const { id, key, value, title, color, EndIcon } = button;
        const extraProps = isActive[key] ? { variant: 'contained', color: 'primary' } : {};
        return (
          <Button
            key={`quick-filter-${id}`}
            id={`quick-filter-${id}`}
            startIcon={<EndIcon sx={{ color: isActive[key] ? 'white' : color }} fontSize="small" />}
            color="text"
            size="small"
            sx={{
              backgroundColor: isActive[key] ? 'primary' : 'white',
              borderColor: { md: 'primary.main', xs: 'text.primary' },
              borderStyle: 'solid',
              minWidth: 124,
            }}
            onClick={handleOnClick(key, value)}
            {...extraProps}
          >{title}
          </Button>
        );
      })}
      <Divider orientation="vertical" variant="middle" sx={{ height: '32px', alignSelf: 'center' }} flexItem />
    </Hidden>
  );
};
export default FingoQuickFilter;

FingoQuickFilter.propTypes = {
  setStates: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setLoadingSii: PropTypes.func.isRequired,
};
