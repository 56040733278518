import React from 'react';
import PropTypes from 'prop-types';
import { AttachMoney } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { SiiIcon } from '../../assets';

const colors = {
  finished: '#237A39',
  rejected: '#E40F0F',
  blocked: '#F8B147',
  default: '#D4D4D4',
};

const validDictionary = (receptionDate) => {
  const meritDays = 8 - (moment().diff(receptionDate, 'days'));
  return {
    label: meritDays < 0 ? 'Con mérito' : `${meritDays} días para el mérito ejecutivo`,
    color: meritDays < 0 ? colors.finished : colors.default,
    CustomIcon: SiiIcon,
  };
};
const rejectedDict = { CustomIcon: SiiIcon, color: colors.rejected, label: 'Rechazada' };
const siiStatusMaster = (receptionDate) => ({
  PROCESSING: { CustomIcon: SiiIcon, color: colors.default, label: 'Procesando' },
  REJECTED_BY_SII: rejectedDict,
  REJECTED_RECEIVER: rejectedDict,
  REJECTED_CONTENT: rejectedDict,
  REJECTED_TOTAL: rejectedDict,
  REJECTED_PARTIAL: rejectedDict,
  VALID: validDictionary(receptionDate),
  RECEPTION_ACKNOWLEDGED: { CustomIcon: SiiIcon, color: colors.finished, label: 'Aceptada' },
  PARTIAL_CLAIM: rejectedDict,
  TOTAL_CLAIM: rejectedDict,
  ALREADY_PAYED: { CustomIcon: AttachMoney, color: colors.finished, label: 'Emitida al contado' },
  INVALID_STATUS: validDictionary(receptionDate),
  UNKNOWN_STATUS: validDictionary(receptionDate),
});

const CustomIconStepper = ({ invoice }) => {
  const { receptionDate, siiStatus } = invoice;
  const { CustomIcon, color, label } = siiStatusMaster(receptionDate)[siiStatus];
  return (
    <Stack alignItems="center">
      <CustomIcon size="small" sx={{ color }} fill={color} />
      <Typography align="center" sx={{ fontSize: '10px' }}>{label}</Typography>
    </Stack>
  );
};

export default function StatusSummaryStepperFreemium({ invoice }) {
  return (
    <CustomIconStepper invoice={invoice} />
  );
}

StatusSummaryStepperFreemium.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    receptionDate: PropTypes.objectOf(moment).isRequired,
  }).isRequired,
};
CustomIconStepper.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    receptionDate: PropTypes.objectOf(moment).isRequired,
  }).isRequired,
};
