import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import { UploadDropZoneDialog } from '@fingo/lib/components/dialogs';
import FingoStack from '@fingo/lib/components/stacks/FingoStack';
import { openUrlAndDownload } from '@fingo/lib/helpers';
import { useSnackBars } from '@fingo/lib/hooks';
import { Button } from '@mui/material';
import { Cached } from '@mui/icons-material';
import AssignExternalLeadsDialog from './AssignExternalLeadsDialog';
import AddExternalLeadsDialog from './AddExternalLeadsDialog';
import { UPLOAD_EXTERNAL_LEADS_EXCEL, GET_EXTERNAL_LEADS_TEMPLATE } from '../../../graphql/external-leads';

const ExternalLeadsActions = (props) => {
  const {
    executives,
    loading,
    externalLeads,
    setSelectedExternalLeads,
    refetchExternalLeads,
  } = props;
  const [openAddExternalLeadsDialog, setOpenAddExternalLeadsDialog] = useState(false);
  const [openUploadExcelDialog, setOpenUploadExcelDialog] = useState(false);
  const [openAssignExternalLeadsDialog, setOpenAssignExternalLeadsDialog] = useState(false);
  const [file, setFile] = useState({ name: '' });
  const { addAlert } = useSnackBars();
  const [downLoadExcelTemplate] = useLazyQuery(
    GET_EXTERNAL_LEADS_TEMPLATE,
    {
      onCompleted: (data) => openUrlAndDownload(data.getExternalLeadsTemplate, 'excel_de_ejemplo.xlsx'),
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  const [
    uploadExternalLeadsExcel,
    { loading: uploadExternalLeadsExcelLoading },
  ] = useMutation(
    UPLOAD_EXTERNAL_LEADS_EXCEL,
    {
      onCompleted: ({ uploadExternalLeadsExcel: { newLeads } }) => {
        setFile({ name: '' });
        refetchExternalLeads();
        setOpenUploadExcelDialog(false);
        addAlert({
          id: 'new-leads',
          message: `Se añadieron ${newLeads.length} leads nuevos`,
        });
      },
      onError: () => { console.error('Hubo un error maldito'); }, // eslint-disable-line no-console
    },
  );

  return (
    <FingoStack>
      <LoadingIconButton
        onClick={() => refetchExternalLeads()}
        sx={{ mr: { xs: '0', md: 'auto' } }}
        loading={loading}
        color="gray"
      >
        <Cached sx={{ display: { xs: 'none', md: 'inherit' } }} />
      </LoadingIconButton>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenUploadExcelDialog(true)}
      >
        Subir excel
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenAssignExternalLeadsDialog(true)}
        disabled={!externalLeads.length}
      >
        Asignar leads a un ejecutivo
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenAddExternalLeadsDialog(true)}
      >
        Añadir un lead externo
      </Button>
      <UploadDropZoneDialog
        open={openUploadExcelDialog}
        setOpen={setOpenUploadExcelDialog}
        file={file}
        setFile={setFile}
        message="Carga el excel con los nuevos leads (Formato XLSX o CSV - máximo 10Mb)"
        mutation={uploadExternalLeadsExcel}
        mutationLoading={uploadExternalLeadsExcelLoading}
        downloadTemplate={downLoadExcelTemplate}
      />
      <AssignExternalLeadsDialog
        executives={executives}
        loading={loading}
        open={openAssignExternalLeadsDialog}
        setOpen={setOpenAssignExternalLeadsDialog}
        selectedExternalLeads={externalLeads}
        setSelectedExternalLeads={setSelectedExternalLeads}
        refetchExternalLeads={refetchExternalLeads}
      />
      <AddExternalLeadsDialog
        open={openAddExternalLeadsDialog}
        setOpen={setOpenAddExternalLeadsDialog}
        refetchExternalLeads={refetchExternalLeads}
      />
    </FingoStack>
  );
};

ExternalLeadsActions.propTypes = {
  executives: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  externalLeads: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setSelectedExternalLeads: PropTypes.func.isRequired,
  refetchExternalLeads: PropTypes.func.isRequired,
};

ExternalLeadsActions.defaultProps = {
  executives: null,
  loading: true,
};

export default ExternalLeadsActions;
