import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';
import moment from 'moment';
import { Cached } from '@mui/icons-material';
import { ShoppingCartPreview } from '../banners';
import { DownloadExcelButton } from '../buttons';
import FingoDataGrid from '../dataGrids';
import { PaperHeader } from '../headers';
import { useExportDocument, useIsMobile, useFilteredQuery, useSavedState, useSelectedCompany } from '../../hooks';
import { EXPORT_INVOICES, LOAD_NEW_INVOICES } from '../../graphql';
import { formatYearMonthDay, baseInvoiceFilters, cleanObjectFromUndefined } from '../../helpers';
import { DocumentFilters } from '../filters';
import { LoadingMan } from '../../assets';
import { LoadingDialog } from '../dialogs';

const DocumentList = (
  {
    type,
    headerTitle,
    queryDocument,
    queryExport,
    checkboxSelection,
    isRowSelectable,
    emitter,
    serverFilters,
    initialFilterValue,
    includeHeaders,
    mobileHeaders,
    updateTrigger,
    setUpdateTrigger,
    showExportInvoice,
    financingStatusOptions,
    allCompaniesOptions,
    customVariables,
    shoppingCartPreview,
    showFilters,
    customSummaryElement,
    onCompletedSetLength,
    onCompletedSetRows,
    initialOrderBy,
    noRowsMessage,
    preColumns,
    openJoyride,
    newData,
    readData,
    selectionModel,
    ...restDataGridProps
  },
) => {
  const isMobile = useIsMobile();
  const [globalFilter, _setGlobalFilter] = useState('', `${type}-global-filter`);
  const [invoiceFilters, setInvoiceFilters] = useSavedState(initialFilterValue, `${type}-invoice-filters`);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [dates, setDates] = useState({
    dateIssued_Gte: moment().subtract(2, 'months'),
    dateIssued_Lte: moment(),
  });
  const setGlobalFilter = useCallback((value) => {
    setPage(0);
    _setGlobalFilter(value);
  }, [_setGlobalFilter]);
  const [columnFilter, setColumnFilter] = useSavedState('', `${type}-column-filter`);
  const selectedCompany = useSelectedCompany();
  const [loadNewInvoices] = useMutation(LOAD_NEW_INVOICES, {
    variables: {
      companyId: selectedCompany?.rut,
    },
    onError: () => {},
  });
  const manageFilters = (filters) => {
    const rejectedFilters = ['Rejected content', 'Rejected partial', 'Rejected total', 'Rejected receiver'];
    if (filters.includes('rejected')) {
      const newFilters = filters.filter((e) => e !== 'rejected').concat(rejectedFilters);
      return newFilters;
    }
    return filters;
  };
  const commonVars = cleanObjectFromUndefined({
    ...columnFilter,
    ...invoiceFilters,
    first: pageSize,
    offset: page * pageSize,
    globalFilter,
    orderBy,
    receiver_Rut:
      !emitter && !invoiceFilters.allCompanies ? selectedCompany?.rut : null,
    company_MasterEntity_Rut:
      !!emitter && !invoiceFilters.allCompanies ? selectedCompany?.rut : null,
    allReceiverCompany: !emitter ? Boolean(invoiceFilters.allCompanies) : null,
    allIssuedCompany: emitter ? Boolean(invoiceFilters.allCompanies) : null,
    dateIssued_Gte: formatYearMonthDay(dates.dateIssued_Gte),
    dateIssued_Lte: formatYearMonthDay(dates.dateIssued_Lte),
    loanedStatus: invoiceFilters.loanedStatus,
    creditNoteStatus: invoiceFilters.creditNoteStatus,
    siiStatus: manageFilters(invoiceFilters.siiStatus),
    dteType_Code_In: invoiceFilters.documentType,
    documentfinancestate_Status_Code_In: invoiceFilters.documentFinanceStatus,
    ...customVariables,
  });
  const {
    data: rawData,
    loading,
    refetch: refetchDocuments,
    deletePaginatedAndRefetch,
  } = useFilteredQuery(
    queryDocument,
    {
      variables: { ...commonVars },
      notifyOnNetworkStatusChange: true,
    },
  );
  const readDataAndRefresh = () => {
    deletePaginatedAndRefetch();
    readData();
  };
  const newUpdateDocuments = () => {
    readDataAndRefresh();
    loadNewInvoices();
  };
  const data = rawData ? onCompletedSetRows(rawData) : [];
  const rowCount = rawData ? onCompletedSetLength(rawData) : 0;

  useEffect(() => {
    if (updateTrigger) {
      refetchDocuments();
      setUpdateTrigger(false);
    }
  }, [updateTrigger]);

  const {
    exportDocuments: exportInvoices,
    loading: exportingDocuments,
  } = useExportDocument(queryExport, { ...commonVars, rowCount });

  const columns = preColumns.map((col) => {
    if (col.type === 'number') {
      return {
        ...col,
        filterOperators: getGridNumericOperators().filter(
          (operator) => ['=', '>', '>=', '<', '<='].includes(operator.value),
        ),
      };
    }
    if (col.type === 'date') {
      return {
        ...col,
        filterOperators: getGridDateOperators().filter(
          (operator) => ['is', 'after', 'onOrAfter', 'before', 'onOrBefore'].includes(operator.value),
        ),
      };
    }
    if (col.field === 'company_MasterEntity_Name' || col.field === 'receiver_Name') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter(
          (operator) => ['equals', 'contains', 'startsWith'].includes(operator.value),
        ),
      };
    }
    if (col.type === 'string') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter(
          (operator) => ['equals', 'isAnyOf'].includes(operator.value),
        ),
      };
    }
    if (col.type === 'singleSelect') {
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => ['is', 'isAnyOf'].includes(operator.value),
        ),
      };
    }
    return col;
  });

  const dataGridColumns = columns
    .filter((column) => includeHeaders.includes(column.field))
    .filter((column) => (isMobile ? mobileHeaders.includes(column.field) : true));

  const flexEndButtonsItem = () => {
    if (showExportInvoice) {
      return <DownloadExcelButton downloadFunction={exportInvoices} loading={exportingDocuments} />;
    }
    return null;
  };
  const [loadingSii, setLoadingSii] = useState(false);
  const handleLoadingClose = () => {
    setLoadingSii(false);
  };
  return (
    <>
      <PaperHeader
        type={type}
        viewTitle={headerTitle}
        finder={{
          onFinderChange: setGlobalFilter,
          searchPlaceHolder: 'Búsqueda por folio, rut o nombres',
          finderValue: globalFilter,
          canPasteExcel: true,
          setColumnFilter,
        }}
        openJoyride={openJoyride}
      />
      {shoppingCartPreview && selectionModel.length !== 0 && (
      <ShoppingCartPreview cartType={type} />
      )}
      {showFilters && (
        <DocumentFilters
          dates={dates}
          setDates={setDates}
          financingStatusOptions={financingStatusOptions}
          allCompaniesOptions={allCompaniesOptions}
          states={invoiceFilters}
          setStates={setInvoiceFilters}
          showQuickFilters={type === 'my-sales'}
          flexEndButtons={flexEndButtonsItem()}
          canPasteExcel
          showRefresh
          onRefresh={newUpdateDocuments}
          loading={loading}
          setLoadingSii={setLoadingSii}
        />
      )}
      {customSummaryElement}
      {!!newData && (
        <Box display="flex" justifyContent="center" mt={1} position="absolute" width="100%">
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<Cached />}
            onClick={readDataAndRefresh}
            sx={{
              height: 25,
              zIndex: 100,
            }}
          >
            Tienes {newData} documentos nuevos
          </Button>
        </Box>
      )}

      {loadingSii && loading && (
      <LoadingDialog
        open={loading}
        title="¡No te vayas!"
        img={LoadingMan}
        handleClose={handleLoadingClose}
        description="Estamos trayendo las facturas desde el SII,
          por lo que te pedimos unos segundos para que carguen"
      />
      )}
      <FingoDataGrid
        serverFilters={serverFilters}
        loadingWithSkeleton={loading}
        rows={data}
        columns={dataGridColumns}
        rowCount={rowCount}
        orderBy={orderBy}
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateIssued', sort: 'desc' }],
          },
        }}
        pageSize={pageSize}
        disableSelectionOnClick
        setColumnFilter={setColumnFilter}
        setOrderBy={setOrderBy}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection={checkboxSelection}
        isRowSelectable={isRowSelectable}
        noRowsMessage={noRowsMessage}
        {...restDataGridProps}
      />
    </>
  );
};

DocumentList.propTypes = {
  type: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryDocument: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preColumns: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  queryExport: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  emitter: PropTypes.bool.isRequired,
  initialFilterValue: PropTypes.shape({
    documentType: PropTypes.arrayOf(PropTypes.number),
    creditNoteStatus: PropTypes.arrayOf(PropTypes.string),
    siiStatus: PropTypes.arrayOf(PropTypes.string),
    documentFinanceStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  mobileHeaders: PropTypes.arrayOf(PropTypes.string),
  showExportInvoice: PropTypes.bool,
  financingStatusOptions: PropTypes.bool,
  updateTrigger: PropTypes.bool,
  setUpdateTrigger: PropTypes.func,
  serverFilters: PropTypes.bool,
  allCompaniesOptions: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customVariables: PropTypes.object,
  showFilters: PropTypes.bool,
  shoppingCartPreview: PropTypes.bool,
  customSummaryElement: PropTypes.element,
  // Function that receives row and newValue
  onCompletedSetLength: PropTypes.func,
  onCompletedSetRows: PropTypes.func,
  initialOrderBy: PropTypes.string,
  noRowsMessage: PropTypes.element,
  openJoyride: PropTypes.func,
  newData: PropTypes.number,
  readData: PropTypes.func,
  selectionModel: PropTypes.arrayOf(PropTypes.string),
};

DocumentList.defaultProps = {
  checkboxSelection: false,
  isRowSelectable: () => true,
  initialFilterValue: baseInvoiceFilters,
  queryExport: EXPORT_INVOICES,
  includeHeaders: [],
  preColumns: [],
  updateTrigger: false,
  setUpdateTrigger: () => null,
  serverFilters: true,
  mobileHeaders: [],
  showExportInvoice: false,
  financingStatusOptions: false,
  allCompaniesOptions: false,
  customVariables: {},
  showFilters: false,
  shoppingCartPreview: false,
  customSummaryElement: null,
  onCompletedSetLength: (data) => data.invoices.totalCount,
  onCompletedSetRows: (data) => data.invoices.edges.map((edge) => (edge.node)),
  initialOrderBy: '-dateIssued',
  noRowsMessage: (
    <>
      <Typography variant="h6" paragraph>
        No posees documentos
      </Typography>
      <Typography variant="body1" paragraph>
        Prueba cambiando los filtros o sincroniza tus credenciales con nosotros
        para que puedas tener la experiencia Fingo
      </Typography>
    </>
  ),
  openJoyride: undefined,
  newData: 0,
  readData: () => {},
  selectionModel: [],
};

export default DocumentList;
