import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import FingoDataGrid from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import AsyncSelect from '@fingo/lib/components/selects/AsyncSelect';
import { useSnackBars } from '@fingo/lib/hooks';
import { Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DATA_GRID_COLUMNS } from '../../../constants/external-leads';
import { ASSIGN_EXTERNAL_LEADS } from '../../../graphql/external-leads';

const AssignExternalLeadsDialog = (props) => {
  const {
    executives,
    loading,
    open,
    setOpen,
    selectedExternalLeads,
    setSelectedExternalLeads,
    refetchExternalLeads,
  } = props;
  const [selectedExecutive, setSelectedExecutive] = useState(null);
  const { addAlert } = useSnackBars();
  const [assignExternalLeads, { loading: assignExternalLeadsLoading }] = useMutation(
    ASSIGN_EXTERNAL_LEADS,
    {
      onCompleted: ({ assignExternalLeads: { assignedLeads } }) => {
        setSelectedExternalLeads([]);
        refetchExternalLeads();
        setOpen(false);
        const { executiveAssigned } = assignedLeads[0].masterEntity.company;
        const executiveFullName = `${executiveAssigned.firstName} ${executiveAssigned.lastName}`;
        addAlert({
          id: 'assigned-leads',
          message: `Se asignaron ${assignedLeads.length} leads a ${executiveFullName}`,
        });
      },
    },
  );

  const assignExternalLeadsHandler = () => {
    assignExternalLeads({
      variables: {
        executiveId: selectedExecutive.value,
        externalLeadIds: selectedExternalLeads.map((el) => el.id),
      },
    });
  };

  const handleDelete = ({ id }) => {
    if (selectedExternalLeads?.length === 1) {
      setSelectedExternalLeads([]);
      setOpen(false);
    }
    setSelectedExternalLeads(
      selectedExternalLeads
        ?.map((el) => el.id)
        ?.filter((leadId) => leadId !== id),
    );
  };

  const options = executives?.map((executive) => ({
    label: `${executive.user.firstName} ${executive.user.lastName}`,
    value: executive.user.id,
  }));

  const columns = [
    ...DATA_GRID_COLUMNS,
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete External Lead"
          onClick={() => handleDelete(params.row)}
        />,
      ],
    },
  ];

  const dialogButtonDisabled = !selectedExecutive
    || !selectedExternalLeads?.length
    || assignExternalLeadsLoading;

  return (
    <FingoDialog
      open={open}
      handleClose={() => setOpen(false)}
      title="Asignar leads externos a un ejectuivo"
      dialogActionButton={(
        <Button
          color="primary"
          onClick={assignExternalLeadsHandler}
          disabled={dialogButtonDisabled}
        >
          Asignar leads
        </Button>
      )}
      maxWidth="md"
      fullWidth
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        alignItems="flex-end"
        spacing={4}
        mt={2}
      >
        <AsyncSelect
          id="executive"
          options={options}
          loading={loading}
          value={selectedExecutive}
          setValue={setSelectedExecutive}
          label="Selecciona un ejecutivo"
        />
        <FingoDataGrid
          rows={selectedExternalLeads}
          columns={columns}
          autoHeight
          density="compact"
        />
      </Stack>
    </FingoDialog>
  );
};

AssignExternalLeadsDialog.propTypes = {
  executives: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedExternalLeads: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setSelectedExternalLeads: PropTypes.func.isRequired,
  refetchExternalLeads: PropTypes.func.isRequired,
};

AssignExternalLeadsDialog.defaultProps = {
  executives: null,
  loading: true,
};

export default AssignExternalLeadsDialog;
