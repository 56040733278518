import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { actionTypeToSpanish } from '../../helpers';

const ActionCell = ({ action }) => (
  <>
    {action ? (
      <Grid container alignItems="center" justifyContent="flex-start" overflow="hidden">
        <Tooltip
          title={action.comment}
          placement="left"
          arrow
        >
          <Stack>
            <Typography>
              {actionTypeToSpanish[action.actionType]}
            </Typography>
            <Typography variant="body2" noWrap>
              {action.comment}
            </Typography>
            <Typography variant="caption">
              {moment(action.createdAt).format('DD-MM-YYYY HH:MM[hrs]')}
            </Typography>
          </Stack>
        </Tooltip>
      </Grid>
    ) : (
      <Grid container alignItems="center" justifyContent="center" overflow="hidden">
        <Typography>Sin gestiones</Typography>
      </Grid>
    )}
  </>
);

ActionCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.object.isRequired,
};

export default ActionCell;
