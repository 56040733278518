import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Evaluations } from '../components';

const riskRoutes = [
  {
    label: 'Evaluación de riesgo',
    path: 'executive-manager',
    icon: <DescriptionOutlinedIcon />,
    component: <Evaluations />,
  },
];

export default riskRoutes;
