import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
  Typography,
  TextField,
  Grid,
  InputAdornment,
} from '@mui/material';

const ExecutiveProportionsDialog = ({ open, handleClose, executives, handleUpdateExecutive }) => {
  const [assignationType, setAssignationType] = useState('loginAssignationProportion');
  const [selectedExecutives, setSelectedExecutives] = useState([]);

  useEffect(() => {
    const assignationKey = assignationType.includes('login') ? 'loginActiveAssignation' : 'clickActiveAssignation';
    setSelectedExecutives(
      executives?.filter((executive) => executive.active && executive[assignationKey]),
    );
  }, [executives, assignationType]);

  const updateDisabled = selectedExecutives
    ?.reduce((sum, row) => sum + parseFloat(row[assignationType]), 0.0) !== 1;

  const setProportionType = (event, id) => {
    setSelectedExecutives(selectedExecutives?.map((executive) => {
      if (executive.user.id === id) {
        return {
          ...executive,
          [assignationType]: event.target.value,
        };
      }
      return executive;
    }));
  };

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Editar Proporciones de Asignación de Ejecutivos"
      dialogActionButton={(
        <Button
          color="primary"
          onClick={() => {
            handleUpdateExecutive(assignationType, selectedExecutives);
            handleClose();
          }}
          disabled={updateDisabled}
        >
          Guardar
        </Button>
      )}
    >
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="active-executive">Tipo de Asignacion</InputLabel>
          <Select
            labelId="active-executive"
            id="demo-simple-select"
            label="Ejecutivo Activo"
            value={assignationType}
            onChange={(event) => setAssignationType(event.target.value)}
          >
            <MenuItem value="loginAssignationProportion">Login</MenuItem>
            <MenuItem value="clickAssignationProportion">Click</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2} mt={1}>
        {selectedExecutives?.map((executive) => {
          const {
            user: {
              id,
              firstName,
              lastName,
            },
          } = executive;
          return (
            <Grid item key={id} xs={6}>
              <Typography>{firstName} {lastName}</Typography>
              <TextField
                fullWidth
                variant="standard"
                onChange={(e) => setProportionType(e, id)}
                value={executive[assignationType]}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </FingoDialog>
  );
};

ExecutiveProportionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  executives: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  handleUpdateExecutive: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ExecutiveProportionsDialog;
