import { Badge, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Feed } from '@mui/icons-material';
import { ActionsDrawer } from '../drawer';

const ActionDrawerCell = ({ masterEntityData, actions }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <Grid container alignItems="center" justifyContent="center">
      <IconButton
        onClick={() => setDrawerOpen(true)}
      >
        <Badge color="primary" badgeContent={actions.length}>
          <Feed />
        </Badge>
      </IconButton>
      <ActionsDrawer
        masterEntityData={masterEntityData}
        actions={actions}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </Grid>
  );
};

ActionDrawerCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  masterEntityData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array.isRequired,
};

export default ActionDrawerCell;
