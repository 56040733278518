import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoPaper } from '@fingo/lib/components/layout';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { UPDATE_USER_INFO } from '@fingo/lib/graphql';
import { formatRut } from '@fingo/lib/helpers/forms';
import { useBooleanState, useGetUser } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, TextField, Typography } from '@mui/material';

const EditingGrid = ({ title, user, getAttribute, onChangeInput, isRut, errorMsg }) => {
  const format = (value) => {
    if (isRut) return formatRut(String(value));
    return value;
  };
  const [state, setState] = useState(format(getAttribute(user) || ''));
  const onChange = (event) => {
    const newValue = event.target.value;
    if (isRut && newValue.length) {
      const regex = /[0-9kK]/g;
      if (!newValue.replaceAll('-', '').slice(-1).match(regex)) return;
    }
    onChangeInput(format(newValue));
    setState(format(newValue));
  };
  return (
    <Grid item xs={6} sm={6} md={4}>
      <Typography noWrap paragraph gutterBottom variant="h6" fontWeight={700}>{title}</Typography>
      <TextField
        variant="standard"
        value={state}
        disabled={!onChangeInput}
        error={!!errorMsg}
        helperText={errorMsg}
        onChange={onChange}
      />
    </Grid>
  );
};

EditingGrid.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getAttribute: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func,
  isRut: PropTypes.bool,
  errorMsg: PropTypes.string,
};

EditingGrid.defaultProps = {
  onChangeInput: null,
  isRut: false,
  errorMsg: null,
};

const Info = () => {
  const userObject = useGetUser();
  const [attributeChanges, setAttributeChanges] = useState({});
  const [snackbarOpen, toggleSnackbar] = useBooleanState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [updateUserInfo, { error, loading }] = useMutation(
    UPDATE_USER_INFO,
    {
      onError: () => {
        setSnackbarMessage('Ha ocurrido un error. Revise que los datos sean correctos e intente nuevamente.');
        toggleSnackbar();
      },
      onCompleted: () => {
        setAttributeChanges({});
        setSnackbarMessage('Sus cambios fueron guardados exitosamente.');
        toggleSnackbar();
      },
    },
  );
  const settingAttribute = (attribute) => (
    (newValue) => setAttributeChanges((prev) => ({ ...prev, [attribute]: newValue })));
  const editingGrids = [
    {
      title: 'Empresa',
      getAttribute: (user) => user.selectedCompany?.name,
    },
    {
      title: 'Nombre',
      getAttribute: (user) => user.firstName,
      onChangeInput: settingAttribute('firstName'),
    },
    {
      title: 'Apellido',
      getAttribute: (user) => user.lastName,
      onChangeInput: settingAttribute('lastName'),
    },
    {
      title: 'Teléfono de contacto',
      getAttribute: (user) => user.phoneNumber,
      onChangeInput: settingAttribute('phoneNumber'),
    },
    {
      title: 'Correo de contacto',
      getAttribute: (user) => user.mailProvided,
      onChangeInput: settingAttribute('mailProvided'),
    },
    {
      title: 'Rut representante legal',
      getAttribute: (user) => user.completeRut,
      onChangeInput: settingAttribute('completeRut'),
      isRut: true,
      errorMsg: error && error.message,
    },
  ];
  return (
    <>
      <Stack spacing={4} sx={{ mt: 0 }}>
        <PaperHeader viewTitle="Mi información" />
        <FingoDivider />
        <Typography paragraph gutterBottom>
          Esta es tu información y la de tu empresa que serán necesarias para operar con nosotros
        </Typography>
        <Grid container rowSpacing={4}>
          {editingGrids.map((editing) => (
            <EditingGrid
              {...editing}
              user={userObject}
              key={editing.title}
            />
          ))}
        </Grid>
        <LoadingButton
          disabled={!Object.keys(attributeChanges).length}
          variant="contained"
          color="primary"
          size="small"
          loading={loading}
          onClick={() => updateUserInfo({ variables: attributeChanges })}
          sx={{
            alignSelf: { xs: 'inherit', sm: 'end' },
            maxWidth: { xs: 'inherit', sm: 'fit-content' },
          }}
        >
          Guardar
        </LoadingButton>
      </Stack>
      <FingoSnackBar
        open={snackbarOpen}
        setOpen={toggleSnackbar}
        message={snackbarMessage}
        severity="error"
      />
    </>
  );
};

export default () => (
  <FingoPaper menuOptions={[
    {
      label: 'Mi información',
      path: 'my-profile',
      component: <Info />,
    },
  ]}
  />
);
