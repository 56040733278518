/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { InvoiceMessageFields } from '../fragments';

export const NOTIFICATION_LIST = gql`
  query getNotifications($first: Int, $offset: Int, $unread: Boolean, $globalFilter: String, $timestamp_Lte: DateTime , $timestamp_Gte: DateTime) {
    getNotifications(first: $first, offset: $offset, unread: $unread, globalFilter: $globalFilter, timestamp_Lte:$timestamp_Lte, timestamp_Gte:$timestamp_Gte) {
      totalPages
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          timestamp
          level
          unread
          actorStr
          verbStr
          targetStr
          redirectionPath
        }
      }
    }
  }
`;

export const INVOICE_MESSAGES_QUERY = gql`
  query InvoiceMessage($first: Int, $after: String, $invoiceId: Int!, $messageType: String) {
    invoiceMessages(first: $first, after: $after, invoiceId: $invoiceId, messageType: $messageType) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ... InvoiceMessageFields
        }
      }
    }
  }
  ${InvoiceMessageFields}
`;

export const USER_MENTIONS_QUERY = gql`
  query userMentions($invoiceId: Int!, $messageType: String) {
    userDisplayForMentionInvoice(invoiceId: $invoiceId, messageType: $messageType) {
      id
      display
    }
  }
`;

export const UPDATE_COLLECTION_CONTACT = gql`
  mutation UpdateCollectionContact(
    $contactId: String!,
    $email: String,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String,
    $position: String,
  ) {
    updateCollectionContact(
      contactId: $contactId,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      position: $position,
    ) {
      success
    }
  }
`;

export const CREATE_COLLECTION_CONTACT = gql`
  mutation CreateCollectionContact(
    $companyRut: Int!,
    $email: String,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String,
    $position: String,
    $source: CollectionContactSourceEnum!,
  ) {
    createCollectionContact(
      companyRut: $companyRut,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      position: $position,
      source: $source,
    ) {
      success
    }
  }
`;