import { gql } from '@apollo/client';
import { UserFields, NotificationMenuFields } from '../fragments';

export const GET_USER = gql`
query GetUser {
  getUser {
    ...UserFields
    ...NotificationMenuFields
  }
}
  ${UserFields} 
  ${NotificationMenuFields}
`;

export const GET_PREFERENCES = gql`
  query{
    getUserPreferences{
      notifications_MentionsMail
      notifications_CessionMail
      notifications_AcceptanceMail
      notifications_RejectionMail
      supplierAutomate_BannedCompanies {
        rut
        name
      }
    }  
  }
`;

export const REGISTER_SENEGOCIA_PREFORM = gql`
query RegisterSenegocia($token: String!) {
  senegociaRegisterInfo(token: $token){
    user {
      firstName
      lastName
      email
      rut
      phoneNumber
    }
    company {
      rut,
      name
    }
  }
}`;

export const VALID_TOKEN = gql`
  query validToken($token: String!){
    validToken(token:$token){
      valid
    }
  }
`;
