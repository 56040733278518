import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MASTER_ENTITIES } from '@fingo/lib/graphql';
import FingoDataGrid from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import collectionDebtorSetRow from '../../helpers/collection-debtor-set-row';
import collectionDebtorColumns from '../../constants/collection-debtor-columns';

const DebtorsList = () => {
  const [masterEntities, setMasterEntities] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const handleSearchChange = (value) => {
    setGlobalFilter(value);
  };
  const { loading } = useQuery(GET_MASTER_ENTITIES, {
    variables: {
      inCollection: true,
      first: pageSize,
      offset: page * pageSize,
      globalFilter,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMasterEntities(() => collectionDebtorSetRow(data));
      setRowCount(data.getMasterEntities.totalCount);
    },
  });
  return (
    <>
      <PaperHeader
        viewTitle="Lista de deudores"
        finder={{
          onFinderChange: handleSearchChange,
          searchPlaceHolder: 'Buscar deudor',
          finderValue: globalFilter,
        }}
      />
      <FingoDataGrid
        rows={masterEntities}
        columns={collectionDebtorColumns()}
        rowCount={rowCount}
        loadingWithSkeleton={loading}
        page={page}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </>
  );
};

export default DebtorsList;
