/* eslint-disable import/prefer-default-export */
export const actionTypeToSpanish = {
  PHONE_CALL_TO_RECEIVER: 'Llamada al receptor',
  PHONE_CALL_TO_ISSUER: 'Llamada al emisor',
  MAIL_TO_RECEIVER: 'Correo al receptor',
  MAIL_TO_ISSUER: 'Correo al emisor',
  RECEIVER_FORM_MAIL: 'Correo formulario al receptor',
  ISSUER_FORM_MAIL: 'Correo formulario al emisor',
  AUTOMATIC_MAIL_TO_RECEIVER: 'Correo automático receptor',
  AUTOMATIC_MAIL_TO_ISSUER: 'Correo automático emisor',
  OTHER: 'Otra gestión',
};
