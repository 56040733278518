import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { AttachMoney, MenuBook } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { SiiIcon } from '../../assets';
import { HtmlTooltip } from '../tooltips';

const colors = {
  finished: '#237A39',
  rejected: '#E40F0F',
  blocked: '#F8B147',
  default: '#D4D4D4',
};

const validDictionary = (receptionDate) => {
  const meritDays = 8 - (moment().diff(receptionDate, 'days'));
  return {
    label: meritDays < 0 ? 'Con mérito' : `${meritDays} días para el mérito ejecutivo`,
    color: meritDays < 0 ? colors.finished : colors.default,
    last: 'sii',
  };
};

const siiStatusMaster = (receptionDate) => ({
  PROCESSING: { last: 'sii', color: colors.default, label: 'En proceso' },
  REJECTED_BY_SII: { last: 'sii', color: colors.rejected, label: 'Rechazado por SII' },
  REJECTED_RECEIVER: { last: 'sii', color: colors.rejected, label: 'Rechazado por Receptor' },
  REJECTED_CONTENT: { last: 'sii', color: colors.rejected, label: 'Con reclamos al contenido' },
  REJECTED_TOTAL: { last: 'sii', color: colors.rejected, label: 'Con reclamos por falta total' },
  REJECTED_PARTIAL: { last: 'sii', color: colors.rejected, label: 'Con reclamos por falta parcial' },
  VALID: validDictionary(receptionDate),
  RECEPTION_ACKNOWLEDGED: { last: 'sii', color: colors.finished, label: 'Con acuse de recibo' },
  PARTIAL_CLAIM: { last: 'sii', color: colors.rejected, label: 'Con rechazo por falta parcial de mercaderías' },
  TOTAL_CLAIM: { last: 'sii', color: colors.rejected, label: 'Con rechazo por falta total de mercaderías' },
  ALREADY_PAYED: { last: 'sii', color: colors.finished, label: 'Emitida al contado' },
  INVALID_STATUS: validDictionary(receptionDate),
  UNKNOWN_STATUS: validDictionary(receptionDate),
});

const apiMaster = [
  { last: 'sii', color: colors.rejected },
  { last: 'accounting', color: colors.blocked },
  { last: 'payments', color: colors.blocked },
  { last: 'accounting', color: colors.finished },
  { last: 'payments', color: colors.finished },
  { last: 'payments', color: colors.finished },
  { last: 'payments', color: colors.finished },
  { last: 'sii', color: colors.default },
];

const CustomIconStepper = ({ current, lastApiState, receptionDate, siiStatus }) => {
  const [iconColor, setIconColor] = useState(null);
  const finishedSteps = {
    sii: [],
    accounting: ['sii'],
    payments: ['sii', 'accounting'],
  };

  const Icon = {
    sii: SiiIcon,
    accounting: MenuBook,
    payments: AttachMoney,
  }[current];
  useEffect(() => {
    if (lastApiState && lastApiState.rejectionCode) {
      const { last, color } = { last: 'sii', color: colors.rejected };
      setIconColor(() => {
        if (last === current) return color;
        if (finishedSteps[last].includes(current)) return colors.finished;
        return colors.default;
      });
    } else if (lastApiState && lastApiState.status) {
      const { last, color } = apiMaster[lastApiState.status.code - 1];
      setIconColor(() => {
        if (last === current) return color;
        if (finishedSteps[last].includes(current)) return colors.finished;
        return colors.default;
      });
    } else {
      const { last, color } = siiStatusMaster(receptionDate)[siiStatus];
      setIconColor(() => {
        if (last === current) return color;
        if (finishedSteps[last].includes(current)) return colors.finished;
        return colors.default;
      });
    }
  }, [lastApiState, current, finishedSteps, receptionDate, siiStatus]);
  return (
    <Avatar sx={{ backgroundColor: iconColor, width: 28, height: 28 }}>
      <Icon fontSize="8px" sx={{ color: 'white' }} />
    </Avatar>
  );
};

export default function StatusSummaryStepperPremium({ invoice }) {
  const { receptionDate, siiStatus, uniqueDocumentFinanceState } = invoice;
  const lastApiState = uniqueDocumentFinanceState[0] || {};
  const showComment = useCallback(() => {
    if (lastApiState.rejectionCode?.reason) {
      return lastApiState.rejectionCode.reason;
    }
    if (lastApiState.status && lastApiState.status.code === '7') {
      const payDate = lastApiState.paymentDate ? lastApiState.paymentDate.format('DD-MM-YYYY') : '';
      return `${lastApiState.status.status}\n${payDate}`;
    }
    if (lastApiState.status) {
      return lastApiState.status && lastApiState.status.status;
    }
    return siiStatusMaster(receptionDate)[siiStatus].label;
  }, [lastApiState, receptionDate, siiStatus]);
  return (
    <HtmlTooltip
      title={(
        <Typography color="inherit" sx={{ whiteSpace: 'pre-line' }}>
          {invoice.uniqueDocumentFinanceState.length ? `Comentarios:\n${invoice.uniqueDocumentFinanceState[0]?.comments || 'Sin Comentarios'}` : 'Sin Comentarios'}
        </Typography>
      )}
    >
      <Stack direction="column" textAlign="center" alignItems="center">
        <Stack direction="row" spacing="8px">
          <CustomIconStepper
            current="sii"
            receptionDate={receptionDate}
            siiStatus={siiStatus}
            lastApiState={lastApiState}
          />
          <CustomIconStepper
            current="accounting"
            receptionDate={receptionDate}
            siiStatus={siiStatus}
            lastApiState={lastApiState}
          />
          <CustomIconStepper
            current="payments"
            receptionDate={receptionDate}
            siiStatus={siiStatus}
            lastApiState={lastApiState}
          />
        </Stack>
        <Typography sx={{ fontSize: '10px', whiteSpace: 'pre-line' }}>
          {showComment()}
        </Typography>
      </Stack>
    </HtmlTooltip>
  );
}

StatusSummaryStepperPremium.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    uniqueDocumentFinanceState: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    receptionDate: PropTypes.objectOf(moment).isRequired,
    receiver: PropTypes.shape({
      rut: PropTypes.number,
    }),
  }).isRequired,
};

CustomIconStepper.propTypes = {
  siiStatus: PropTypes.string.isRequired,
  receptionDate: PropTypes.objectOf(moment).isRequired,
  current: PropTypes.string.isRequired,
  lastApiState: PropTypes.shape({
    rejectionCode: PropTypes.shape({
      reason: PropTypes.string,
    }),
    status: PropTypes.shape({
      code: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
};
