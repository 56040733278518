import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const OrderingExternalCessionDialogStepTwo = ({ statistics }) => (
  <Stack spacing={2} alignItems="center">
    {statistics.map((item) => (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="60%"
      >
        <Typography sx={{ fontWeight: 'bold' }}>{item.label}:</Typography>
        <Typography>{item.value}</Typography>
      </Stack>
    ))}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <WarningAmberIcon />
      <Typography>
        Los montos finales están sujetos a la evaluación de riesgo y la fecha de operación.
      </Typography>
    </Box>
  </Stack>
);

OrderingExternalCessionDialogStepTwo.propTypes = {
  statistics: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default OrderingExternalCessionDialogStepTwo;
