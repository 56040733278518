import React from 'react';
import { formatRut } from '@fingo/lib/helpers';
import RutCell from '@fingo/lib/components/cells/RutCell';
import MasterEntityNameCell from '@fingo/lib/components/cells/MasterEntityNameCell';
import TextCell from '@fingo/lib/components/cells/TextCell';

export const ROWS_PER_PAGE = 15;
export const HEADER_TITLE = 'Leads';
export const DATA_GRID_COLUMNS = [
  {
    field: 'masterEntity_Name',
    headerName: 'Razón Social',
    renderCell: ({ row }) => <MasterEntityNameCell name={row?.masterEntity?.name} />,
    flex: 2,
  },
  {
    field: 'masterEntity_Rut',
    headerName: 'RUT',
    renderCell: ({ row }) => <RutCell rut={row?.masterEntity?.rut} />,
    flex: 1,
  },
  {
    field: 'source_Name',
    headerName: 'Tipo de negocio',
    renderCell: ({ row }) => <TextCell text={row?.source?.name} />,
    flex: 1,
  },
  {
    field: 'type_Name',
    headerName: 'Tipo de cliente',
    renderCell: ({ row }) => <TextCell text={row?.type?.name} />,
    flex: 1,
  },
];

export const FORM_DATA_GRID_COLUMNS = [
  {
    field: 'masterEntityName',
    type: 'string',
    headerName: 'Razón social',
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'masterEntityRut',
    type: 'string',
    headerName: 'RUT',
    align: 'center',
    editable: true,
    valueFormatter: ({ value }) => formatRut(value),
    flex: 1,
  },
  {
    field: 'sourceName',
    type: 'string',
    headerName: 'Tipo de negocio',
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'typeName',
    type: 'singleSelect',
    headerName: 'Tipo de cliente',
    align: 'center',
    valueOptions: ['Nuevo', 'Cartera'],
    editable: true,
    flex: 1,
  },
];
