import React from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const AvatarContent = ({ active, content }) => (
  <Avatar
    sx={{
      bgcolor: active ? 'primary.main' : null,
      height: 20,
      width: 20,
      fontSize: '0.6rem',
    }}
  >
    {Math.min(content, 99)}
  </Avatar>
);

AvatarContent.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.number.isRequired,
};

export default AvatarContent;
