import React from 'react';
import { useQuery } from '@apollo/client';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { LoggedOutNavItems, LogoNavItem } from '@fingo/lib/components/layout/navbar';
import { useIsLogged } from '@fingo/lib/hooks';
import { GET_USER } from '@fingo/lib/graphql';
import { LucilaLogo } from '../../../assets';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const { data } = useQuery(GET_USER);

  return (
    <FingoNavbar>
      <LogoNavItem logo={LucilaLogo} />
      {isLogged && data?.getUser && <LoggedInDesktopNavItems />}
      {!isLogged && <LoggedOutNavItems withRegister={false} />}
    </FingoNavbar>
  );
};

export default DesktopNavbar;
