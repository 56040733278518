import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useContactColumns } from '../../constants';
import {
  GET_COMPANY_COLLECTION_CONTACTS,
  GET_COMPANY_RATIFICATION_CONTACTS,
} from '../../graphql';
import FingoDataGrid from '../dataGrids/DataGrid';

const contactsTypeToQuery = {
  ratification: GET_COMPANY_RATIFICATION_CONTACTS,
  collection: GET_COMPANY_COLLECTION_CONTACTS,
};

const ContactList = ({ companyRut, contactsType, setSelectedContactsIds }) => {
  const CONTACTS_QUERY = contactsTypeToQuery[contactsType];
  const [contacts, setContacts] = useState([]);

  const { loading } = useQuery(CONTACTS_QUERY, {
    variables: { companyRut },
    skip: !companyRut,
    fetchPolicy: 'network-only',
    onCompleted: (data) => setContacts(Object.values(data)[0]),
  });

  return (
    <FingoDataGrid
      rows={contacts}
      columns={useContactColumns()}
      boxHeight={350}
      checkboxSelection
      loadingWithSkeleton={loading}
      onSelectionModelChange={(ids) => setSelectedContactsIds(ids)}
      hideFooter
    />
  );
};

ContactList.propTypes = {
  companyRut: PropTypes.number.isRequired,
  setSelectedContactsIds: PropTypes.func.isRequired,
  contactsType: PropTypes.string,
};

ContactList.defaultProps = {
  contactsType: 'collection',
};

export default ContactList;
