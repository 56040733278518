import { gql } from '@apollo/client';
import { UserFields } from '../fragments';

export const LOGIN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      payload
      refreshExpiresIn
      token
      refreshToken
      user {
        ... UserFields
      }
    }
  }
  ${UserFields}
`;

export const SII_CREDENTIAL_EXPERIMENT = gql`
  mutation SiiCredentialExperiment($companyRut: Int!) {
    siiCredentialExperiment(companyRut: $companyRut) {
      success
    }
  }
`;

export const UPDATE_ACEPTA_USER_AND_ACCEPT_TERMS = gql`
  mutation updateAceptaUserAndAcceptTerms(
    $email: String!
    $phoneNumber: String!
    $password: String!
    $companyRutAcceptingTerms: Int!
  ) {
    updateAceptaUserAndAcceptTerms(
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      companyRutAcceptingTerms: $companyRutAcceptingTerms
    ) {
      token
      refreshToken
      user {
        email
        firstName
        lastName
        companyName
        phoneNumber
        companies {
          rut
          invoiceProvider {
            id
            name
            integrated
            iconUrl
          }
        }
      }
      actualCompanyRut
      companies {
        rut
        name
        acceptedTerms
      }
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo(
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $rut: String
    $completeRut: String
    $mailProvided: String
  ) {
    updateUserInfo(
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      rut: $rut
      completeRut: $completeRut
      mailProvided: $mailProvided
    ) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFields} 
`;

export const ACEPTA_LOGIN = gql`
  mutation AceptaAuth($rut: String!, $password: String!){
    aceptaAuth(rut: $rut, password: $password) {
      token
      refreshToken
    }
  }
`;

export const ONBOARDING_AUTH = gql`
  mutation onboardingAuth($activationCode: String!) {
    onboardingAuth(activationCode: $activationCode) {
      token
      refreshToken
    }
  }
`;

export const SENEGOCIA_AUTH = gql`
  mutation SenegociaAuth($token: String!, $loginFlag: String, $isOrdering: Boolean!) {
    senegociaAuth(token: $token, loginFlag: $loginFlag, isOrdering: $isOrdering){
      token
      refreshToken
    }
  }
`;

export const REGISTER_DEMO_STEP = gql`
  mutation registerDemoStep($clickedButton: String!) {
    registerDemoStep(clickedButton: $clickedButton){
      response
    }
  }
`;

export const REGISTER_MOBILE_LOGIN = gql`
  mutation RegisterMobileLogin($companyRut: Int!) {
    registerMobileLogin(companyRut: $companyRut) {
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
mutation ChangePassword(
  $oldPassword: String!
  $newPassword: String!
  ) {
  changePassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
  ){
    response
  }
}
`;

export const RESET_PASSWORD = gql`
mutation ResetPassword(
  $token: String!
  $password: String!
) {
  resetPassword (
    token: $token
    password: $password
  ) {
    success
  }
}
`;

export const UPDATE_CONTACT_INFO = gql`
mutation UpdateContactInfo(
  $email: String!
  $phoneNumber: String!
) {
  updateContactInfo(
    email: $email
    phoneNumber: $phoneNumber
  ) {
    success
    message
  }
}
`;

export const UPDATE_DUMMY_PASSWORD = gql`
  mutation UpdateDummyPassword(
    $newPassword: String!,
    $mailProvided: String!,
    $phoneNumber: String!
  ) {
    updateDummyPassword(
      newPassword: $newPassword
      mailProvided: $mailProvided
      phoneNumber: $phoneNumber
    ) {
      success
      message
      user {
        ... UserFields
      }
    }
  }
  ${UserFields}
`;

export const MARK_TUTORIAL_SEEN = gql`
  mutation MarkTutorialSeen($tutorialCode: String!) {
    markTutorialAsSeen(tutorialCode: $tutorialCode) {
      user {
        ... UserFields
      }
    }
  }
  ${UserFields}
`;

export const SET_PREFERENCES = gql`
  mutation setUserPreferences($input: UserPreferenceInputType!) {
    setUserPreferences(input: $input) {
      success
      message
    }
  }
`;

export const ADD_BANNED_COMPANY = gql`
  mutation addBannedCompany($companyId: ID!) {
    addBannedCompany(companyId: $companyId) {
      success
      message
    }
  }
`;

export const ERASE_BANNED_COMPANY = gql`
  mutation eraseBannedCompany($companyId: ID!) {
    eraseBannedCompany(companyId: $companyId) {
      success
      message
    }
  }
`;

export const REGISTER_USER_ACTIONS = gql`
  mutation CreateUserTrackMetric($actions: [UserActionType]!) {
    createUserTrackMetric(actions: $actions) {
      success
    }
  }
`;

export const USER_INTERACTION_REGISTER = gql`
  mutation userInteractionRegister($action: UserInteractionInputType!) {
    userInteractionRegister(action: $action) {
      interaction {
        id
      }
    }
  }
`;

export const REVOKE_REFRESH_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken){
      revoked
    }
  }
`;

export const ACEPTA_LOGIN_SESSION_ID = gql`
  mutation aceptaAuthSessionId(
    $sessionId: String!,
    $buttonFlag: String,
    $clientRut: Int,
    $companyRut: Int
  ) {
    aceptaAuthSessionId(
      sessionId: $sessionId,
      buttonFlag: $buttonFlag,
      clientRut: $clientRut,
      companyRut: $companyRut
    ){
      token
      refreshToken
      actualCompanyRut
    }
  }
`;

export const UPDATE_NPS = gql`
  mutation UpdateNps(
    $id: ID!,
    $grade: Int,
    $reasons: [String],
    $otherReason: String,
    $comment: String,
    $lastShown: DateTime,
  ) {
    updateNps(
      id: $id,
      input: {
        grade: $grade,
        reasons: $reasons,
        otherReason: $otherReason,
        comment: $comment,
        lastShown: $lastShown,
      }
    ) {
      updateNps {
        id,
        grade,
        reasons,
        otherReason,
        comment,
      }
    }
  }
`;

export const SKIP_NPS = gql`
  mutation SkipNps(
    $id: ID!,
  ) {
    skipNps(
      id: $id,
    ) {
      skipNps {
        id,
        grade,
        reasons,
        otherReason,
        comment,
      }
    }
  }
`;

export const SEND_EMAIL_PASSWORD_RECOVERY = gql`
  mutation sendMail($email:String!){
    sendEmailRecoveryPassword(email:$email){
      success
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String!
    $firstName: String
    $lastName: String
    $password: String!
    $phoneNumber: String
    $utmCampaign: String
  ) {
    registerUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      utmCampaign: $utmCampaign
    ) {
      response
    }
  }
`;

export const REGISTER_SENEGOCIA = gql`
  mutation SenegociaRegister(
    $token: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
    $isOrdering: Boolean!
    $hasDummyPassword: Boolean!
  ) {
    senegociaRegister(
      token: $token
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      isOrdering: $isOrdering
      hasDummyPassword: $hasDummyPassword
    ) {
      response
    }
  }
`;

export const GET_USER_TOKEN = gql`
  mutation getUserToken(
    $userId: Int!
    $tokenMinutes: Int
  ) {
    getUserToken(
      userId: $userId,
      tokenMinutes: $tokenMinutes
    ) {
      token
    }
  }
`;

export const CHANGE_COMPANY = gql`
  mutation changeCompany($companyId: Int!) {
    changeCompany(companyId: $companyId) {
      getUser {
        id
      }
    }
  }
`;
