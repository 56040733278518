import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { validationTypes } from '../../helpers';

function ValidationTextField({ Component, value, validationtype, helperTextColor, ...other }) {
  const [errorMessage, setErrorMessage] = useState('');
  const handleOnBlur = () => {
    const { truthFunction, message } = validationTypes(value)[validationtype];
    if (!truthFunction() && value) {
      setErrorMessage(message);
    }
  };
  useEffect(() => {
    const { truthFunction } = validationTypes(value)[validationtype];
    if (truthFunction() || !value) {
      setErrorMessage('');
    }
  }, [value, validationtype]);

  return (
    <Component
      onBlur={handleOnBlur}
      error={Boolean(errorMessage)}
      helperText={(
        <Typography variant="caption" color={helperTextColor}>
          {errorMessage}
        </Typography>
      )}
      value={value}
      {...other}
    />
  );
}
export default ValidationTextField;

ValidationTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validationtype: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.object,
  helperTextColor: PropTypes.string,
};

ValidationTextField.defaultProps = {
  validationtype: 'email',
  value: undefined,
  Component: TextField,
  helperTextColor: 'error',
};
