import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import FingoDataGrid from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import { PURCHASE_ORDER_DATA_GRID_COLUMNS, HEADER_TITLE } from '../../constants/pending-purchase-orders';
import PendingPurchaseOrdersActions from './pending-purchase-orders/PendingPurchaseOrdersActions';

const PendingPurchaseOrders = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const { loading } = useQuery(
    ORDERING_PURCHASE_ORDERS,
    {
      variables: {
        first: pageSize,
        offset: page * pageSize,
        inPendingInvoices: true,
        companyId_In: null,
      },
      onCompleted: (data) => {
        setPurchaseOrders(data.purchaseOrders.edges.map((edge) => edge.node));
        setRowCount(data.purchaseOrders.totalCount);
      },
    },
  );

  return (
    <>
      <PaperHeader viewTitle={HEADER_TITLE} />
      <PendingPurchaseOrdersActions selectedPurchaseOrdersIds={selectedIds} />
      <FingoDataGrid
        rows={purchaseOrders}
        columns={PURCHASE_ORDER_DATA_GRID_COLUMNS}
        loadingWithSkeleton={loading}
        getRowId={(row) => row.id} // verify
        sx={{
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            overflow: 'hidden',
            lineHeight: '20px',
            whiteSpace: 'normal',
          },
        }}
        headerHeight={70}
        density="compact"
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(ids) => { setSelectedIds(ids); }}
        selectionModel={selectedIds}
        pagination
        paginationMode="server"
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={rowCount}
        keepNonExistentRowsSelected
      />
    </>
  );
};

export default PendingPurchaseOrders;
