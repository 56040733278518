import React from 'react';
import PropTypes from 'prop-types';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';

import FingoDataGrid from '../dataGrids';
import { PaperHeader } from '../headers';
import { useIsMobile } from '../../hooks';

import { baseInvoiceFilters } from '../../helpers';

const FakeDocumentList = (
  {
    type,
    headerTitle,
    includeHeaders,
    mobileHeaders,
    customSummaryElement,
    preColumns,
    openJoyride,
  },
) => {
  const isMobile = useIsMobile();

  const columns = preColumns.map((col) => {
    if (col.type === 'number') {
      return {
        ...col,
        filterOperators: getGridNumericOperators().filter(
          (operator) => ['=', '>', '>=', '<', '<='].includes(operator.value),
        ),
      };
    }
    if (col.type === 'date') {
      return {
        ...col,
        filterOperators: getGridDateOperators().filter(
          (operator) => ['is', 'after', 'onOrAfter', 'before', 'onOrBefore'].includes(operator.value),
        ),
      };
    }
    if (col.field === 'company_MasterEntity_Name' || col.field === 'receiver_Name') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter(
          (operator) => ['equals', 'contains', 'startsWith'].includes(operator.value),
        ),
      };
    }
    if (col.type === 'string') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter(
          (operator) => ['equals', 'isAnyOf'].includes(operator.value),
        ),
      };
    }
    if (col.type === 'singleSelect') {
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => ['is', 'isAnyOf'].includes(operator.value),
        ),
      };
    }
    return col;
  });

  const dataGridColumns = columns
    .filter((column) => includeHeaders.includes(column.field))
    .filter((column) => (isMobile ? mobileHeaders.includes(column.field) : true));

  return (
    <>
      <PaperHeader
        type={type}
        viewTitle={headerTitle}
        finder={{
          searchPlaceHolder: 'Búsqueda por folio, rut o nombres',
          canPasteExcel: true,
          onFinderChange: () => {},
          finderValue: '',
        }}
        openJoyride={openJoyride}
      />
      {customSummaryElement}
      <FingoDataGrid
        loadingWithSkeleton
        rows={[]}
        columns={dataGridColumns}
      />
    </>
  );
};

FakeDocumentList.propTypes = {
  type: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preColumns: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  initialFilterValue: PropTypes.shape({
    documentType: PropTypes.arrayOf(PropTypes.number),
    creditNoteStatus: PropTypes.arrayOf(PropTypes.string),
    siiStatus: PropTypes.arrayOf(PropTypes.string),
    documentFinanceStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  mobileHeaders: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  customSummaryElement: PropTypes.element,
  // Function that receives row and newValue
  openJoyride: PropTypes.func,
};

FakeDocumentList.defaultProps = {
  initialFilterValue: baseInvoiceFilters,
  includeHeaders: [],
  preColumns: [],
  mobileHeaders: [],
  customSummaryElement: null,
  openJoyride: undefined,
};

export default FakeDocumentList;
