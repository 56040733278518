import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Tabs from './Tabs';
import { FadeSwitch } from '../switches';
import { FullHeightBox } from '../boxes';

const FingoPaper = ({ menuOptions }) => {
  const { path: routePath } = useRouteMatch();
  const hasManyOptions = menuOptions.length > 1;

  return (
    <FullHeightBox>
      <Grid
        container
        sx={{
          opacity: hasManyOptions ? '100%' : '0%',
          display: { xs: hasManyOptions ? 'auto' : 'none', md: 'auto' },
        }}
      >
        <Tabs menuOptions={menuOptions} />
      </Grid>
      <Box
        height="95%"
        overflow="auto !important"
        sx={(_theme) => ({
          bgcolor: 'white',
          borderRadius: 12,
          borderTopLeftRadius: hasManyOptions ? 0 : 'none',
          px: 4,
          py: 2,
          [_theme.breakpoints.down('md')]: {
            px: 1,
            borderRadius: 0,
            height: '100%',
            borderTopLeftRadius: 0,
          },
        })}
      >
        <FadeSwitch depth={4}>
          {menuOptions.map(({ path, component }) => (
            <Route key={path} path={`${routePath}/${path}`}>
              {component}
            </Route>
          ))}
          <Route key="all-routes">
            {menuOptions[0].component}
          </Route>
        </FadeSwitch>
      </Box>
    </FullHeightBox>
  );
};

FingoPaper.propTypes = {
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
    component: PropTypes.element,
  })).isRequired,
};

export default FingoPaper;
