import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const EditExecutiveDialog = ({ open, handleClose, executive, handleUpdateExecutive }) => {
  const [editedFields, setEditedFields] = useState({});

  const handleConfirm = () => {
    handleUpdateExecutive({ id: executive.user.id, ...editedFields });
    handleClose();
  };

  const formFields = [
    { id: 'active', label: 'Ejecutivo Activo' },
    { id: 'tycActiveAssignation', label: 'Asignación de TYC' },
    { id: 'loginActiveAssignation', label: 'Asignación de Login' },
    { id: 'clickActiveAssignation', label: 'Asignación de Click' },
  ];

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title={`${executive?.user?.firstName} ${executive?.user?.lastName}`}
      dialogActionButton={(
        <Button color="primary" onClick={() => { handleConfirm(); }}>Guardar</Button>
      )}
    >
      <Grid container spacing={2} mt={1}>
        {formFields.map((field) => (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={field.id}>{field.label}</InputLabel>
              <Select
                labelId={field.id}
                id="demo-simple-select"
                label="Ejecutivo Activo"
                value={
                  Object.hasOwn(editedFields, field.id)
                    ? editedFields[field.id]
                    : executive?.[field.id]
                }
                onChange={(e) => setEditedFields({ ...editedFields, [field.id]: e.target.value === 'true' })}
              >
                <MenuItem value="true">Si</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </FingoDialog>
  );
};

EditExecutiveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdateExecutive: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  executive: PropTypes.object,
};

EditExecutiveDialog.defaultProps = {
  executive: {},
};

export default EditExecutiveDialog;
