export const statusToSpanish = {
  CREATED: 'Disponible',
  PROCESSING: 'Procesando cesión',
  REJECTED: 'Cesión rechazada',
  PENDINGRATIFICATION: 'Por ratificar',
  PENDINGDOCUMENTSREVIEW: 'Revisando documentos',
  REJECTEDRATIFICATION: 'Ratificación rechazada',
  PENDINGTRANSFER: 'Por depositar',
  TRANSFERED: 'Recaudación',
  DEBT: 'En mora',
  EVALUATING: 'En evaluación',
  FINISHED: 'Recaudada',
  BADDEBT: 'Incobrable',
};

export const statusToActiveStep = {
  PROCESSING: 0,
  REJECTED: 0,
  PENDINGRATIFICATION: 1,
  PENDINGDOCUMENTSREVIEW: 1,
  REJECTEDRATIFICATION: 1,
  PENDINGTRANSFER: 2,
  TRANSFERED: 3,
  DEBT: 3,
  FINISHED: 3,
};

export const statusToColor = {
  PROCESSING: 'yellow',
  REJECTED: 'red',
  PENDINGRATIFICATION: 'yellow',
  PENDINGDOCUMENTSREVIEW: 'yellow',
  REJECTEDRATIFICATION: 'red',
  PENDINGTRANSFER: 'yellow',
  TRANSFERED: 'green',
  DEBT: 'red',
  FINISHED: 'green',
};

export const statusToStepLabels = (status) => {
  let labels = [statusToSpanish[status]];
  if (statusToActiveStep[status] === 0) {
    labels = labels.concat(['Ratificación', 'Depósito', 'Recaudación']);
  }
  if (statusToActiveStep[status] === 1) {
    labels.unshift(['Cedida']);
    labels = labels.concat(['Depósito', 'Recaudación']);
  }
  if (statusToActiveStep[status] === 2) {
    labels.unshift('Cedida', 'Ratificada');
    labels.push('Recaudación');
  }
  if (statusToActiveStep[status] === 3) {
    labels.unshift('Cedida', 'Ratificada', 'Depositada');
  }
  return labels;
};
