import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { setDebtorProfileData } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { debtorProfileData } from '@fingo/lib/apollo/reactive-variables';
import { GET_MASTER_ENTITY } from '@fingo/lib/graphql';
import DebtorProfileFactoring from './DebtorProfileFactoring';
import DebtorProfileOrdering from './DebtorProfileOrdering';

const DebtorProfile = () => {
  const location = useLocation();
  const [debtor, setDebtor] = useState({});
  const searchParams = new URLSearchParams(location.search);
  const debtorRut = searchParams.get('debtorRut') || debtorProfileData().debtorRut;
  const documentType = searchParams.get('documentType') || debtorProfileData().documentType;

  useEffect(() => {
    if (searchParams.get('debtorRut') && searchParams.get('documentType')) {
      setDebtorProfileData({
        debtorRut,
        documentType,
      });
    }
  }, []);

  const { data: getMasterEntity, refetch: refetchDebtor } = useQuery(
    GET_MASTER_ENTITY,
    {
      variables: { rut: debtorRut },
      skip: debtor.rut === debtorRut || !debtor,
      onCompleted: () => {
        setDebtor(getMasterEntity.getMasterEntity);
      },
    },
  );

  if (documentType === 'invoice' && debtor.rut) return <DebtorProfileFactoring debtor={debtor} refetchDebtor={refetchDebtor} />;
  if (documentType === 'ordering' && debtor.rut) return <DebtorProfileOrdering debtor={debtor} refetchDebtor={refetchDebtor} />;
  return null;
};

export default DebtorProfile;
