const contactSourceToText = {
  OBTAINED_BY_FINGO: 'Fingo',
  PROVIDED_BY_CLIENT: 'Cliente',
};

const useContactColumns = () => [
  {
    field: 'firstName',
    headerName: 'Nombre',
    type: 'string',
    width: 80,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'lastName',
    headerName: 'Apellido',
    type: 'string',
    width: 100,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'position',
    headerName: 'Cargo',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'email',
    headerName: 'Correo',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'source',
    headerName: 'Fuente',
    type: 'singleSelect',
    width: 140,
    align: 'center',
    valueOptions: ['PROVIDED_BY_CLIENT', 'OBTAINED_BY_FINGO'],
    valueFormatter: ({ value }) => contactSourceToText[value],
    editable: true,
    flex: 2,
  },
];

export default useContactColumns;
