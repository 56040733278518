import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { esES as coreES } from '@mui/material/locale';
import { esES } from '@mui/x-data-grid';

const primaryColor = '#E62E66';
const palette = {
  primary: {
    main: primaryColor,
    light: '#fdeaf0',
    unreadNotification: '#FDEEF3',
  },
  secondary: {
    main: '#233266',
  },
  fingoWhite: {
    main: '#FFF',
  },
  text: {
    main: '#323232',
    primary: '#323232',
    secondary: '#323232',
    light: '#666666',
  },
  gray: {
    main: '#7D7D7D',
    light: '#7D7D7D10',
    dark: '#c4c4c4',
  },
  chart: {
    primary: primaryColor,
    secondary: '#999999',
    lightPinkColor: '#e62e6629',
  },
  error: { main: '#f44336' },
  warning: { main: '#ff9800' },
  success: { main: '#2A7A23' },
  excel: { main: '#1D6F42' },
  action: { disabled: '#ABABAB' },
  background: {
    dark: '#F0F0F0',
    light: '#F7F7F7',
  },
};

const theme = createTheme(
  {
    palette,
    typography: {
      fontFamily: 'Be Vietnam Pro',
      h1: {
        fontWeight: 'bold',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 25,
        lineHeight: 1,
      },
      h2: {
        fontWeight: '400',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 14,
        lineHeight: 1.1,
      },
      h3: {
        fontWeight: '500',
        fontSize: 25,
        lineHeight: 1.1,
      },
      h4: {
        fontWeight: '400',
        fontSize: 20,
        lineHeight: 1.2,
      },
      h5: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 1.2,
      },
      h6: {
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 1.2,
      },
      body1: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 1.2,
      },
      body2: {
        fontSize: '12',
        fontWeight: '300',
        lineHeight: 1.25,
      },
      caption: {
        fontSize: 12,
        lineHeight: 1.2,
        color: '#ABABAB',
      },
      button: {
        textTransform: 'none',
        height: 50,
        fontSize: 14,
      },
      subtitle1: {
        height: 'auto',
        fontSize: 12,
        WebkitLineClamp: 2,
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        lineHeight: '0.8rem !important',
      },
      subtitle3: {
        height: 'auto',
        fontSize: 11,
        WebkitLineClamp: 2,
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        lineHeight: '0.8rem !important',
        fontWeight: 400,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          body: {
            margin: 0,
            height: '100%',
            '*::-webkit-scrollbar': {
              width: 20,
            },
            '*::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: themeParam.palette.gray.dark,
              borderRadius: 20,
              border: '6px solid transparent',
              backgroundClip: 'content-box',
              '&:hover': {
                backgroundColor: '#CCCCCC',
              },
            },
          },
          '#root': {
            height: '100%',
          },
        }),
      },
      MuiToolbar: {
        styleOverrides: {
          root: ({ theme: themeParam }) => ({
            minHeight: 0,
            [themeParam.breakpoints.up('xs')]: {
              minHeight: 0,
            },
          }),
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          columnHeaders: {
            border: 'none',
          },
          row: {
            backgroundColor: '#f9f9f9',
            verticalAlign: 'top',
            marginBottom: 8,
            borderRadius: 12,
            '&$selected': {
              backgroundColor: palette.primary.light,
            },
            '&$selected:hover': {
              backgroundColor: palette.primary.light,
            },
          },
          cell: {
            border: 'none',
            overflow: 'auto !important',
            whiteSpace: 'initial !important',
            '&:focus': {
              outline: 'none',
            },
            '&:focus-within': {
              outline: 'none',
            },
          },
          footerContainer: {
            justifyContent: 'center',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            overflowX: 'unset',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
            borderSpacing: '0 10px',
            marginTop: -10,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '0.5rem 0.3rem',
            fontSize: 12,
            lineHeight: '0.8rem !important',
            verticalAlign: 'middle',
            border: 'none !important',
            borderStyle: 'none !important',
            borderBottom: 'none !important',
            backgroundColor: 'transparent',
            '&:first-of-type': {
              borderLeftStyle: 'none',
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            '&:last-child': {
              borderRightStyle: 'none',
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
            },
          },
          head: {
            verticalAlign: 'middle',
            fontWeight: 'normal',
            border: 'none',
            boxShadow: 'none',
            '&:first-of-type': {
              borderLeftStyle: 'none',
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              boxShadow: 'none',
            },
            '&:last-child': {
              borderRightStyle: 'none',
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              boxShadow: 'none',
            },
          },
          sizeSmall: {
            padding: '0.5rem 0.3rem !important',
            '&:first-of-type': {
              paddingLeft: '0.5rem !important',
            },
            '&:last-child': {
              paddingRight: '0.5rem !important',
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: 40,
            backgroundColor: '#f9f9f9',
            verticalAlign: 'top',
            '&$selected': {
              backgroundColor: palette.primary.light,
            },
            '&$selected:hover': {
              backgroundColor: palette.primary.light,
            },
          },
          head: {
            backgroundColor: 'transparent',
            height: 'inherit',
            borderTop: 'none',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            marginTop: '1rem',
          },
          toolbar: {
            justifyContent: 'center',
          },
          spacer: {
            flex: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: '8px 14px',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            backgroundColor: palette.background.dark,
            '&:before': {
              border: 0,
            },
            '&:hover:before': {
              border: 0,
            },
          },
          input: {
            paddingTop: 10,
            paddingBottom: 10,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&$selected': {
              fontWeight: 'bold',
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'containedReverse', color: 'primary' },
            style: {
              backgroundColor: 'white',
              color: palette.primary.main,
              '&:hover': {
                backgroundColor: palette.background.light,
              },
            },
          },
          {
            props: { variant: 'link' },
            style: ({ theme: _theme }) => ({
              color: palette.primary.main,
              ..._theme.typography.body2,
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }),
          },
        ],
        styleOverrides: {
          root: {
            padding: '0 14px',
            borderRadius: 8,
          },
          label: {
            height: '100%',
          },
          sizeSmall: {
            padding: '8px 16px',
            height: 'min-content',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 16,
            lineHeight: 1.25,
            fontWeight: 300,
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          sizeSmall: {
            fontSize: 14,
            fontWeight: '500',
            color: palette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            opacity: 0.4,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            '&[disabled]': {
              color: palette.action.disabled,
              pointerEvents: 'none',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          inputProps: {
            'data-hj-allow': '',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          inputProps: {
            'data-hj-allow': '',
          },
        },
      },
    },
  },
  coreES,
  esES,
);

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
};

export default responsiveFontSizes(theme);
