import React, { useRef } from 'react';
import { Box } from '@mui/material';

// eslint-disable-next-line react/prop-types
const MinHeightBox = ({ children, ...restBoxProps }) => {
  const boxRef = useRef(null);
  return (
    <Box
      height={`calc(100% - ${boxRef.current?.getBoundingClientRect().top || 64}px)`}
      ref={boxRef}
      {...restBoxProps}
    >
      {children}
    </Box>
  );
};

export default MinHeightBox;
