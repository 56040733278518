import React from 'react';
import { Bolt } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import invoicePropTypes from '../../propTypes/invoice';

const FastPaymentTitle = ({ invoice }) => (
  <>
    <Typography variant="caption">
      Portal de proveedores: {invoice.receiver.name}
    </Typography>
    <Typography variant="body2">
      Factura autorizada para pago rápido
    </Typography>
  </>
);

const LightningFastPayment = ({ invoice }) => (
  <Tooltip title={<FastPaymentTitle invoice={invoice} />}>
    <Bolt color="success" />
  </Tooltip>
);

FastPaymentTitle.propTypes = {
  invoice: invoicePropTypes.isRequired,
};

LightningFastPayment.propTypes = {
  invoice: invoicePropTypes.isRequired,
};

export default LightningFastPayment;
