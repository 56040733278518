import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { formatMoney } from '../../helpers';

const SummaryCard = ({ title, subtitle, isMoney, isWarning }) => (
  <Grid container sx={{ bgcolor: 'white', borderRadius: 2, p: 1.5, pt: 3 }} height="100%">
    <Grid item xs={12}>
      <Typography
        variant="h4"
        align="center"
        color={isWarning ? 'primary' : ''}
      >
        {isMoney ? '$' : ''} {formatMoney(title)}
      </Typography>
    </Grid>
    <Grid item xs={12} sx={{ mt: 2 }}>
      <Typography variant="body1" align="center">{subtitle}</Typography>
    </Grid>
  </Grid>
);

SummaryCard.propTypes = {
  title: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  isMoney: PropTypes.bool,
  isWarning: PropTypes.bool,
};

SummaryCard.defaultProps = {
  isMoney: false,
  isWarning: false,
};

export default SummaryCard;
