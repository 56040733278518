/* eslint-disable import/prefer-default-export */
export const baseInvoiceFilters = {
  siiStatus: [],
  creditNoteStatus: [],
  documentType: [],
  documentFinanceStatus: [],
  allCompanies: undefined,
  availableForFinancing: undefined,
  loanedStatus: undefined,
  documentfinancestate_PaymentDate_Gte: undefined,
  documentfinancestate_PaymentDate_Lte: undefined,
};
