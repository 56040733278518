import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FingoPaper } from '@fingo/lib/components/layout';
import ExternalLeads from './executive-manager/ExternalLeads';
// eslint-disable-next-line import/no-named-as-default
import Executives from './executive-manager/Executives';
import { GET_COMMERCIAL_EXECUTIVES_INFORMATION } from '../graphql/executives';

const ExecutiveManager = () => {
  const [executives, setExecutives] = useState(null);
  const { loading, refetch } = useQuery(
    GET_COMMERCIAL_EXECUTIVES_INFORMATION,
    { onCompleted: (data) => { setExecutives(data.getExecutiveAssignmentInformation); } },
  );

  return (
    <FingoPaper
      menuOptions={[
        {
          label: 'Leads externos',
          path: 'external-leads',
          component: <ExternalLeads executives={executives} loading={loading} />,
        },
        {
          label: 'Ejecutivos',
          path: 'executives',
          component: (
            <Executives
              executives={executives}
              setExecutives={setExecutives}
              loading={loading}
              refetch={refetch}
            />
          ),
        },
      ]}
    />
  );
};

export default ExecutiveManager;
