import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { LoggedInNavItems, ProfileNavItem } from '@fingo/lib/components/layout/navbar';
import { GET_USER } from '@fingo/lib/graphql';
import { Stack } from '@mui/material';
import useLucilaRoutes from '../../../hooks/useLucilaRoutes';

const LoggedInDesktopNavItems = () => {
  const lucilaLinks = useLucilaRoutes();
  const [profileMissing, setProfileMissing] = useState(0);
  const [credentialsMissing, setCredentialsMissing] = useState(0);
  useQuery(
    GET_USER,
    {
      onCompleted: ({ getUser }) => {
        setProfileMissing(getUser?.hasProfileInfoMissing || 0);
        setCredentialsMissing(getUser?.selectedCompany?.hasMissingCredentials || 0);
      },
    },
  );

  return (
    <Stack
      width="100%"
      direction="row"
      spacing={4}
      height={55}
      alignItems="center"
      justifyContent="space-between"
      ml={4}
    >
      <LoggedInNavItems pages={lucilaLinks} />
      <Stack direction="row" spacing={1}>
        <ProfileNavItem
          credentialsMissing={credentialsMissing}
          profileMissing={profileMissing}
        />
      </Stack>
    </Stack>
  );
};

export default LoggedInDesktopNavItems;
