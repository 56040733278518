import { useState, useCallback } from 'react';

const useBooleanState = (initialValue) => {
  const [boolean, setBoolean] = useState(initialValue);
  const toggleBoolean = useCallback(() => setBoolean((prev) => !prev), []);
  const setTrue = useCallback(() => setBoolean(true), []);
  const setFalse = useCallback(() => setBoolean(false), []);
  return [boolean, toggleBoolean, setTrue, setFalse];
};

export default useBooleanState;
