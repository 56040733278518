import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';

const CollectionHistoric = () => (
  <PaperHeader
    viewTitle="Gestiones Históricas"
  />
);

export default CollectionHistoric;
