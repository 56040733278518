import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Info from '../components/Info';

const profileRoutes = (selectedCompany) => [
  {
    label: 'Mi información',
    path: 'info',
    icon: <PersonOutlineOutlinedIcon />,
    component: <Info />,
    show: !!selectedCompany,
  },
];

export default profileRoutes;
