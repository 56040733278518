export const getRutValidatorDigit = (rut) => {
  let rutWithoutDigit = rut;
  let base = 2;
  let sum = 0;
  for (; rutWithoutDigit; rutWithoutDigit = Math.floor(rutWithoutDigit / 10)) {
    sum += (rutWithoutDigit % 10) * base;
    base += 1;
    if (base > 7) base = 2;
  }
  const vDigit = 11 - (sum % 11);
  if (vDigit === 10) return 'k';
  if (vDigit === 11) return '0';
  return vDigit.toString();
};

// Deprecated. Use the one in helpers/forms.js
export const formatRutD = (rut) => {
  const rutStr = rut.toString();
  const { length } = rutStr;
  return `${rutStr.substr(0, length - 6)}.${rutStr.substr(length - 6, 3)}.${rutStr.substr(length - 3, 3)}-${getRutValidatorDigit(rutStr)}`;
};

export const urlParser = (url) => {
  const search = url.replace('?', '');
  if (!search) return null;
  const jsonResponse = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  return jsonResponse;
};

export const groupIt = (array) => {
  const resultObj = {};
  for (let i = 0; i < array.length; i += 1) {
    const currentObj = array[i];
    const firstChar = currentObj.name && currentObj.name[0].toUpperCase();
    const innerArr = [];
    if (resultObj[firstChar] === undefined) {
      innerArr.push(currentObj);
      resultObj[firstChar] = innerArr;
    } else {
      resultObj[firstChar].push(currentObj);
    }
  }
  return resultObj;
};

export const toPercentage = (floatNumber) => parseFloat(floatNumber)?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) || 'n/a';
