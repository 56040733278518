import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '../fragments';

export const GET_ORDER_STATUS_ENUM = gql`
query GetOrderStatusEnum {
  GetOrderStatusEnum
}
`;

export const ORDERING_PURCHASE_ORDERS = gql`
  query purchaseOrders(
    $offset: Int,
    $before: String,
    $after: String,
    $first: Int,
    $last: Int,
    $id_In: [String],
    $companyId_In: [ID],
    $orderBy: String,
    $inSimulation: Boolean,
    $inOffer: Boolean,
    $inOperation: Boolean,
    $inCollection: Boolean = false,
    $getFiles: Boolean = false,
    $getRates: Boolean = true,
    $globalFilter: String,
    $purchaseOrderSecretKey: String,
  ) {
    purchaseOrders(
      offset: $offset,
      before: $before,
      after: $after,
      first: $first,
      last: $last,
      id_In: $id_In,
      companyId_In: $companyId_In,
      orderBy: $orderBy,
      inSimulation: $inSimulation,
      inOffer: $inOffer,
      inOperation: $inOperation,
      inCollection: $inCollection,
      globalFilter: $globalFilter,
      purchaseOrderSecretKey: $purchaseOrderSecretKey
    ){
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ... PurchaseOrderFragment
        }
      }
    }
  }
  ${PurchaseOrderFragment}
`;
