import { gql } from '@apollo/client';

export const ExternalLeadFields = gql`
  fragment ExternalLeadFields on ExternalLeadType {
    id
    masterEntity {
      rut
      name
      company {
        rut
        executiveAssigned {
          id
          firstName
          lastName
        }
      }
    }
    isAssigned
    source {
      name
    }
    type {
      name
    }
  }
`;

export const GET_EXTERNAL_LEADS = gql`
  query GetExternalLeads($first: Int, $offset: Int, $globalFilter: String, $orderBy: String) {
    getExternalLeads(first: $first, offset: $offset, globalFilter: $globalFilter, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          ... ExternalLeadFields
        }
      }
    }
  }
  ${ExternalLeadFields}
`;

export const GET_EXTERNAL_LEADS_TEMPLATE = gql`
  query getExternalLeadsTemplate {
    getExternalLeadsTemplate
  }
`;

export const UPLOAD_EXTERNAL_LEADS_EXCEL = gql`
  mutation uploadExternalLeadsExcel($file: Upload!) {
    uploadExternalLeadsExcel(file: $file) {
      newLeads {
        ... ExternalLeadFields
      }
    }
  }
  ${ExternalLeadFields}
`;

export const ADD_EXTERNAL_LEADS = gql`
  mutation addExternalLeads($externalLeads: [ExternalLeadInputType]!) {
    addExternalLeads(externalLeads: $externalLeads) {
      newLeads {
        ... ExternalLeadFields
      }
    }
  }
  ${ExternalLeadFields}
`;

export const ASSIGN_EXTERNAL_LEADS = gql`
  mutation assignExternalLeads($executiveId: Int!, $externalLeadIds: [Int]!) {
    assignExternalLeads(executiveId: $executiveId, externalLeadIds: $externalLeadIds) {
      assignedLeads {
        ... ExternalLeadFields
      }
    }
  }
  ${ExternalLeadFields}
`;
