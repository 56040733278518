import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  logo: { width: 120 },
}));

const LogoNavItem = ({ logo }) => {
  const classes = useStyles();

  return (
    <Box component={Link} to="/app/home">
      <img src={logo} alt="logo" className={classes.logo} />
    </Box>
  );
};

LogoNavItem.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default LogoNavItem;
