import React, { useCallback, useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { FullHeightBox } from '../boxes';
import './FadeSwitch.css';

const LONG_ANIMATION_PATH = [
  '/app/home',
];
// eslint-disable-next-line react/prop-types
const FadeSwitch = ({ depth, children }) => {
  const transitionCalculator = useCallback((locationVar) => (LONG_ANIMATION_PATH.includes(locationVar.pathname) ? 'fadeInHome' : 'fadeIn'), []);
  const transitionCalculatorOut = useCallback((locationVar) => (LONG_ANIMATION_PATH.includes(locationVar.pathname) ? 'fadeOutHome' : 'fadeOut'), []);
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState(transitionCalculator(location));

  useEffect(() => {
    if (location.pathname.split('/')[depth] !== displayLocation.pathname.split('/')[depth]) {
      setTransistionStage(transitionCalculatorOut(displayLocation));
    } else if (location.pathname !== displayLocation.pathname) {
      setDisplayLocation(location);
    }
  }, [location, displayLocation, depth, transitionCalculatorOut]);

  return (
    <FullHeightBox
      className={transitionStage}
      flex={1}
      onAnimationEnd={() => {
        if (['fadeOut', 'fadeOutHome'].includes(transitionStage)) {
          setTransistionStage(transitionCalculator(location));
          setDisplayLocation(location);
        }
      }}
    >
      <Switch location={displayLocation}>
        {children}
      </Switch>
    </FullHeightBox>
  );
};

export default FadeSwitch;
