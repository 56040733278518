import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { token } from '@fingo/lib/apollo/reactive-variables';
import { SnackBarProvider } from '@fingo/lib/contexts/snack-bar';
import { cacheCreator } from '@fingo/lib/apollo/cache';
import { customFetch } from '@fingo/lib/helpers';
import { theme } from '@fingo/lib/theme';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import moment from 'moment';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { Login, App } from './views';
import PrivateRoute from './components/router/PrivateRoute';
import { Layout } from './components/layout';

const Lucila = () => {
  const [client, setClient] = useState();
  moment.locale('es');
  const myDate = moment();
  myDate.format('ll');
  const cache = cacheCreator();
  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
    fetch: (uri, options) => customFetch(uri, options),
  });
  const wsLink = new WebSocketLink(
    new SubscriptionClient(
      process.env.REACT_APP_WEBSOCKET_SERVER || 'ws://localhost/ws/graphql/',
      {
        lazy: true,
        options: {
          reconnect: true,
        },
        inactivityTimeout: 1000,
        connectionParams: () => ({
          token: token() || '',
        }),
      },
    ),
  );

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token() ? `JWT ${token()}` : '',
    },
  }));

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(uploadLink),
  );

  useEffect(() => {
    const createClientApollo = async () => {
      const apolloClient = new ApolloClient({
        cache,
        link: splitLink,
        resolvers: {},
      });
      setClient(apolloClient);
    };
    if (!client) {
      createClientApollo();
    }
  }, [cache, splitLink, client]);

  if (!client) return <></>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
              <SnackBarProvider>
                <Router>
                  <Layout>
                    <Switch>
                      <PrivateRoute path="/app" component={App} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/" component={Login} />
                    </Switch>
                  </Layout>
                </Router>
              </SnackBarProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </CssBaseline>
    </ThemeProvider>
  );
};

render(<Lucila />, document.getElementById('root'));
