import { gql } from '@apollo/client';

export const GET_COMMERCIAL_EXECUTIVES_INFORMATION = gql`
  query getExecutiveAssignmentInformation {
    getExecutiveAssignmentInformation {
      user {
        id
        firstName
        lastName
      }
      id
      active
      hubspotId
      tycActiveAssignation
      loginActiveAssignation
      clickActiveAssignation
      clickAssignationProportion
      loginAssignationProportion
      weeklyAssignations
      monthlyAssignations
      hubspotMonthlyAssignations
    }
}`;

export const UPDATE_USER_EXECUTIVE = gql`
  mutation UpdateUserExecutive(
    $id: Int!
    $active: Boolean
    $hubspotId: Int
    $tycActiveAssignation: Boolean
    $loginActiveAssignation:Boolean
    $clickActiveAssignation:Boolean
  ) {
    updateUserExecutive(
        id: $id,
        input: {
          active: $active,
          hubspotId: $hubspotId,
          tycActiveAssignation: $tycActiveAssignation,
          loginActiveAssignation: $loginActiveAssignation,
          clickActiveAssignation: $clickActiveAssignation
        }
    ) {
    updateUserExecutive {
        id
        active
        hubspotId
        tycActiveAssignation
        loginActiveAssignation
        clickActiveAssignation
      }
    }
}`;

export const UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION = gql`
  mutation UpdateUserExecutiveAssignationProportion(
    $assignationType: String!
    $input: [UserExecutiveAssignationInputType]!
  ) {
    updateUserExecutiveAssignationProportion(
        assignationType: $assignationType,
        input: $input
    ) {
      success
      reason {
        id
        loginAssignationProportion
        clickAssignationProportion
      }
    }
  }
`;
