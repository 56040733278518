import moment from 'moment';

export const getTimeDiffText = (date) => {
  const today = moment().startOf('day');
  const diff = date.diff(today, 'days');
  if (diff < -1) return `Hace ${Math.abs(diff)} días`;
  if (diff === -1) return 'Ayer';
  if (diff === 0) return 'Hoy';
  if (diff === 1) return 'Mañana';
  return `En ${diff} días`;
};

export const getTermTextBetweenDates = (date1, date2) => {
  const diff = date2.diff(date1, 'days');
  if (diff === 1) return '1 día';
  return `Financiamiento ${diff} días`;
};

export const formatDayMonthYear = (date) => date.format('DD-MM-YYYY');

export const formatYearMonthDay = (date) => date.format('YYYY-MM-DD');

export const formatMinWordDate = (date) => date.format('MMM DD').replace('.', '');

export const formatDateTime = (date) => date.format('YYYY-MM-DD HH:mm').replace('.', '');

export const formatTime = (date) => date.format('HH:mm');

export const formatDayWords = (date) => date.format('DD [de] MMMM');

export const formatDateWordsWithYear = (date) => date.format('DD [de] MMMM [de] YYYY');
