import React from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import { formatRut, formatDayMonthYear, formatMoney, getTimeDiffText } from '../helpers';
import { orderingInvoiceIssuedSelected } from '../apollo/reactive-variables';
import { orderingSimulationSelection } from '../apollo/cache/localQuery';
import { GridEditDateCell } from '../components/dataGridCells';
import useWidth from './document-columns-width';
import { ActionDrawerCell, CompanyCell } from '../components/cells';

const useOrderingLucilaPreColumns = () => {
  const {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    orderNumberWidth,
    littleMessageStatus,
  } = useWidth();
  const dateToPayOnChange = (row, newValue) => orderingInvoiceIssuedSelected(
    { ...orderingInvoiceIssuedSelected(), [row.id]: newValue },
  );
  const { data: { orderingInvoiceIssuedSelected: invSelectedQuery },
  } = useQuery(orderingSimulationSelection);
  return [
    {
      field: 'orderNumber',
      headerName: 'Número',
      width: orderNumberWidth,
      filterable: false,
      resizable: false,
      sortable: true,
      renderCell: (params) => (
        <Typography variant="body2" noWrap width="inherit">
          {params.row.orderNumber}
        </Typography>
      ),
    },
    {
      field: 'purchaser_Name',
      headerName: 'Comprador',
      flex: 1.3,
      filterable: false,
      resizable: false,
      sortable: true,
      renderCell: (params) => (
        <CompanyCell
          name={params.row.purchaser?.name}
          rut={formatRut(params.row.purchaser?.rut)}
        />
      ),
    },
    {
      field: 'orderingoffer_OrderingFinancedAmount',
      headerName: 'Monto financiado',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          ${formatMoney(params.row.orderingsimulation.orderingFinancedAmount)}
        </Typography>
      ),
    },
    {
      field: 'publicationDate',
      headerName: 'Fecha OC',
      width: dateWidth,
      filterable: false,
      resizable: false,
      sortable: true,
      renderCell: (params) => (
        <Typography variant="body2">
          {formatDayMonthYear(params.row.publicationDate)}
        </Typography>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Monto total',
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      sortable: true,
      width: amountWidth,
      renderCell: (params) => (
        <Typography variant="body2">
          ${formatMoney(params.row.totalAmount)}
        </Typography>
      ),
    },
    {
      field: 'orderingsimulation_OrderingPaymentAmount',
      headerName: 'Monto adelanto',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          ${formatMoney(params.row.orderingsimulation.orderingPaymentAmount)}
        </Typography>
      ),
    },
    {
      field: 'orderingsimulation_OrderingMonthlyRate',
      headerName: 'Tasa',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.orderingsimulation.orderingMonthlyRate.toFixed(2).replace('.', ',')}%
        </Typography>
      ),
    },
    {
      field: 'orderingsimulation_OrderingRetentionRate',
      headerName: 'Anticipo',
      width: rateWidth + 5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {(100 - params.row.orderingsimulation.orderingRetentionRate).toFixed(2).replace('.', ',')}%
        </Typography>
      ),
    },
    {
      field: 'orderingStatus',
      headerName: 'Estado',
      width: littleMessageStatus,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.simulationSelectable.status}
        </Typography>
      ),
    },
    {
      field: 'orderingInvoiceDate',
      headerName: 'Fecha de emisión factura',
      ...GRID_DATE_COL_DEF,
      headerAlign: 'left',
      align: 'left',
      width: dateInputWidth,
      filterable: false,
      resizable: false,
      sortable: false,
      renderEditCell: (params) => (
        <GridEditDateCell
          {...params}
          onChange={dateToPayOnChange}
          minDate={moment().add(15, 'days')}
          maxDate={params.row.dateExpiration.clone().add(params.row.offer.fundingDaysAfterExpiration, 'days')}
        />
      ),
      renderCell: (params) => (
        <GridEditDateCell
          {...params}
          value={invSelectedQuery[params.row.id]}
          onChange={dateToPayOnChange}
          minDate={moment().startOf('day')}
          maxDate={moment().startOf('day').add(120, 'days')}
        />
      ),
    },
    {
      field: 'orderingoffer_OrderingPaymentAmount',
      headerName: 'Monto adelanto',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          ${formatMoney(params.row.orderingoffer.orderingPaymentAmount)}
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_OrderingMonthlyRate',
      headerName: 'Tasa',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.orderingoffer.orderingMonthlyRate.toFixed(2).replace('.', ',')}%
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_OrderingRetentionRate',
      headerName: 'Anticipo',
      width: rateWidth + 5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {(100 - params.row.orderingoffer.orderingRetentionRate).toFixed(2).replace('.', ',')}%
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_InvoiceIssuedDate',
      headerName: 'Emisión factura',
      width: dateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      resizable: false,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start" overflow="hidden" direction="column">
          <Typography variant="body1">
            {moment(params.row.orderingoffer.invoiceIssuedDate).format('DD-MM-YYYY')}
          </Typography>
          <Typography variant="caption">{getTimeDiffText(params.row.orderingoffer.invoiceIssuedDate)}</Typography>
        </Grid>
      ),
    },
    {
      field: 'collectionPriority',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <Typography>{params.row.collectionManager?.priority}</Typography>
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      width: 80,
      renderCell: (params) => (
        <ActionDrawerCell
          masterEntityData={params.row.company.masterEntity}
          actions={params.row.collectionManager.actions}
        />
      ),
    },
  ];
};

export default useOrderingLucilaPreColumns;
