import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Grid, List, ListItem, Typography } from '@mui/material';
import { formatRut } from '../../helpers';
import { ActionListItem } from '../lists';

const ActionsDrawer = ({ masterEntityData, actions, open, setOpen }) => (
  <Drawer
    open={open}
    onClose={() => setOpen(false)}
    anchor="right"
  >
    <Box
      sx={{ padding: 5, maxWidth: 700 }}
    >
      <Grid sx={{ marginBottom: 3 }}>
        <Typography variant="h4" color="primary">
          {masterEntityData.name}
        </Typography>
        <Typography variant="h2" color="primary">
          {formatRut(masterEntityData.rut)}
        </Typography>
      </Grid>
      <Divider />
      <List>
        {actions.map((action) => (
          <>
            <ListItem sx={{ marginBottom: 1 }}>
              <ActionListItem action={action} documentsType={action.invoices ? 'invoices' : 'purchaseorders'} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Box>
  </Drawer>
);

ActionsDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  masterEntityData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

ActionsDrawer.defaultProps = {
  actions: [],
};

export default ActionsDrawer;
