import React from 'react';
import {
  Tooltip,
  styled,
  tooltipClasses,
} from '@mui/material';

const HtmlTooltip = styled(({ className, ...params }) => (
  <Tooltip {...params} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
  },
}));

export default HtmlTooltip;
