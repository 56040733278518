import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';
import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import { actionTypeToSpanish } from '../../helpers';

const ActionListItem = ({ action, documentsType }) => (
  <Grid container alignItems="center" justifyContent="flex-start">
    <Stack>
      <Typography variant="caption">
        {moment(action.createdAt).format('DD-MM-YYYY HH:MM[hrs]')}
      </Typography>
      <Typography variant="subtitle2" sx={{ marginBlock: 0.5 }}>
        {actionTypeToSpanish[action.actionType]}
      </Typography>

      {Object.entries(_.groupBy(action[documentsType], 'companyName')).map(
        ([key, value]) => (
          <>
            {documentsType === 'invoices' ? (
              <Typography sx={{ display: 'contents' }}>• {value.length > 1 ? 'Folios' : 'Folio'} </Typography>
            ) : (
              <Typography sx={{ display: 'contents' }}>• {value.length > 1 ? 'Órdenes de compra' : 'Orden de compra'} </Typography>
            )}
            {value.map((v) => (
              <>
                <Typography sx={{ display: 'contents', fontWeight: 550 }}>{documentsType === 'invoices' ? v.folio : v.orderNumber}, </Typography>
              </>
            ))}
            <>
              <Typography sx={{ display: 'contents' }}>de la compañía </Typography>
              <Typography sx={{ display: 'contents', fontWeight: 550 }}>{key}</Typography>
              <br />
            </>
          </>
        ),
      )}
      <Typography variant="body1" sx={{ marginBlock: 0.5 }}>
        {action.comment}
      </Typography>
    </Stack>
  </Grid>
);

ActionListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.object.isRequired,
  documentsType: PropTypes.string.isRequired,
};

export default ActionListItem;
