import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  IconButton,
  Stack,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { UPLOAD_INVOICE_DOCUMENTS } from '../../graphql';

const documentOptions = [
  { label: 'PDF Factura', value: 'PDF' },
  { label: 'Factura Timbrada/firmada', value: 'SignedInvoice' },
  { label: 'Guía de Despacho', value: 'ShippingGuidebook' },
  { label: 'OC', value: 'PurchaseOrder' },
  { label: 'HES', value: 'HES' },
  { label: 'Estado de Pago', value: 'PaymentStatus' },
  { label: 'Otros', value: 'Other' },
];

const UploadDocumentsManager = ({
  open,
  handleClose,
  invoiceId,
}) => {
  const [documents, setDocuments] = useState([{
    name: '',
    documentType: '',
    file: '',
  }]);
  const [uploadFilesMessage, setUploadFilesMessage] = useState('');
  const [uploadFiles, { loading: loadingUploadFiles }] = useMutation(UPLOAD_INVOICE_DOCUMENTS, {
    onCompleted: (data) => {
      setUploadFilesMessage(data.uploadInvoiceDocuments.message);
      handleClose();
    },
  });

  const setDocumentType = (e, i) => {
    const { value } = e.target;
    const newDocuments = [...documents];
    newDocuments[i].documentType = value;
    setDocuments(newDocuments);
  };

  const handleAddDocumentInput = () => {
    setDocuments((prev) => [
      ...prev,
      {
        name: '',
        documentType: '',
        file: '',
      },
    ]);
  };

  const handleRemoveDocument = (i) => {
    setDocuments((prev) => prev.slice(0, i).concat(prev.slice(i + 1, prev.length)));
  };

  const handleAddDocument = (file, i) => {
    setDocuments((previous) => {
      const newDocuments = [...previous];
      newDocuments[i].name = file.name;
      newDocuments[i].file = file;
      return newDocuments;
    });
  };

  const handleUploadDocuments = () => {
    let arrayFilesMutation = [];
    Object.entries(documents).forEach(([...v]) => {
      arrayFilesMutation = [...arrayFilesMutation,
        { documentType: v[1].documentType, file: v[1].file }];
    });
    uploadFiles({
      variables: {
        invoiceId,
        inputUploadFiles: arrayFilesMutation,
      },
    });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Carga de Documentos</DialogTitle>
      <DialogContent sx={{ width: 700 }}>
        <Stack spacing={1}>
          {documents.map(({ name, documentType }, i) => (
            <Stack key={name} direction="row" alignItems="center" spacing={1}>
              <Typography>Documento</Typography>
              <Typography width={120} noWrap>{name || '...'}</Typography>
              <Select
                id="ratification-contact-select"
                value={documentType}
                onChange={(e) => setDocumentType(e, i)}
                style={{ minWidth: '12rem', maxWidth: '12rem' }}
              >
                {documentOptions.map((method) => (
                  <MenuItem key={method.value} value={method.value}>{method.label}</MenuItem>
                ))}
              </Select>
              <label htmlFor={i}>
                <input
                  style={{ display: 'none' }}
                  id={i}
                  type="file"
                  onChange={(e) => handleAddDocument(e.target.files[0], i)}
                />
                <IconButton aria-label="attach" component="span" size="small" id="uploadDocumentAttach">
                  <AttachFileIcon />
                </IconButton>
              </label>
              <IconButton onClick={() => handleRemoveDocument(i)} size="small" id="uploadDocumentRemove">
                <Delete />
              </IconButton>
            </Stack>
          ))}
        </Stack>
        <Button
          color="primary"
          variant="text"
          startIcon={<Add />}
          onClick={handleAddDocumentInput}
          size="small"
          id="uploadDocumentAdd"
        >
          Agregar documento
        </Button>
        <Typography><b>{uploadFilesMessage}</b></Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          disabled={loadingUploadFiles}
          onClick={handleClose}
          color="primary"
          id="uploadDocumentOut"
        >
          Salir
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleUploadDocuments}
          color="primary"
          autoFocus
          size="small"
          disabled={
          documents.length === 0
          || documents.some((document) => document.name === ''
            || document.documentType === '')
          }
          loading={loadingUploadFiles}
          id="uploadDocumentUpload"
        >
          Cargar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

UploadDocumentsManager.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
};

export default UploadDocumentsManager;
