import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { formatRut } from '../../helpers';

const MasterEntityRutCell = ({ rut }) => {
  const draggableProps = (dataSearch) => (
    {
      draggable: true,
      onDrag: (e) => { e.preventDefault(); e.newData = dataSearch; },
    }
  );

  return (
    <Grid container alignItems="center" justifyContent="flex-start" {...draggableProps(rut)}>
      <Typography variant="body2">
        {formatRut(rut)}
      </Typography>
    </Grid>
  );
};

MasterEntityRutCell.propTypes = {
  rut: PropTypes.number.isRequired,
};

export default MasterEntityRutCell;
