import React from 'react';
import { Checkbox } from '@mui/material';

const DataGridCheckbox = React.forwardRef((props, ref) => (
  <Checkbox
    ref={ref}
    id="dataGridCheckbox"
    {...props}
    sx={{
      p: { xs: 0, md: 'inherit' },
      '& .MuiSvgIcon-root': {
        fontSize: { xs: 15, md: 20 },
      },
    }}
  />
));

export default DataGridCheckbox;
