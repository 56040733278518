import { Button, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ShoppingCartOutlined, Event } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { useShoppingCart } from '../../hooks';
import { formatMoney } from '../../helpers';
import { shoppingCartShowQuery } from '../../apollo/cache/localQuery';
import { shoppingCartShow } from '../../apollo/reactive-variables';
import { IconTooltip } from '../tooltips';

const ShoppingCartPreview = ({ cartType }) => {
  const {
    shoppingCartData,
    disabledShowCart,
    loading,
  } = useShoppingCart({ cartType });
  const { data: { showShoppingCart } } = useQuery(shoppingCartShowQuery);
  const handleOnClick = useCallback(() => {
    shoppingCartShow(!showShoppingCart);
  }, [showShoppingCart]);
  const documentType = {
    'available-offers': 'facturas',
    'available-factoring': 'facturas',
    ordering: 'OC',
  };
  const showCartButton = (
    <Button
      id="preview-show-cart"
      startIcon={<ShoppingCartOutlined />}
      variant="contained"
      size="small"
      onClick={handleOnClick}
      disabled={disabledShowCart}
    >Ver en el carrito
    </Button>
  );
  const previewInfo = [
    { title: `Cantidad ${documentType[cartType]}`, value: shoppingCartData?.invoiceCount },
    { title: `Monto total ${documentType[cartType]}`, value: `$${formatMoney(shoppingCartData?.totalAmount)}` },
  ];
  return (
    <Stack
      width="100%"
      borderRadius={2}
      py={2.5}
      px={4}
      marginBottom={0.5}
      direction="row"
      bgcolor="primary.light"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        spacing={5}
        justifyContent="flex-start"
      >
        {previewInfo.map((info) => (
          <Stack
            direction="column"
            spacing={1}
            key={info.title}
          >
            <Typography variant="h6" fontWeight={600}>
              {info.title}:
            </Typography>
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              <Typography variant="h6">
                {info.value}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
      <IconTooltip
        icon={<Event />}
        text="Recuerda ingresar las fechas de pago"
        disabledHover={!disabledShowCart}
      >
        {showCartButton}
      </IconTooltip>
    </Stack>
  );
};

ShoppingCartPreview.propTypes = {
  cartType: PropTypes.string.isRequired,
};

export default ShoppingCartPreview;
