/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import {
  AccessTime,
  ErrorOutline,
  RadioButtonUnchecked,
  CheckCircleOutline,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsMobile } from '../../hooks';
import { statusToActiveStep, statusToStepLabels } from '../../helpers';

export const statusToIcon = {
  PROCESSING: <AccessTime color="warning" />,
  REJECTED: <ErrorOutline color="error" />,
  PENDINGRATIFICATION: <AccessTime color="warning" />,
  PENDINGDOCUMENTSREVIEW: <AccessTime color="warning" />,
  REJECTEDRATIFICATION: <ErrorOutline color="error" />,
  PENDINGTRANSFER: <AccessTime color="warning" />,
  TRANSFERED: <AccessTime color="warning" />,
  DEBT: <ErrorOutline color="error" />,
  FINISHED: <CheckCircleOutline color="success" />,
};

const useStyles = makeStyles({
  stepper: {
    padding: 0,
  },
  stepLabel: {
    marginTop: '4px !important',
    fontSize: 12,
  },
  collectionActionsButton: {
    fontSize: 12,
    height: 0,
  },
});

const getStatusIcon = (status, i) => {
  if (i < statusToActiveStep[status]) return <CheckCircleOutline color="success" />;
  if (statusToActiveStep[status] === i) return statusToIcon[status];
  return <RadioButtonUnchecked />;
};

export const Status = ({ status, paymentDate }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Stepper className={classes.stepper} activeStep={statusToActiveStep[status]} alternativeLabel>
      {statusToStepLabels(status).map((label, i) => (
        ((isMobile && i === statusToActiveStep[status]) || !isMobile) && (
        <Step key={label}>
          <StepLabel
            icon={getStatusIcon(status, i)}
            classes={{
              label: classes.stepLabel,
            }}
          >
            {label} <br />
            {status === 'FINISHED' && statusToActiveStep[status] === i && paymentDate && moment(paymentDate).format('DD-MM-YYYY')}
          </StepLabel>
        </Step>
        )))}
    </Stepper>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGRATIFICATION', 'REJECTEDRATIFICATION', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paymentDate: PropTypes.object,
  invoice: PropTypes.shape({
    folio: PropTypes.number,
    debtorName: PropTypes.string,
    collectionManager: PropTypes.shape({
      actions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
      })),
    }),
  }).isRequired,
};

Status.defaultProps = {
  paymentDate: null,
};
