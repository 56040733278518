/* eslint-disable import/prefer-default-export */
import { logIn, logOut } from '../apollo/reactive-variables/localUpdates';

const rawRefresh = `mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
    __typename
  }
}`;

export const customFetch = async (uri, options) => {
  const response = await fetch(uri, options);
  const text = await response.text();
  const jsonResponse = await JSON.parse(text);
  if (jsonResponse.errors && jsonResponse.errors[0].message === 'Signature has expired') {
    const vars = await JSON.parse(localStorage.getItem('apolloVariables'));
    const body = {
      operationName: 'refreshToken',
      variables: {
        refreshToken: vars.refreshToken,
      },
      query: rawRefresh,
    };
    const refreshResponse = await fetch(uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const refreshJSON = await refreshResponse.json();
    if (refreshJSON.errors && refreshJSON.errors[0].message === 'Invalid refresh token') {
      logOut();
      window.location = '/';
    }
    const user = refreshJSON.data.refreshToken;
    logIn(refreshJSON.data.refreshToken);
    const newOptions = {
      ...options,
      headers: { ...options.headers, authorization: `JWT ${user.token}` },
    };
    return fetch(uri, newOptions);
  }

  const result = {};
  result.ok = true;
  result.text = () => Promise.resolve(text);
  return result;
};
