import React from 'react';
import { Tooltip, Typography, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { SwapHoriz } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatMoney } from '../../helpers';

const TooltipTitle = ({ children, creditNotes, debitNotes }) => (
  <Tooltip
    placement="right"
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'background.paper',
        },
      },
    }}
    sx={{
      cursor: 'pointer',
    }}
    title={(
      <List>
        {creditNotes.map(({ folio, amountWithIva, id }) => (
          <ListItemButton
            component={Link}
            to={{
              pathname: '/app/invoice',
              search: `?invoiceId=${id}`,
            }}
            key={id}
          >
            <ListItemText
              primary={`Nota de crédito #${folio}, monto $${formatMoney(amountWithIva)}`}
              primaryTypographyProps={{
                variant: 'subtitle1',
                sx: {
                  color: 'text.primary',
                },
              }}
            />
          </ListItemButton>
        ))}
        {debitNotes.map(({ folio, amountWithIva, id }) => (
          <ListItemButton
            component={Link}
            to={{
              pathname: '/app/invoice',
              search: `?invoiceId=${id}`,
            }}
            key={id}
          >
            <ListItemText
              primary={`Nota de débito #${folio}, monto $${formatMoney(amountWithIva)}`}
              primaryTypographyProps={{
                variant: 'subtitle1',
                sx: {
                  color: 'text.primary',
                },
              }}
            />
          </ListItemButton>
        ))}
      </List>
    )}
  >
    {children}
  </Tooltip>
);

const AmountWithIvaMessage = ({ creditNotes, debitNotes }) => {
  const creditNotesTotalAmountWithIva = creditNotes.reduce((sum, cr) => sum + cr.amountWithIva, 0);
  const debitNotesTotalAmountWithIva = debitNotes.reduce((sum, cr) => sum + cr.amountWithIva, 0);
  const correctionAmount = creditNotesTotalAmountWithIva - debitNotesTotalAmountWithIva;
  if (creditNotes.length || debitNotes.length) {
    return (
      <TooltipTitle creditNotes={creditNotes} debitNotes={debitNotes}>
        <Stack alignItems="center" justifyContent="flex-start" direction="row" width="100%">
          <Typography noWrap variant="caption">{'\u00A0'}${formatMoney(correctionAmount)}</Typography>
          <SwapHoriz color={creditNotes.length ? 'error' : 'success'} fontSize="small" />
        </Stack>
      </TooltipTitle>
    );
  }
  return <></>;
};

const defaultPropTypes = {
  creditNotes: PropTypes.arrayOf(PropTypes.shape({
    amountWithIva: PropTypes.number,
    id: PropTypes.string,
  })).isRequired,
  debitNotes: PropTypes.arrayOf(PropTypes.shape({
    amountWithIva: PropTypes.number,
    id: PropTypes.string,
  })).isRequired,
};

AmountWithIvaMessage.propTypes = {
  ...defaultPropTypes,
};

TooltipTitle.propTypes = {
  ...defaultPropTypes,
  children: PropTypes.element.isRequired,
};

export default AmountWithIvaMessage;
