/* eslint-disable react/prop-types */
import React from 'react';
import { Icon, SvgIcon } from '@mui/material';
import { ReactComponent as SiiIconImage } from './sii_icon.svg';
import PencilIconImage from './pencil.svg';

const SiiIcon = (props) => {
  const { sx: { color } } = props;
  return (
    <SvgIcon {...props} alt="sii-icon">
      <SiiIconImage fill={color} />
    </SvgIcon>
  );
};

const PencilIcon = (props) => (
  <Icon {...props}>
    <img src={PencilIconImage} alt="pencil-icon" />
  </Icon>
);

export { SiiIcon, PencilIcon };
