import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@mui/material';
import { AddReaction } from '@mui/icons-material';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { ADD_COLLECTION_ACTION } from '@fingo/lib/graphql';
import { collectionReceiverActionTypes } from '../../features/collection/constants/actions-type';
import OperativeStepperDialog from '../dialogs/OperativeStepperDialog';

const stepToTitle = {
  0: 'Selecciona tipo de gestión',
  1: 'Selecciona contacto',
  2: 'Agrega un comentario',
};

const AddActionButton = ({
  managerIds,
  operationType,
  targetRut,
  setTriggerRefetchDocument,
}) => {
  const ratificationActionTypes = null;
  const [openDialog, toggleDialog, setTrue] = useBooleanState(false);
  const [actionType, setActionType] = useState();
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [comment, setComment] = useState();
  const { addAlert } = useSnackBars();
  const actionsType = (operationType === 'collection') ? collectionReceiverActionTypes : ratificationActionTypes;
  const onClose = () => {
    setActionType(null);
    setSelectedContactsIds([]);
    setComment(null);
    setCurrentStep(0);
    toggleDialog();
  };

  const [addCollectionAction, { loading: loadingAddAction }] = useMutation(ADD_COLLECTION_ACTION, {
    onCompleted: () => {
      setTriggerRefetchDocument(true);
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Gestión creada exitosamente',
      });
      onClose();
    },
  });

  const onSubmit = () => {
    addCollectionAction({
      variables: {
        collectionManagerIds: managerIds,
        collectionContactIds: selectedContactsIds,
        actionType,
        comment,
      },
    });
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddReaction />}
        sx={{ m: 0.5, height: 30, width: '100%' }}
        onClick={setTrue}
        disabled={managerIds.length === 0}
      >
        <Typography noWrap>
          Nueva gestión
        </Typography>
      </Button>
      <OperativeStepperDialog
        open={openDialog}
        onClose={onClose}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        firstStepOptions={actionsType}
        setOptions={setActionType}
        selectedOption={actionType}
        targetRut={targetRut}
        operationType={operationType}
        setSelectedContactsIds={setSelectedContactsIds}
        selectedContactsIds={selectedContactsIds}
        lastStepComponent={(
          <TextField
            id="outlined-basic"
            label="Comentario"
            variant="outlined"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            sx={{ m: 2 }}
            multiline
            rows={5}
            fullWidth
          />
        )}
        onSubmit={onSubmit}
        submitButtonText="Añadir gestión"
        loadingSubmit={loadingAddAction}
        stepToTitle={stepToTitle}
      />
    </>
  );
};

AddActionButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  managerIds: PropTypes.array,
  operationType: PropTypes.string.isRequired,
  targetRut: PropTypes.number.isRequired,
  setTriggerRefetchDocument: PropTypes.func.isRequired,
};

AddActionButton.defaultProps = {
  managerIds: [],
};

export default AddActionButton;
