import { gql } from '@apollo/client';

export const UPDATE_INVOICE_PROVIDER_CREDENTIALS = gql`
  mutation upload($companyRut: Int!, $password: String!, $naturalPersonRut: String!, $credentialTypeId: Int!, $secondUserName: String) {
    uploadInvoiceProvicerCredentials(companyRut: $companyRut, password: $password, naturalPersonRut: $naturalPersonRut, credentialTypeId: $credentialTypeId, secondUsername: $secondUserName) {
      success
      message
    }
  }
`;

export const UPLOAD_SII_CREDENTIALS = gql`
  mutation UploadSiiCredentials($companyRut: Int!, $siiPassword: String!) {
    uploadSiiCredentials(companyRut: $companyRut, siiPassword: $siiPassword) {
      success
      message
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount(
    $accountEmail: String!
    $accountName: String!
    $accountNumber: String!
    $accountRut: String!
    $accountType: String!
    $bankName: String!
    ) {
      createBankAccount(
        accountEmail: $accountEmail
        accountName: $accountName
        accountNumber: $accountNumber
        accountRut: $accountRut
        accountType: $accountType
        bankName: $bankName
      ){
      bankAccountCreated {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
      }
    }
  }
`;

export const EDIT_BANK_ACCOUNT = gql`
  mutation EditBankAccount(
    $id: ID!
    $accountEmail: String!
    $accountName: String!
    $accountNumber: String!
    $accountType: String!
    $bankName: String!
    ) {
      editBankAccount(
        id: $id
        accountEmail: $accountEmail
        accountName: $accountName
        accountNumber: $accountNumber
        accountType: $accountType
        bankName: $bankName
      ){
      bankAccount {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
      }
    }
  }
`;

export const BANK_ACCOUNT_ACTIVE_CHANGE = gql`
  mutation bankAccountActiveChange($bankId: Int!) {
    bankAccountActiveChange(bankId: $bankId) {
      bankAccount {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
      }
    }
  }
`;

export const SET_NEW_PHONE = gql`
  mutation newPhone($newPhone: String!, $companyRut: String!) {
    addCompanyPhoneNumber(newPhone: $newPhone, companyRut: $companyRut) {
      success
      message
    }
  }
`;

export const UPLOAD_CERTIFICATE = gql`
  mutation UploadDigitalCertificate(
    $certificatePassword: String!
    $companyRut: Int!
    $digitalCertificate: Upload!
    $isOrdering: Boolean!
    ) {
      uploadDigitalCertificate(
        certificatePassword: $certificatePassword
        companyRut: $companyRut
        digitalCertificate: $digitalCertificate
        isOrdering: $isOrdering
      ){
      digitalCertificate {
        id
      }
    }
  }
`;

export const UPLOAD_BALANCE = gql`
  mutation UploadBalance($balance: Upload!, $balanceYear: Int!, $companyRut: Int!) {
    uploadBalance(balance: $balance, balanceYear: $balanceYear, companyRut: $companyRut) {
      success
      message
    }
  }
`;

export const ADD_PURCHASE_ORDER_TO_PREOFFERS = gql`
  mutation addPurchaseOrderToInvoices(
    $purchaseOrderList: [PurchaseOrderInputType]!
    $rut: Int!
  ) {
    addPurchaseOrderToInvoices(
      purchaseOrderList: $purchaseOrderList
      rut: $rut
    ) {
      success
      message
    }
  }
`;

export const GENERATE_PORTFOLIO_URL = gql`
  mutation GeneratePortfolioSummary(
    $clientRut: String!
    $portfolioType: PortfolioTypeEnum!
    $documentFormat: PortfolioFormatEnum!
    $startDate: Date!
    $endDate: Date!
  ) {
    generatePortfolioSummary(
      clientRut: $clientRut
      portfolioType: $portfolioType
      documentFormat: $documentFormat
      startDate: $startDate
      endDate: $endDate
    ){
      url
    }
  }
`;

export const REGISTER_COMPANY = gql`
  mutation RegisterCompany($companyRut: String!, $siiPassword: String!, $isNotRegister: Boolean) {
    registeCompany(companyRut: $companyRut, siiPassword: $siiPassword, isNotRegister: $isNotRegister) {
      response
      socialReason
    }
  }
`;

export const REGISTER_INVOICE_PROVIDER = gql`
  mutation RegisterInvoiceProvider(
    $skip: Boolean!
    $companyRut: String
    $invoiceProvider: String
    $username: String
    $password: String
  ) {
    registerInvoiceProvider(
      skip: $skip
      companyRut: $companyRut
      username: $username
      password: $password
      invoiceProvider: $invoiceProvider
    ) {
      response
    }
  }
`;

export const SOPHIA_BANNER_CLICK = gql`
mutation SophiaBannerClick($companyRut: Int!) {
  sophiaBannerClick(companyRut: $companyRut){
    response
  }
}`;

export const ADD_COMPANY_TO_SUPER_USER = gql`
  mutation addCompany($rut:Int!){
    addCompanyToSuperuser(rut:$rut){
      response
    }
  }`;

export const ADD_OR_EDIT_CONTACT = gql`
  mutation createOrEditContact(
    $isNew: Boolean!,
    $id: Int!,
    $name: String,
    $phoneNumber: String,
    $position : String,
    $email : String!,
    $company: Int!,
    ) {
    createOrEditContact(
      isNew: $isNew,
      name: $name,
      phoneNumber: $phoneNumber,
      position:$position,
      email: $email,
      id: $id,
      company: $company,
    ) {
      messageType
      message
      contact{
        id
        name
        email
        position
        phoneNumber
      }
    }
  }`;

export const ERASE_CONTACT = gql`
  mutation eraseContact(
    $id: Int!,
    $company: Int!,
    ) {
    eraseContact(
      id: $id,
      company: $company,
    ) {
      messageType
      message
    }
  }
`;

export const UPLOAD_CONTACTS_EXCEL = gql`
  mutation uploadContactsExcel($inputUploadFiles: Upload!, $companyId: Int!) {
    uploadContactsExcel(inputUploadFiles: $inputUploadFiles, companyId: $companyId) {
      message
      messageType
    }
  }
`;

export const INVITE_CONTACT = gql`
  mutation inviteContact($contactId: Int!, $companyFrom: Int!) {
    inviteContact(contactId: $contactId, companyFrom: $companyFrom) {
      message
      messageType
    }
  }
`;

export const ACCEPT_TERMS = gql`
  mutation AcceptTermsAndConditions($ruts: [Int]!) {
    acceptTermsAndConditions(ruts: $ruts) {
      response
    }
  }
`;

export const SET_DEBTOR_PAYMENT_BEHAVIOUR = gql`
  mutation setDebtorPaymentBehaviour(
    $masterEntityId: Int!,
    $newBehaviour: String!
  ) {
    setDebtorPaymentBehaviour(
      masterEntityId: $masterEntityId,
      newBehaviour: $newBehaviour
    ) {
      success
    }
  }
`;

export const ADD_COLLECTION_ACTION = gql`
  mutation AddCollectionAction(
    $collectionManagerIds: [Int]!
    $actionType: CollectionActionEnum!
    $comment: String
    $collectionContactIds: [Int]!
  ) {
    addCollectionAction(collectionAction: {
      collectionManagerIds: $collectionManagerIds,
      actionType: $actionType,
      comment: $comment,
      collectionContactIds: $collectionContactIds,
    }) {
      success
      addedAction {
        id
        actionType
        comment
        createdAt
        collectionContacts {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
