import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const SelectRadioGroup = ({ options, setOption, selectedOption }) => (
  <RadioGroup
    aria-label="collection-action-type"
    value={selectedOption}
    onChange={(e) => setOption(e.target.value)}
    sx={{ marginTop: 2 }}
  >
    {options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        control={<Radio />}
        label={option.label}
      />
    ))}
  </RadioGroup>
);

SelectRadioGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  setOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
};

export default SelectRadioGroup;
