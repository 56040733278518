import { gql } from '@apollo/client';

export const GET_COLLECTION_MAIL_ENUM = gql`
  query GetCollectionMailEnum{
    getCollectionMailEnum{
      value
      label
    }
  }
`;

export const GET_RATIFICATION_MAIL_ENUM = gql`
query GetRatificationMailEnum{s
  getRatificationMailEnum{
    value
    label
  }
}
`;
