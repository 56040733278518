import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DocumentList } from '@fingo/lib/components/lists';
import { DOCUMENTS } from '@fingo/lib/graphql';
import useInvoiceLucilaPreColumns from '@fingo/lib/constants/invoice-lucila-pre-columns';
import DebtorProfileSummary from './DebtorProfileSummary';

const DebtorProfileFactoring = ({ debtor, refetchDebtor }) => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    if (updateTrigger) {
      setSelectedDocumentIds([]);
      setUpdateTrigger(false);
    }
  }, [updateTrigger]);

  return (
    <DocumentList
      trackerId="COLLECTION_INVOICES"
      type="collection-debtor-profile"
      headerTitle={debtor.name}
      queryDocument={DOCUMENTS}
      checkboxSelection
      serverFilters={false}
      onSelectionModelChange={(ids) => setSelectedDocumentIds(ids)}
      includeHeaders={[
        'folio',
        'dateIssued',
        'company_MasterEntity_Name',
        'amountWithIva',
        'surplusDebt',
        'dateToPay',
        'collectionPriority',
        'actionsDrawer',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_Name',
        'cessionStatus',
      ]}
      allCompaniesOptions
      getRowId={(row) => row.id}
      updateTrigger={updateTrigger}
      setUpdateTrigger={setUpdateTrigger}
      customVariables={{
        receiver_Rut: debtor.rut,
        inCollection: true,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
      }}
      customSummaryElement={(
        <DebtorProfileSummary
          debtor={debtor}
          documentType="invoice"
          refetchDebtor={refetchDebtor}
          setTriggerRefetchDocument={setUpdateTrigger}
          selectedDocumentIds={selectedDocumentIds}
        />
      )}
      preColumns={useInvoiceLucilaPreColumns()}
    />
  );
};

DebtorProfileFactoring.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  debtor: PropTypes.object.isRequired,
  refetchDebtor: PropTypes.func.isRequired,
};

export default DebtorProfileFactoring;
