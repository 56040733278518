import React from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton } from '@mui/material';
import { Chat as ChatIcon } from '@mui/icons-material';
import { useBooleanState } from '../../hooks';
import ChatDialog from './ChatDialog';

const ChatButton = ({ invoice }) => {
  const [open, toggleOpen] = useBooleanState(false);
  return (
    <>
      <IconButton onClick={toggleOpen} size="large" id="chat">
        <Badge>
          <ChatIcon />
        </Badge>
      </IconButton>
      <ChatDialog open={open} onClose={toggleOpen} invoice={invoice} />
    </>
  );
};

ChatButton.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    unreadMessages: PropTypes.number,
    binnacleSet: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default ChatButton;
