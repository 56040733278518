import {
  offerDateToPaySelected,
  offerSelected,
  orderingInvoiceIssuedSelected,
  orderingOfferSelected,
  orderingSelected,
  preofferSelected,
  debtorProfileData,
  updateVar,
  refreshToken as currentRefreshToken,
} from '.';
import { customMutation } from '../../helpers/custom-mutation';
import { REVOKE_REFRESH_TOKEN } from '../../graphql';

const setShowReferralCampaignSelectedCompany = (company) => {
  updateVar('showReferralCampaignSelectedCompany', company.showReferralCampaign);
};

const setSelectedFolio = (folio) => {
  updateVar('selectedFolio', folio);
};

const setSelectedCompanyRutFromExternalLogin = ({ actualCompanyRut }) => {
  updateVar('selectedCompanyRut', actualCompanyRut);
};

const cleanOfferShoppingCart = () => {
  offerSelected([]);
  offerDateToPaySelected({});
};

const cleanPreOfferShoppingCart = () => {
  preofferSelected([]);
};

const cleanOrderingShoppingCart = () => {
  orderingSelected([]);
  orderingInvoiceIssuedSelected({});
};

const cleanOrderingOfferShoppingCart = () => {
  orderingOfferSelected([]);
};

const cleanAllShopping = () => {
  cleanOfferShoppingCart();
  cleanOrderingShoppingCart();
  cleanPreOfferShoppingCart();
  cleanOrderingOfferShoppingCart();
};

const setDebtorProfileData = (profileData) => {
  debtorProfileData(profileData);
};

const cleanDebtorProfileData = () => {
  debtorProfileData({});
};

const logIn = ({ token, refreshToken }) => {
  cleanAllShopping();
  updateVar('token', token);
  updateVar('refreshToken', refreshToken);
  updateVar('userEvents', []);
};

const logOut = (client) => {
  cleanAllShopping();
  if (currentRefreshToken()) {
    customMutation(REVOKE_REFRESH_TOKEN, { refreshToken: currentRefreshToken() });
  }
  updateVar('token', undefined);
  updateVar('refreshToken', undefined);
  if (client) {
    localStorage.removeItem('apollo-cache-persist');
    localStorage.removeItem('apolloVariables');
    client.cache.reset();
  }
};

export {
  logIn,
  logOut,
  setDebtorProfileData,
  setShowReferralCampaignSelectedCompany,
  setSelectedFolio,
  setSelectedCompanyRutFromExternalLogin,
  cleanDebtorProfileData,
  cleanOfferShoppingCart,
  cleanPreOfferShoppingCart,
  cleanOrderingShoppingCart,
  cleanOrderingOfferShoppingCart,
  cleanAllShopping,
};
