import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';

const backToFront = {
  TRANSFER: 'Transfer',
  BANKTICKET: 'BankTicket',
  CHECK: 'Check',
};

const SelectPaymentMethod = ({ onChange, defaultValue }) => {
  const [selectedValue, setSelectedValue] = useState(backToFront[defaultValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <Select
      value={selectedValue}
      onChange={handleChange}
      displayEmpty
      variant="outlined"
      style={{ minWidth: 165 }}
    >
      <MenuItem value="">Método de pago</MenuItem>
      <MenuItem value="Transfer">Transferencia</MenuItem>
      <MenuItem value="BankTicket">Vale vista</MenuItem>
      <MenuItem value="Check">Cheque</MenuItem>
    </Select>
  );
};

SelectPaymentMethod.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

SelectPaymentMethod.defaultProps = {
  defaultValue: '',
};

export default SelectPaymentMethod;
