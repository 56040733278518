/* eslint-disable */
export const formatMoney = (amount, decimalCount = 0, decimal = ',', thousands = '.') => {
  let newDecimalCount = decimalCount;
  let newAmount = amount;
  try {
    newDecimalCount = Math.abs(newDecimalCount);
    newDecimalCount = isNaN(newDecimalCount) ? 2 : newDecimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(newAmount = Math.abs(Number(newAmount) || 0)
      .toFixed(newDecimalCount), 10)
      .toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (newDecimalCount ? decimal + Math.abs(newAmount - i).toFixed(newDecimalCount).slice(2) : '');
  } catch (e) {
    console.error(e);
    return ''
  }
};

export const formatMillion= (x) => {
  return `${formatMoney((x/1000000).toFixed(0))} MM`;
};
