import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link, Stack, useTheme } from '@mui/material';
import OnboardingTooltip from '../../tooltips/OnboardingTooltip';

const LoggedInNavItems = ({ pages }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={3}
      height={55}
      alignItems="center"
      justifyContent="flex-start"
      ml={4}
      sx={{ marginInline: 0 }}
    >
      {pages.map(({ id, label, path, exactMatch, disabled }) => (
        <OnboardingTooltip disabled={disabled}>
          <Link
            id={id}
            key={label}
            disabled={disabled}
            to={path}
            activeStyle={{
              color: theme.palette.primary.main,
              fontWeight: '500',
            }}
            variant="h2"
            color="text.main"
            component={NavLink}
            exact={exactMatch}
            sx={{
              '&:hover': {
                color: 'primary.main',
                textDecoration: 'none',
              },
              textDecoration: 'none',
            }}
          >
            {label}
          </Link>
        </OnboardingTooltip>
      ))}
    </Stack>
  );
};

LoggedInNavItems.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      shouldShow: PropTypes.func,
      action: PropTypes.string,
    }),
  ).isRequired,
};

export default LoggedInNavItems;
