import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import FingoStack from '@fingo/lib/components/stacks/FingoStack';
import { Button } from '@mui/material';
import { Cached } from '@mui/icons-material';
import ExecutiveProportionsDialog from './ExecutiveProportionsDialog';
import { UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION } from '../../../graphql/executives';

const ExecutivesActions = ({ executives, setExecutives, onRefresh, loading }) => {
  const [openExecutiveProportionsDialog, setOpenExecutiveProportionsDialog] = useState(false);
  const [updateUserExecutiveProportions] = useMutation(
    UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION,
    {
      onCompleted: ({ updateUserExecutiveAssignationProportion: { reason } }) => {
        const updatedExecutivesIds = reason.map((executive) => executive.id);
        setExecutives(executives.map((executive) => {
          if (updatedExecutivesIds.includes(executive.id)) {
            const updatedExecutive = reason.find((ex) => ex.id === executive.id);
            return { ...executive, ...updatedExecutive };
          }

          return executive;
        }));
      },
    },
  );

  const handleUpdateExecutiveProportions = (assignationType, selectedExecutives) => {
    const assignationKey = assignationType.includes('login') ? 'loginActiveAssignation' : 'clickActiveAssignation';
    const input = selectedExecutives
      .filter((executive) => executive.active && executive[assignationKey])
      .map((executive) => ({
        id: executive.user.id,
        assignationProportion: executive[assignationType],
      }));
    updateUserExecutiveProportions({ variables: { assignationType, input } });
  };

  return (
    <>
      <ExecutiveProportionsDialog
        open={openExecutiveProportionsDialog}
        executives={executives}
        handleUpdateExecutive={handleUpdateExecutiveProportions}
        handleClose={() => setOpenExecutiveProportionsDialog(false)}
      />
      <FingoStack>
        <LoadingIconButton
          onClick={onRefresh}
          sx={{ mr: { xs: '0', md: 'auto' } }}
          loading={loading}
          color="gray"
        >
          <Cached sx={{ display: { xs: 'none', md: 'inherit' } }} />
        </LoadingIconButton>
        <Button
          id="executive-propotions-dialog"
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setOpenExecutiveProportionsDialog(true)}
        >
          Proporción ejecutivos
        </Button>
      </FingoStack>
    </>
  );
};

ExecutivesActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  executives: PropTypes.array.isRequired,
  setExecutives: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ExecutivesActions;
