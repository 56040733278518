import React from 'react';
import momentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableCell,
  Tooltip,
  Typography,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HelpOutline } from '@mui/icons-material';
import { Status } from '../dataGridCells';
import { formatRut, formatMoney, getTimeDiffText, getTermTextBetweenDates } from '../../helpers';
import { FingoDateRangePicker } from '../datePickers';

const preOfferStatusToText = {
  CREATED: 'Disponible',
  EVALUATING: 'En evaluación',
};

const offerConditionToText = {
  WHITHOUT_RATIFICATION_CLIENT: 'Sin ratificación',
  WHITHOUT_RATIFICATION_DEBTOR: 'Sin ratificación',
  WITH_RATIFICATION: 'Con ratificación',
};

const offerConditionToTooltip = {
  WHITHOUT_RATIFICATION_CLIENT: 'Te depositaremos el dinero en aproximadamente 30-60 minutos.',
  WHITHOUT_RATIFICATION_DEBTOR: 'Te depositaremos el dinero en aproximadamente 30-60 minutos.',
  WITH_RATIFICATION: 'Te depositaremos el dinero cuando tu cliente nos confirme que se entregaron los productos o servicios.',
};

const useStyles = makeStyles((theme) => ({
  nameReceiver: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  statusCell: {
    paddingTop: '13px !important',
  },
  surplusCell: {
    paddingRight: '1rem !important',
    width: 130,
  },
  amountMinusInterests: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 100,
  },
}));

const Cell = ({ type, data, extraData, extraData2, tooltip, handleChangeDate }) => {
  const classes = useStyles();
  if (type === 'rut') {
    return (
      <TableCell>
        {formatRut(data)}
      </TableCell>
    );
  }
  if (type === 'text') {
    return (
      <TableCell size="small">
        {data}
      </TableCell>
    );
  }
  if (type === 'longText') {
    return (
      <TableCell>
        <Tooltip title={data}>
          <Typography variant="body2" className={classes.nameReceiver}>{data}</Typography>
        </Tooltip>
      </TableCell>
    );
  }
  if (type === 'date') {
    return (
      <TableCell size="small">
        <div className={classes.dateContainer}>
          <Typography className={classes.date}>{data.format('DD-MM-YYYY')}</Typography>
          <Typography variant="caption">{getTimeDiffText(data)}</Typography>
        </div>
      </TableCell>
    );
  }
  if (type === 'comparedDate') {
    return (
      <TableCell size="small">
        <div className={classes.dateContainer}>
          <Typography className={classes.date}>{data.format('DD-MM-YYYY')}</Typography>
          <Tooltip title={tooltip}>
            <Typography variant="caption">{getTermTextBetweenDates(extraData, data)}</Typography>
          </Tooltip>
        </div>
      </TableCell>
    );
  }
  if (type === 'money') {
    return (
      <TableCell size="small">
        ${formatMoney(data)}
      </TableCell>
    );
  }
  if (type === 'importantMoney') {
    return (
      <TableCell size="small" className={classes.amountMinusInterests}>
        ${formatMoney(data)}
      </TableCell>
    );
  }
  if (type === 'rate') {
    return (
      <TableCell size="small">
        {(data).toFixed(2)}%
      </TableCell>
    );
  }
  if (type === 'status') {
    return (
      <TableCell className={classes.statusCell}>
        <Status status={data} paymentDate={extraData} />
      </TableCell>
    );
  }
  if (type === 'preOfferStatus') {
    return (
      <TableCell size="small">
        {preOfferStatusToText[data]}
      </TableCell>
    );
  }
  if (type === 'inputDate') {
    // extraData is dateIssued and extraData2 is dateExpiration
    const dateIssued = extraData.clone();
    const dateExpiration = extraData2.clone();
    const daysUntilExpiration = dateExpiration.diff(moment().startOf('day'), 'days');
    // If issued and expiration date are equal, allow up to 60 days extra of financing
    const maxDate = extraData.unix() === dateExpiration.unix()
      ? dateIssued.add(60, 'days')
      // if dates are different, allow up to 180 days of financing
      // or 60 days more than the expiration date
      : moment().add(Math.min(180, daysUntilExpiration + 60), 'days');
    return (
      <TableCell size="small" align="right">
        <div className={classes.datePickerContainer}>
          <FingoDateRangePicker
            style={{ width: '90%' }}
            disablePast
            maxDate={maxDate}
            value={data}
            onChange={handleChangeDate}
            inputProps={{
              style: {
                padding: 5,
                fontSize: 12,
              },
            }}
          />
          {data && (
            <Typography variant="caption" align="left" style={{ color: '#417DFF' }}>
              En {data.diff(moment().startOf('day'), 'days')} días
            </Typography>
          )}
        </div>
      </TableCell>
    );
  }
  if (type === 'surplus') {
    return (
      <TableCell size="small" className={classes.surplusCell}>
        <Grid container direction="column">
          <Typography align="right">${formatMoney(data)}</Typography>
          <Typography align="right" variant="caption">Retención: ${formatMoney(extraData)}</Typography>
          <Typography align="right" variant="caption">Mora: ${formatMoney(extraData2)}</Typography>
        </Grid>
      </TableCell>
    );
  }
  if (type === 'condition') {
    return (
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography align="left" variant="caption">{offerConditionToText[data]}</Typography>
          <Tooltip
            arrow
            placement="left"
            title={offerConditionToTooltip[data]}
          >
            <HelpOutline style={{ marginLeft: '1rem' }} />
          </Tooltip>
        </div>
      </TableCell>
    );
  }
  return null;
};

Cell.propTypes = {
  type: PropTypes.oneOf(['text', 'longText', 'date', 'comparedDate', 'money', 'importantMoney', 'rate', 'status', 'inputDate', 'rut', 'preOfferStatus', 'surplus']).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGRATIFICATION', 'REJECTEDRATIFICATION', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']),
    momentPropTypes.momentObj,
  ]).isRequired,
  extraData: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGRATIFICATION', 'REJECTEDRATIFICATION', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']),
    momentPropTypes.momentObj,
  ]),
  extraData2: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGRATIFICATION', 'REJECTEDRATIFICATION', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']),
    momentPropTypes.momentObj,
  ]),
  tooltip: PropTypes.string,
  handleChangeDate: PropTypes.func,
};

Cell.defaultProps = {
  extraData: '',
  extraData2: '',
  tooltip: '',
  handleChangeDate: () => { },
};

export default Cell;
