import { useQuery } from '@apollo/client';
import { offerDateToPaySelectedQuery, offerSelectedQuery } from '../../apollo/cache/localQuery';
import { formatYearMonthDay } from '../../helpers';
import { OFFER_SHOPPING_CART } from '../../graphql';

const useOfferShoppingCart = () => {
  const {
    data: { selectedOfferIds },
  } = useQuery(offerSelectedQuery);
  const {
    data: { selectedOfferDateToPay },
  } = useQuery(offerDateToPaySelectedQuery);
  const offerJoined = selectedOfferIds.map((invoiceId) => ({
    invoiceId,
    dateToPay: selectedOfferDateToPay[invoiceId]
      ? formatYearMonthDay(selectedOfferDateToPay[invoiceId]) : null,
  }));
  const { data: shoppingCartData, loading } = useQuery(
    OFFER_SHOPPING_CART,
    { variables: { offerSelected: offerJoined } },
  );
  const disabledShowCartFunction = () => (
    offerJoined.filter((off) => off.dateToPay === null).length > 0
  );
  const disabledShowCart = disabledShowCartFunction();
  const thisShoppingCartData = shoppingCartData?.offerShoppingCart;
  return { shoppingCartData: thisShoppingCartData, disabledShowCart, loading };
};

export default useOfferShoppingCart;
