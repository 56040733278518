import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import DebtorsList from './Debtors/DebtorsList';
import DebtorProfile from './Debtors/DebtorProfile';

const Debtors = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Lista de deudores',
        path: 'debtors-list',
        component: <DebtorsList />,
      },
      {
        label: 'Deudor',
        path: 'debtor-profile',
        component: <DebtorProfile />,
      },
    ]}
  />
);

export default Debtors;
