import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import FingoDataGrid from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditExecutiveDialog from './executives/EditExecutiveDialog';
import ExecutivesActions from './executives/ExecutivesActions';
import { UPDATE_USER_EXECUTIVE } from '../../graphql/executives';
import { DATA_GRID_COLUMNS, HEADER_TITLE } from '../../constants/executives';

const Executives = ({ executives, setExecutives, loading, refetch }) => {
  const [selectedExecutive, setSelectedExecutive] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateUserExecutive] = useMutation(
    UPDATE_USER_EXECUTIVE,
    {
      onCompleted: ({ updateUserExecutive: { updateUserExecutive: data } }) => {
        setExecutives(executives.map((executive) => {
          if (executive.id === updateUserExecutive.id) return { ...executive, ...data };

          return executive;
        }));
      },
    },
  );

  const columns = [
    ...DATA_GRID_COLUMNS,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Editar',
      flex: 2,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit Executive"
          onClick={() => {
            setSelectedExecutive(params.row);
            setOpenEditDialog(true);
          }}
        />,
      ],
    },
  ];

  return (
    <>
      <PaperHeader viewTitle={HEADER_TITLE} />
      <ExecutivesActions
        executives={executives}
        setExecutives={setExecutives}
        onRefresh={refetch}
        loading={loading}
      />
      <FingoDataGrid
        rows={executives}
        columns={columns}
        loadingWithSkeleton={loading}
        getRowId={(row) => row.user.id}
        sx={{
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            overflow: 'hidden',
            lineHeight: '20px',
            whiteSpace: 'normal',
          },
        }}
        headerHeight={70}
        density="compact"
      />
      <EditExecutiveDialog
        open={openEditDialog}
        executive={selectedExecutive}
        handleUpdateExecutive={(variables) => updateUserExecutive({ variables })}
        handleClose={() => {
          setSelectedExecutive(null);
          setOpenEditDialog(false);
        }}
      />
    </>
  );
};

Executives.propTypes = {
  executives: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  setExecutives: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  refetch: PropTypes.func.isRequired,
};

Executives.defaultProps = {
  executives: null,
  loading: true,
};

export default Executives;
