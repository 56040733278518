import React from 'react';
import moment from 'moment';
import {
  Grid,
  Typography,
  Stack } from '@mui/material';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import { formatRut, formatMoney, getTimeDiffText } from '../helpers';
import { useIsMobile } from '../hooks';
import { AmountWithIvaMessage } from '../components/tooltips';
import { Status, StatusSummaryStepper } from '../components/dataGridCells';
import { UploadDocumentButton } from '../components/buttons';
import useWidth from './document-columns-width';
import { preOfferStatusToText } from './preoffer-status-to-text';
import { ActionDrawerCell, CompanyCell, MasterEntityNameCell } from '../components/cells';

const useInvoiceLucilaPreColumns = () => {
  const draggableProps = (dataSearch) => (
    { draggable: true,
      onDrag: (e) => { e.preventDefault(); e.newData = dataSearch; },
    });
  const isMobile = useIsMobile();
  const {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    rutWidth,
    iconSize,
    statusWidth,
    cessionStatusWidth,
  } = useWidth();
  return [
    {
      field: 'folio',
      headerName: 'Folio',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: isMobile ? 60 : 90,
      renderCell: (params) => (
        <Typography>
          {params.row.folio}
        </Typography>
      ),
    },
    {
      field: 'dteType_Code',
      headerName: 'Tipo',
      type: 'singleSelect',
      valueOptions: [{ label: 'Factura Electronica', value: 33 },
        { label: 'Factura Exenta Electronica', value: 34 },
        // { label: 'Liquidacion Factura Electronica', value: 43 },
        // { label: 'Factura de Compra Electronica', value: 46 },
        // { label: 'Nota de Credito de Exportacion Electronica', value: 112 },
        { label: 'Nota de Credito Electronica', value: 61 },
        { label: 'Nota de Debito Electronica', value: 56 }],
      // { label: 'Guia de Despacho Electronica', value: 52 },
      // { label: 'Factura de Exportacion Electronica', value: 110 },
      // { label: 'Nota de Debito de Exportacion Electronica', value: 111 }],
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography
            variant="body2"
          >
            {params.row.dteType.name}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'dateIssued',
      width: dateWidth,
      type: 'date',
      headerName: 'Emisión',
      filterable: false,
      renderCell: (params) => (
        params.row.dateIssued
          ? (
            <Grid container justifyContent="center" alignItems="flex-start" direction="column">
              <Typography variant="body2">{params.row.dateIssued.format(isMobile ? 'DD MMM' : 'DD-MM-YYYY')}</Typography>
              {!isMobile && (
              <Typography variant="caption">{getTimeDiffText(params.row.dateIssued)}</Typography>
              )}
            </Grid>
          ) : <></>
      ),
    },
    {
      field: 'receptionDate',
      width: dateWidth,
      type: 'dateTime',
      headerName: 'Recepción SII',
      renderCell: (params) => (
        <Grid container justifyContent="center" alignItems="flex-start" direction="column">
          <Typography variant="body2">{params.row.receptionDate.format('DD-MM-YYYY')}</Typography>
          <Typography variant="caption">{getTimeDiffText(params.row.receptionDate)}</Typography>
        </Grid>
      ),
    },
    {
      field: 'company_MasterEntity_Name',
      headerName: 'Emisor',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <MasterEntityNameCell
          name={params.row.company.masterEntity.name}
          loanedStatus={params.row.loanedStatus}
          loanedTo={params.row.loanedTo}
        />
      ),
    },
    {
      field: 'company_MasterEntity_Rut',
      headerName: 'Rut emisor',
      type: 'string',
      width: rutWidth,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start" {...draggableProps(params.row.company.rut)}>
          <Typography variant="body2">
            {formatRut(params.row.company.rut)}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'receiver_Name',
      headerName: 'Receptor',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <CompanyCell
          name={params.row.receiver.name}
          rut={formatRut(params.row.receiver.rut)}
        />
      ),
    },
    {
      field: 'amountWithIva',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center" width="100%">
          <Typography variant="body2" align="left" noWrap width="inherit">
            ${formatMoney(params.row.amountWithIva)}
          </Typography>
          <AmountWithIvaMessage
            creditNotes={params.row.creditNotes}
            debitNotes={params.row.debitNotes}
          />
        </Stack>
      ),
    },
    {
      field: 'surplusDebt',
      headerName: 'Mora',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography variant="body2">
            ${formatMoney(params.row.surplusDebt)}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'monthlyRate',
      headerName: 'Tasa',
      type: 'number',
      sortable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2" align="left">
          {params.row.preoffer ? (
            `${(params.row.preoffer.monthlyRate).toFixed(2).replace('.', ',')}%`
          ) : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'defaultRate',
      headerName: 'Mora',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2" align="left">
          {params.row.preoffer ? (
            `${(params.row.preoffer.defaultRate).toFixed(2).replace('.', ',')}%`
          ) : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'offerMonthlyRate',
      headerName: 'Tasa',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.offer ? (
            `${(params.row.offer.monthlyRate).toFixed(2).replace('.', ',')}%`
          ) : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'offerDefaultRate',
      headerName: 'Mora',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.offer ? (
            `${(params.row.offer.defaultRate).toFixed(2).replace('.', ',')}%`
          ) : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'siiStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: statusWidth,
      type: 'singleSelect',
      align: 'center',
      renderCell: (params) => (
        <StatusSummaryStepper
          invoice={params.row}
        />
      ),
    },
    {
      field: 'factoringStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      width: 230,
      renderCell: (params) => (
        <Typography variant="body2">
          {typeof preOfferStatusToText[params.row.preofferSelectable.status] === 'function'
            ? preOfferStatusToText[params.row.preofferSelectable.status](params.row)
            : preOfferStatusToText[params.row.preofferSelectable.status]}
        </Typography>
      ),
    },
    {
      field: 'cessionStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: cessionStatusWidth,
      align: 'center',
      renderCell: (params) => (
        <Status
          status={params.row.status}
          paymentDate={params.row.paymentDate}
          invoice={params.row}
        />
      ),
    },
    {
      field: 'dateToPay',
      headerName: isMobile ? 'Fecha' : 'Fecha de pago',
      ...GRID_DATE_COL_DEF,
      width: dateInputWidth,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start" overflow="hidden" direction="column">
          <Typography variant="body1">
            {moment(params.row.dateToPay).format('DD-MM-YYYY')}
          </Typography>
          <Typography variant="caption">{getTimeDiffText(params.row.dateToPay)}</Typography>
        </Grid>
      ),
    },
    {
      field: 'uploadDocuments',
      headerName: '',
      sortable: false,
      filterable: false,
      width: iconSize,
      renderCell: (params) => (
        <UploadDocumentButton invoiceId={params.row.id} />
      ),
    },
    {
      field: 'collectionPriority',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <Typography>{params.row.collectionManager?.priority}</Typography>
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      width: 80,
      renderCell: (params) => (
        <ActionDrawerCell
          masterEntityData={params.row.company.masterEntity}
          actions={params.row.collectionManager.actions}
        />
      ),
    },
  ];
};

export default useInvoiceLucilaPreColumns;
