import { useLazyQuery } from '@apollo/client';
import { openUrlAndDownload } from '../helpers';

const useExportDocument = (query, variables, filename = 'documentos.xlsx') => {
  const [exportDocuments, { loading }] = useLazyQuery(
    query,
    {
      onCompleted: (_data) => openUrlAndDownload(Object.values(_data)[0], filename),
      onError: () => {},
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables,
    },
  );
  return { exportDocuments, loading };
};

export default useExportDocument;
