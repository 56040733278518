import { useState } from 'react';
import { useQuery } from '@apollo/client';

const useEnum = (enumQuery) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  if (enumQuery !== null) {
    useQuery(enumQuery, {
      onCompleted: (data) => {
        setOptions(Object.values(data)[0]);
      },
    });
  }
  return [options, selectedOption, setSelectedOption];
};

export default useEnum;
