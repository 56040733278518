import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import PendingPurchaseOrders from './evaluations/PendingPurchaseOrders';
// eslint-disable-next-line import/no-named-as-default

const Evaluations = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Leads externos',
        path: 'external-leads',
        component: <PendingPurchaseOrders />,
      },
    ]}
  />
);

export default Evaluations;
