import React, { useEffect, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { FullHeightBox } from '../boxes';
import { SidebarItem, LogOutSidebarItem } from './sidebar/index';
import { FadeSwitch } from '../switches';
import useIsMobile from '../../hooks/useIsMobile';
import OnboardingTooltip from '../tooltips/OnboardingTooltip';

const drawerWidth = 190;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Sidebar = ({ menuOptions, showLogOut, goBack }) => {
  const isMobile = useIsMobile('lg');
  const [open, setOpen] = useState(!goBack);
  const { path: routePath } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (goBack) {
      setOpen(false);
      return;
    }
    setOpen(!isMobile);
  }, [isMobile, goBack]);

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  const handleBack = () => history.goBack();

  return (
    <FullHeightBox
      sx={(_theme) => ({
        display: 'flex',
        pr: { xs: 0, md: 4 },
        py: { xs: 0.5 },
        [_theme.breakpoints.down('md')]: {
          pl: 0,
          flexDirection: 'column',
        },
      })}
    >
      {goBack && (
        <IconButton
          onClick={handleBack}
          color="primary"
          sx={{ width: 'fit-content', display: { xs: 'auto', md: 'none' } }}
          id="goBack"
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
      )}
      <Drawer
        variant="permanent"
        open={open}
        sx={(fingoTheme) => (
          {
            '& .MuiDrawer-paper': {
              position: 'inherit',
              bgcolor: 'transparent',
              border: 0,
            },
            [fingoTheme.breakpoints.down('md')]: {
              display: 'none',
              width: 0,
            },
          }
        )}
        anchor="left"
        onClose={toggleDrawer}
      >
        <DrawerHeader>
          {goBack ? (
            <IconButton onClick={handleBack} color="primary" id="go-back">
              <ArrowBackOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton onClick={toggleDrawer} id="side-collapse">
              {open ? (
                <>
                  <KeyboardDoubleArrowLeft />
                  <Typography>Ocultar</Typography>
                </>
              ) : (
                <KeyboardDoubleArrowRight />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <List>
          {menuOptions.filter(({ icon }) => !!icon).map(
            ({ id, label, path, icon, disabled }, index) => (
              <OnboardingTooltip disabled={disabled}>
                <SidebarItem
                  id={id}
                  key={label}
                  label={label}
                  path={path}
                  icon={icon}
                  index={index}
                  open={open}
                  disabled={disabled}
                />
              </OnboardingTooltip>
            ),
          )}
          {showLogOut && <LogOutSidebarItem open={open} />}
        </List>
      </Drawer>
      <FadeSwitch depth={3}>
        {menuOptions.filter((route) => !route.disabled).map(({ path, component }) => (
          <Route key={path} path={`${routePath}/${path}`}>
            {component}
          </Route>
        ))}
        <Route key="all-routes">
          {menuOptions.filter((route) => !route.disabled)[0].component}
        </Route>
      </FadeSwitch>
    </FullHeightBox>
  );
};

Sidebar.propTypes = {
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.element,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  })).isRequired,
  showLogOut: PropTypes.bool,
  goBack: PropTypes.bool,
};

Sidebar.defaultProps = {
  showLogOut: false,
  goBack: false,
};

export default Sidebar;
