import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const FingoDialog = (props) => {
  const {
    open,
    handleClose,
    title,
    children,
    dialogActionButton,
    ...dialogProps
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: 4 } }}
      {...dialogProps}
    >
      <DialogTitle
        component="div"
        sx={{
          borderRadius: 4,
          bgcolor: 'primary.main',
          py: 3,
        }}
      >
        <Typography color="white" align="center" variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, px: 6 }}>
        {children}
      </DialogContent>
      <DialogActions disableSpacing>
        {dialogActionButton}
      </DialogActions>
    </Dialog>
  );
};

FingoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dialogActionButton: PropTypes.element,
};
FingoDialog.defaultProps = {
  dialogActionButton: null,
};

export default FingoDialog;
