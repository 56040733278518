import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Typography,
  Stack,
  Badge,
} from '@mui/material';
import { Add, Cached, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { LoadingIconButton } from '../buttons';
import { useBooleanState } from '../../hooks';
import DatesFilter from './DatesFilter';
import StatusFilter from './StatusFilter';
import { formatMinWordDate } from '../../helpers/date';
import FingoQuickFilter from './FingoQuickFilter';

const FilterButton = styled((props) => (
  <Button
    variant="text"
    color="text"
    endIcon={props.isdate ? <DateFilterDecoration /> : <StatusFilterDecoration />}
    size="small"
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.fingoWhite.main,
  width: 'auto',
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.primary.main,
    flex: 1,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    px: 2,
  },
}));

const FilterBadge = styled(Badge)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
}));

const FilterTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  [theme.breakpoints.down('md')]: {
    color: theme.palette.fingoWhite.main,
    fontSize: 10,
  },
}));

const DateFilterDecoration = styled(ExpandMore)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    color: theme.palette.fingoWhite.main,
  },
}));

const StatusFilterDecoration = styled(Add)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    color: theme.palette.fingoWhite.main,
  },
}));

const Filters = (
  {
    dates,
    setDates,
    financingStatusOptions,
    allCompaniesOptions,
    states,
    setStates,
    flexEndButtons,
    showQuickFilters,
    showStatesFilter,
    showDatesFilter,
    showRefresh,
    onRefresh,
    loading,
    setLoadingSii,
  },
) => {
  const [datesAnchorEl, setDatesAnchorEl] = useState(null);
  const [openStateFilter, setOpenStateFilter] = useState(false);
  const openDateFilter = ({ currentTarget }) => setDatesAnchorEl(currentTarget);
  const closeDateFilter = () => setDatesAnchorEl(null);
  const [isStatesClear, , setStatesClear, setStatesFull] = useBooleanState(false);
  const numberOfFiltersApplied = Object.keys(states).map(
    (key) => {
      if (Array.isArray(states[key])) {
        return states[key].length;
      }
      return states[key] ? 1 : 0;
    },
  ).reduce((partialSum, a) => partialSum + a, 0);
  return (
    <Stack
      alignItems="center"
      width="100%"
      borderRadius={2}
      py={1}
      ml={0}
      spacing={1}
      direction="row"
      bgcolor="background.light"
      sx={{
        px: { md: 2, xs: 0 },
        bgcolor: { md: 'background.light', xs: 'transparent' },
        justifyContent: { md: 'flex-end', xs: 'space-around' },
      }}
    >
      {showRefresh && (
        <LoadingIconButton
          onClick={onRefresh}
          sx={{ mr: { xs: '0', md: 'auto' } }}
          loading={loading}
          color="gray"
          id="refresh-documents"
        >
          <Cached />
        </LoadingIconButton>
      )}
      {showQuickFilters && (
        <FingoQuickFilter
          states={states}
          setStates={setStates}
          setLoadingSii={setLoadingSii}
        />
      )}
      {showStatesFilter && (
        <>
          <FilterBadge badgeContent={numberOfFiltersApplied} color="primary">
            <FilterButton
              onClick={() => setOpenStateFilter(true)}
              id="open-filters"
              sx={{
                borderColor: { md: 'primary.main', xs: 'text.primary' },
                borderWidth: isStatesClear ? 0 : 1,
                borderStyle: 'solid',
                minWidth: 124,
              }}
            >
              <FilterTypography variant="h6">
                Filtros
              </FilterTypography>
            </FilterButton>
          </FilterBadge>
          <StatusFilter
            openStateFilter={openStateFilter}
            close={() => setOpenStateFilter(false)}
            states={states}
            financingStatusOptions={financingStatusOptions}
            allCompaniesOptions={allCompaniesOptions}
            setStates={setStates}
            setStatesClear={setStatesClear}
            setStatesFull={setStatesFull}
          />
        </>
      )}
      {showDatesFilter && (
        <>
          <FilterButton
            isdate="true"
            onClick={openDateFilter}
            id="filter-dates"
          >
            <FilterTypography variant="h6">
              {`${formatMinWordDate(dates.dateIssued_Gte)} - ${formatMinWordDate(dates.dateIssued_Lte)}`}
            </FilterTypography>
          </FilterButton>
          <DatesFilter
            anchorEl={datesAnchorEl}
            close={closeDateFilter}
            dates={dates}
            setDates={setDates}
          />
        </>
      )}
      {flexEndButtons}
    </Stack>
  );
};

Filters.propTypes = {
  financingStatusOptions: PropTypes.bool,
  allCompaniesOptions: PropTypes.bool,
  dates: PropTypes.shape({
    dateIssued_Gte: PropTypes.instanceOf(moment),
    dateIssued_Lte: PropTypes.instanceOf(moment),
  }),
  setDates: PropTypes.func,
  states: PropTypes.shape({
    siiStatus: PropTypes.arrayOf(PropTypes.string),
    loanedStatus: PropTypes.string,
    creditNoteStatus: PropTypes.arrayOf(PropTypes.string),
    documentFinanceStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  setStates: PropTypes.func,
  flexEndButtons: PropTypes.element,
  showQuickFilters: PropTypes.bool,
  showStatesFilter: PropTypes.bool,
  showDatesFilter: PropTypes.bool,
  showRefresh: PropTypes.bool,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  setLoadingSii: PropTypes.func,
};

Filters.defaultProps = {
  dates: null,
  financingStatusOptions: false,
  allCompaniesOptions: false,
  setDates: () => {},
  states: {
    siiStatus: [],
    loanedStatus: '',
    creditNoteStatus: [],
    allCompanies: [],
    documentFinanceStatus: [],
  },
  setStates: () => {},
  flexEndButtons: null,
  showStatesFilter: true,
  showDatesFilter: true,
  showQuickFilters: false,
  showRefresh: false,
  onRefresh: () => {},
  loading: false,
  setLoadingSii: () => {},
};

export default Filters;
