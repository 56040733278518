import { gql } from '@apollo/client';
import { TraceFields } from '../fragments';

export const UPLOAD_INVOICE_DOCUMENTS = gql`
  mutation uploadInvoiceDocuments($invoiceId: Int!, $inputUploadFiles: [UploadFilesInvoiceDocumentManagerInputType]! ){
    uploadInvoiceDocuments(invoiceId: $invoiceId, inputUploadFiles: $inputUploadFiles){
      message
    }
  }`;

export const LOAD_NEW_INVOICES = gql`
  mutation LoadNewInvoices($companyId: Int!) {
    loadNewInvoices(companyId: $companyId) {
      taskId
    }
  }
`;

export const INVOICE_URL = gql`
  mutation invoicePdfUrl($invoiceId: Int!) {
    invoicePdfUrl(invoiceId: $invoiceId) {
      url
    }
  }
`;

export const LOAD_OFFERS_MISSING_XML = gql`
  mutation loadOffersMissingXml($invoiceIds: [Int]!) {
    loadOffersMissingXml(invoiceIds: $invoiceIds) {
      success
    }
  }
`;

export const UPLOAD_INVOICING_INSTRUCTIONS = gql`
  mutation uploadInvoicingInstructions($companyRut: Int!, $file: Upload!){
    uploadInvoicingInstructions(companyRut: $companyRut, file: $file){
      message
    }
  }
`;

export const CREATE_OPERATION = gql`
  mutation CreateOperation(
    $assignmentRequests: [AssignmentRequestType]
    $commission: Int!
    $bankAccountId: Int!
    $digitalCertificatePassword: String
    $negativeSurplus: Int
    $isExternal: Boolean!
    $companyRut: Int!
  ) {
    createOperation(
      assignmentRequests: $assignmentRequests
      commission: $commission
      bankAccountId: $bankAccountId
      digitalCertificatePassword: $digitalCertificatePassword
      negativeSurplus: $negativeSurplus
      isExternal: $isExternal
      companyRut: $companyRut
    ) {
    response
  }
}
`;

export const REQUEST_PRE_OFFERS_EVALUATION = gql`
  mutation RequestPreOfferEvaluation(
    $invoiceIds: [Int!]
    $email: String!
    $phoneNumber: String!
    $companyRut: Int!
  ) {
    requestPreOfferEvaluation(
      invoiceIds: $invoiceIds
      email: $email
      phoneNumber: $phoneNumber
      companyRut: $companyRut
    ) {
      requestReceived
    }
  }
`;

export const FETCH_TRACE = gql`
  mutation fetchTrace($invoiceId: Int!) {
    fetchTrace(invoiceId: $invoiceId) {
      invoiceTraces {
        ... TraceFields
      }
    }
  }
  ${TraceFields}
`;
