import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { isLoggedInQuery } from '@fingo/lib/apollo/cache/localQuery';
import { GET_USER } from '@fingo/lib/graphql';

function PrivateRoute({ component: Component, ...rest }) {
  const { data: { isLoggedIn: isLogged } } = useQuery(isLoggedInQuery);
  const { data: userData, refetch: refetchUser, loading: loadingUser } = useQuery(
    GET_USER,
    { fetchPolicy: 'network-only' },
  );
  const history = useHistory();
  useEffect(() => {
    if (!isLogged) {
      history.replace({
        pathname: '/',
        search: `?next=${history.location.pathname}${history.location.search}`,
      });
    }
    if (!userData || !userData.getUser) {
      refetchUser();
    }
  }, [isLogged, userData, refetchUser, history]);
  if (loadingUser) return null;
  if (!userData || !userData.getUser) return null;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

export default PrivateRoute;
