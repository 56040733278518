import { gql } from '@apollo/client';
import { OfferFields, PurchaseFields } from '../fragments';

export const GET_OFFERS = gql`
  query GetOffers($rut: Int!) {
    selectedCompanyRut @client @export(as: "rut")
    getOffers(ruts: [$rut]) {
      ... OfferFields
    }
  }
  ${OfferFields}
`;

export const GET_INVOICE = gql`
query GetInvoice($invoiceId: ID!, $inCollection: Boolean = true, $getFiles: Boolean = false, $getRates: Boolean = false) {
  invoice(invoiceId: $invoiceId) {
      ...PurchaseFields 
    } 
  } ${PurchaseFields} 
`;

export const DOCUMENTS = gql`
  query documents(
    $id_In: [String],
    $receiver_Rut: Int,
    $receiver_Rut_In: [Int],
    $receiver_Name_Iexact: String,
    $receiver_Name_Icontains: String,
    $receiver_Name_Istartswith: String,
    $company_MasterEntity_Rut: Int,
    $company_MasterEntity_Rut_In: [Int],
    $company_MasterEntity_Name_Iexact: String,
    $company_MasterEntity_Name_Icontains: String,
    $company_MasterEntity_Name_Istartswith: String,
    $dateIssued: Date,
    $dateIssued_Gte: Date,
    $dateIssued_Lte: Date,
    $dateIssued_Gt: Date,
    $dateIssued_Lt: Date,
    $receptionDate: DateTime,
    $receptionDate_Gte: DateTime,
    $receptionDate_Lte: DateTime,
    $receptionDate_Gt: DateTime,
    $receptionDate_Lt: DateTime,
    $amountWithIva: Int,
    $amountWithIva_Gte: Int,
    $amountWithIva_Lte: Int,
    $amountWithIva_Gt: Int,
    $amountWithIva_Lt: Int,
    $globalFilter: String,
    $orderBy: String,
    $companyId: Int,
    $first: Int,
    $offset: Int,
    $loanedStatus: [String],
    $siiStatus: [String],
    $creditNoteStatus: [String],
    $allReceiverCompany: Boolean,
    $allIssuedCompany: Boolean,
    $dteType_Code: String,
    $dteType_Code_In: [String],
    $folio: Int,
    $folio_Gte: Int,
    $folio_Lte: Int,
    $folio_Gt: Int,
    $folio_Lt: Int,
    $availableForFinancing: String,
    $hasValidOffer: Boolean,
    $hasBeenCeded: Boolean,
    $inCollection: Boolean = false,
    $getFiles: Boolean = false,
    $getRates: Boolean = true,
    $documentfinancestate_Status_Code_In: [String]
    $documentfinancestate_PaymentDate_Gte: Date,
    $documentfinancestate_PaymentDate_Lte: Date,
  ) {
    invoices(
      id_In: $id_In,
      receiver_Rut: $receiver_Rut,
      receiver_Rut_In: $receiver_Rut_In,
      receiver_Name_Iexact: $receiver_Name_Iexact, 
      receiver_Name_Icontains: $receiver_Name_Icontains, 
      receiver_Name_Istartswith: $receiver_Name_Istartswith, 
      company_MasterEntity_Rut: $company_MasterEntity_Rut,
      company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In,
      company_MasterEntity_Name_Iexact: $company_MasterEntity_Name_Iexact, 
      company_MasterEntity_Name_Icontains: $company_MasterEntity_Name_Icontains, 
      company_MasterEntity_Name_Istartswith: $company_MasterEntity_Name_Istartswith, 
      dateIssued: $dateIssued,
      dateIssued_Gte: $dateIssued_Gte,
      dateIssued_Lte: $dateIssued_Lte,
      dateIssued_Gt: $dateIssued_Gt,
      dateIssued_Lt: $dateIssued_Lt,
      receptionDate: $receptionDate,
      receptionDate_Gte: $receptionDate_Gte,
      receptionDate_Lte: $receptionDate_Lte,
      receptionDate_Gt: $receptionDate_Gt,
      receptionDate_Lt: $receptionDate_Lt,
      amountWithIva: $amountWithIva,
      amountWithIva_Gte: $amountWithIva_Gte,
      amountWithIva_Lte: $amountWithIva_Lte,
      amountWithIva_Gt: $amountWithIva_Gt,
      amountWithIva_Lt: $amountWithIva_Lt,
      globalFilter: $globalFilter,
      orderBy: $orderBy,
      companyId: $companyId,
      first: $first,
      offset: $offset,
      loanedStatus: $loanedStatus,
      siiStatus: $siiStatus,
      creditNoteStatus: $creditNoteStatus,
      allReceiverCompany: $allReceiverCompany,
      allIssuedCompany: $allIssuedCompany,
      dteType_Code_In: $dteType_Code_In,
      dteType_Code: $dteType_Code,
      folio: $folio,
      folio_Gte: $folio_Gte,
      folio_Lte: $folio_Lte,
      folio_Gt: $folio_Gt,
      folio_Lt: $folio_Lt,
      availableForFinancing: $availableForFinancing,
      hasValidOffer: $hasValidOffer,
      hasBeenCeded: $hasBeenCeded,
      inCollection: $inCollection,
      documentfinancestate_Status_Code_In: $documentfinancestate_Status_Code_In,
      documentfinancestate_PaymentDate_Gte: $documentfinancestate_PaymentDate_Gte,
      documentfinancestate_PaymentDate_Lte: $documentfinancestate_PaymentDate_Lte,
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ... PurchaseFields
        }
      }
      flatInvoices @client {
        ... PurchaseFields
      }
    }
  }
  ${PurchaseFields}
`;

export const EXPORT_INVOICES = gql`
query
  exportInvoices(
    $receiver_Rut: Int,
    $receiver_Rut_In: [Int],
    $receiver_Name_Iexact: String,
    $receiver_Name_Icontains: String,
    $receiver_Name_Istartswith: String,
    $company_MasterEntity_Rut: Int,
    $company_MasterEntity_Rut_In: [Int],
    $company_MasterEntity_Name_Iexact: String,
    $company_MasterEntity_Name_Icontains: String,
    $company_MasterEntity_Name_Istartswith: String,
    $dateIssued: Date,
    $dateIssued_Gte: Date,
    $dateIssued_Lte: Date,
    $dateIssued_Gt: Date,
    $dateIssued_Lt: Date,
    $receptionDate: DateTime,
    $receptionDate_Gte: DateTime,
    $receptionDate_Lte: DateTime,
    $receptionDate_Gt: DateTime,
    $receptionDate_Lt: DateTime,
    $amountWithIva: Int,
    $amountWithIva_Gte: Int,
    $amountWithIva_Lte: Int,
    $amountWithIva_Gt: Int,
    $amountWithIva_Lt: Int,
    $globalFilter: String,
    $orderBy: String,
    $companyId: Int,
    $first: Int,
    $offset: Int,
    $loanedStatus: [String],
    $siiStatus: [String],
    $creditNoteStatus: [String],
    $allReceiverCompany: Boolean,
    $allIssuedCompany: Boolean,
    $dteType_Code: String,
    $dteType_Code_In: [String],
    $folio: Int,
    $folio_Gte: Int,
    $folio_Lte: Int,
    $folio_Gt: Int,
    $folio_Lt: Int,
    $rowCount: Int!,
    $documentfinancestate_Status_Code_In: [String],
    $documentfinancestate_PaymentDate_Gte: Date,
    $documentfinancestate_PaymentDate_Lte: Date,
  ) {
    exportInvoices(
      receiver_Rut: $receiver_Rut,
      receiver_Rut_In: $receiver_Rut_In,
      receiver_Name_Iexact: $receiver_Name_Iexact, 
      receiver_Name_Icontains: $receiver_Name_Icontains, 
      receiver_Name_Istartswith: $receiver_Name_Istartswith, 
      company_MasterEntity_Rut: $company_MasterEntity_Rut,
      company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In,
      company_MasterEntity_Name_Iexact: $company_MasterEntity_Name_Iexact, 
      company_MasterEntity_Name_Icontains: $company_MasterEntity_Name_Icontains, 
      company_MasterEntity_Name_Istartswith: $company_MasterEntity_Name_Istartswith, 
      dateIssued: $dateIssued,
      dateIssued_Gte: $dateIssued_Gte,
      dateIssued_Lte: $dateIssued_Lte,
      dateIssued_Gt: $dateIssued_Gt,
      dateIssued_Lt: $dateIssued_Lt,
      receptionDate: $receptionDate,
      receptionDate_Gte: $receptionDate_Gte,
      receptionDate_Lte: $receptionDate_Lte,
      receptionDate_Gt: $receptionDate_Gt,
      receptionDate_Lt: $receptionDate_Lt,
      amountWithIva: $amountWithIva,
      amountWithIva_Gte: $amountWithIva_Gte,
      amountWithIva_Lte: $amountWithIva_Lte,
      amountWithIva_Gt: $amountWithIva_Gt,
      amountWithIva_Lt: $amountWithIva_Lt,
      globalFilter: $globalFilter,
      orderBy: $orderBy,
      companyId: $companyId,
      first: $first,
      offset: $offset,
      loanedStatus: $loanedStatus,
      siiStatus: $siiStatus,
      creditNoteStatus: $creditNoteStatus,
      allReceiverCompany: $allReceiverCompany,
      allIssuedCompany: $allIssuedCompany,
      dteType_Code_In: $dteType_Code_In,
      dteType_Code: $dteType_Code,
      folio: $folio,
      folio_Gte: $folio_Gte,
      folio_Lte: $folio_Lte,
      folio_Gt: $folio_Gt,
      folio_Lt: $folio_Lt,
      rowCount: $rowCount,
      documentfinancestate_Status_Code_In: $documentfinancestate_Status_Code_In,
      documentfinancestate_PaymentDate_Gte: $documentfinancestate_PaymentDate_Gte,
      documentfinancestate_PaymentDate_Lte: $documentfinancestate_PaymentDate_Lte,
    )}
`;

export const COUNT_FACTORING = gql`
    query CountFactoring(
      $company_MasterEntity_Rut_In: [Int],
    ) {
      preoffers: invoices(
        company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In,
        availableForFinancing: "AVAILABLE",
      ) {
        totalCount
      }
      offers: invoices(
        company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In,
        hasValidOffer: true,
      ) {
        totalCount
      }
      cessions: invoices(
        company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In,
        hasBeenCeded: true,
      ) {
        totalCount
      }
    }
`;
