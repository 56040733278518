import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const LoggedOutNavItems = ({ withRegister }) => {
  const theme = useTheme();
  const activeStyle = {
    color: theme.palette.primary.main,
    fontWeight: '500',
  };

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Link
        to="/login"
        sx={{
          marginRight: '2rem',
          '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
          },
          textDecoration: 'none',
        }}
        activeStyle={activeStyle}
        variant="h2"
        color="text.main"
        component={NavLink}
        id="nav-login"
      >
        Ingresar
      </Link>
      {withRegister && (
        <Link
          to="/register"
          sx={{
            marginRight: '2rem',
            '&:hover': {
              color: theme.palette.primary.main,
              textDecoration: 'none',
            },
            textDecoration: 'none',
          }}
          activeStyle={activeStyle}
          variant="h2"
          color="text.main"
          component={NavLink}
          id="nav-register"
        >
          Empezar
        </Link>
      )}
    </Grid>
  );
};

LoggedOutNavItems.propTypes = {
  withRegister: PropTypes.bool,
};

LoggedOutNavItems.defaultProps = {
  withRegister: true,
};

export default LoggedOutNavItems;
