import React, { useCallback } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, DialogActions, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getTimeDiffText } from '../../helpers';

const CustomActions = ({ onAllActions, closeDatePicker, showSelectAll }) => (
  <DialogActions>
    <Button
      disabled={!showSelectAll()}
      variant="contained"
      color="primary"
      onClick={onAllActions}
      id="selectAllDates"
      size="small"
      sx={{ mx: 'auto', marginBottom: 1, marginTop: -4 }}
    >
      Seleccionar fecha para todas
    </Button>
    <Button
      variant="outlined"
      color="primary"
      size="small"
      sx={{ mx: 'auto', marginBottom: 1, marginTop: -4 }}
      onClick={closeDatePicker}
    >
      Aceptar
    </Button>
  </DialogActions>
);

CustomActions.propTypes = {
  onAllActions: PropTypes.func.isRequired,
  closeDatePicker: PropTypes.func.isRequired,
  showSelectAll: PropTypes.func.isRequired,
};

const GridEditDateCell = ({ field, value, row, onChange, minDate, maxDate }) => {
  const apiRef = useGridApiContext();
  const changeRowValue = useCallback((changedRow, newValue) => {
    apiRef.current.setEditCellValue({ id: changedRow.id, field, value: newValue });
    apiRef.current.commitCellChange({ id: changedRow.id, field });
    apiRef.current.setCellMode(changedRow.id, field, 'view');
    apiRef.current.selectRow(changedRow.id);
    onChange(changedRow, newValue);
  }, [apiRef, field, onChange]);
  const handleChange = (newValue) => {
    changeRowValue(row, newValue);
  };
  const formatValue = useCallback((momentDate) => {
    if (momentDate) {
      return momentDate.format('DD/MM/YYYY');
    }
    return '';
  }, []);
  const closeDatePicker = useCallback(() => {
    apiRef.current.getCellElement(row.id, field).click();
  }, [apiRef]);
  const selectAll = useCallback((newValue) => {
    apiRef.current.getSelectedRows().forEach((changedRow) => {
      changeRowValue(changedRow, newValue);
    });
    closeDatePicker();
  }, [apiRef, changeRowValue]);
  const showSelectAll = useCallback(() => {
    const selectedRows = apiRef.current.getSelectedRows();
    return selectedRows.size > 0;
  }, [apiRef]);
  return (
    <DatePicker
      value={value || ''}
      renderInput={(params) => (
        <Stack>
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              value: formatValue(value),
              style: { padding: '5px 0px', textAlign: 'center' },
              'data-hj-allow': '',
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{ ...params.InputProps, sx: { fontSize: { xs: 10, md: 12 } } }}
          />
          <Typography variant="caption" sx={{ opacity: value ? 1 : 0 }}>
            {value ? getTimeDiffText(value) : 'No se ve este mensaje'}
          </Typography>
        </Stack>
      )}
      closeOnSelect={!showSelectAll()}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
      components={{
        ActionBar: CustomActions,
      }}
      componentsProps={{
        actionBar: { onAllActions: () => selectAll(value), closeDatePicker, showSelectAll },
      }}
      PopperProps={{
        placement: 'left-start',
      }}
    />
  );
};

GridEditDateCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  row: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
};

GridEditDateCell.defaultProps = {
  value: '',
  minDate: null,
  maxDate: null,
};

export default GridEditDateCell;
