import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';

const MasterEntityNameCell = ({ name, loanedStatus, loanedTo }) => {
  const draggableProps = (dataSearch) => (
    {
      draggable: true,
      onDrag: (e) => { e.preventDefault(); e.newData = dataSearch; },
    }
  );

  return (
    <Stack width="100%" alignItems="flex-start" justifyContent="center" {...draggableProps(name)}>
      <Typography variant="body2" width="inherit" noWrap>{name}</Typography>
      {['LOANED', 'LOANED_FINGO'].includes(loanedStatus) && (
        <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-start">
          <Warning color="warning" />
          <Typography noWrap variant="caption">Cedida {loanedTo && `a ${loanedTo}`}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

MasterEntityNameCell.propTypes = {
  name: PropTypes.string.isRequired,
  loanedStatus: PropTypes.string,
  loanedTo: PropTypes.string,
};

MasterEntityNameCell.defaultProps = {
  loanedStatus: '',
  loanedTo: '',
};

export default MasterEntityNameCell;
