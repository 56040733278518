import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '../fragments';

export const CONFIRM_ORDERING_SIMULATION = gql`
mutation ConfirmOrderingSimulation(
  $simulationId: Int!
  $orderingInterest: Int!
  $factoringInterest: Int!
  $orderingPaymentAmount: Int!
  $factoringPaymentAmount: Int!
  $invoiceIssuedDate: Date!
  $invoiceDateToPay: Date!
) {
  confirmOrderingSimulation(
  simulationId: $simulationId
  orderingInterest: $orderingInterest
  factoringInterest: $factoringInterest
  orderingPaymentAmount: $orderingPaymentAmount
  factoringPaymentAmount: $factoringPaymentAmount
  invoiceIssuedDate: $invoiceIssuedDate
  invoiceDateToPay: $invoiceDateToPay
  )
    {
      success
      message
    }
  }
`;

export const SIGN_ORDER_ASSIGNMENT_DOCUMENT = gql`
  mutation SignOrderAssignmentDocument (
    $id: String!
    $secretKey: String!
    $inCollection: Boolean = false
  ) {
    signOrderAssignmentDocument (
      id: $id
      secretKey: $secretKey
    ) {
      purchaseOrders {
        ... PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export const UPDATE_SIMULATION_SENT_REGISTER = gql`
mutation UpdateSimulationSentUrlRegister($url: String!, $urlOpened: Boolean!, $sentToSimulation: Boolean!, $sentToAutoregister: Boolean!){
  updateSimulationSentUrlRegister(url: $url, urlOpened: $urlOpened, sentToSimulation: $sentToSimulation, sentToAutoregister: $sentToAutoregister){
    success
    message
  }
}
`;

export const ASSIGN_INVOICES_TO_PURCHASE_ORDERS = gql`
  mutation AssignInvoicesToPurchaseOrders (
    $orderingAssignmentRequest: [OrderingAssginmentRequest]!
    $purchaseOrderIds: Int!
    $commission: Int!
    $isExternal: Boolean!
    $bankAccountId: Int
  ) {
    assignInvoicesToPurchaseOrders (
      orderingAssignmentRequest: $orderingAssignmentRequest
      purchaseOrderIds: $purchaseOrderIds
      commission: $commission
      isExternal: $isExternal
      bankAccountId: $bankAccountId
    ) {
      purchaseOrders {
        ... PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export const SEND_ORDER_TO_COLLECTION = gql`
mutation SendOrderToCollection (
  $orderId: Int!
) {
  sendOrderToCollection (
    orderId: $orderId
  ) {
    success
    message
  }
}
`;

export const PURCHASE_ORDERS_SEND_EVALUATE = gql`
  mutation purchaseOrderSendEvaluation(
    $purchaseOrders: [PurchaseOrderSimulationInput]!
    $inCollection: Boolean = false
  ) {
    purchaseOrderSendEvaluation(
      purchaseOrders: $purchaseOrders
    ) {
      purchaseOrders {
        ... PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export const PURCHASE_ORDERS_CONFIRM_OFFER = gql`
  mutation purchaseOrderConfirmOffer(
    $purchaseOrders: [Int]!,
    $bankAccountId: Int!
    $inCollection: Boolean = false
  ) {
    purchaseOrderConfirmOffer(
      purchaseOrders: $purchaseOrders,
      bankAccountId: $bankAccountId
    ) {
      purchaseOrders {
        ... PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export const CREATE_EXTERNAL_PURCHASE_ORDER = gql`
  mutation CreateExternalPurchaseOrder (
    $externalPurchaseOrders: [ExternalPurchaseOrderType]!
  ) {
    createExternalPurchaseOrder (
      externalPurchaseOrders: $externalPurchaseOrders
    ) {
      success
      message
    }
  }
`;
