import React from 'react';
import PropTypes from 'prop-types';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { IconButton, Tooltip } from '@mui/material';
import { useBooleanState } from '../../hooks';
import { UploadDocumentsManager } from '../inputs';

const UploadDocumentButton = ({ invoiceId }) => {
  const [open, toggleOpen] = useBooleanState(false);
  return (
    <>
      <Tooltip title="Adjuntar archivos">
        <IconButton
          onClick={toggleOpen}
          size="large"
          id="openUploadDocuments"
        >
          <UploadFileRoundedIcon />
        </IconButton>
      </Tooltip>
      <UploadDocumentsManager
        open={open}
        handleClose={toggleOpen}
        invoiceId={invoiceId}
      />
    </>
  );
};

UploadDocumentButton.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default UploadDocumentButton;
