import React from 'react';
import { useRouteMatch, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FadeSwitch } from '@fingo/lib/components/switches';
import { Sidebar } from '@fingo/lib/components/layout';
import { GET_USER } from '@fingo/lib/graphql';
import useLucilaRoutes from '../hooks/useLucilaRoutes';
import profileRoutes from '../features/profile/routes';

const App = () => {
  const { path } = useRouteMatch();
  const { data: { getUser: user } } = useQuery(GET_USER);
  const lucilaRoutes = useLucilaRoutes();
  const userRoutes = profileRoutes(user?.selectedCompany);

  return (
    <FadeSwitch depth={2}>
      {lucilaRoutes.map(({ label, children, path: compPath }) => (
        <Route key={label} path={compPath}>
          {children}
        </Route>
      ))}
      <Route path={`${path}/profile`}>
        <Sidebar
          showLogOut
          menuOptions={userRoutes.filter(({ show }) => show)}
        />
      </Route>
      <Route path={path}>
        <Redirect to={lucilaRoutes[0].path} />
      </Route>
    </FadeSwitch>
  );
};

export default App;
