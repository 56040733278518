import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import FormDataGrid from '@fingo/lib/components/dataGrids/FormDataGrid';
import { useSnackBars } from '@fingo/lib/hooks';
import { Button } from '@mui/material';
import { FORM_DATA_GRID_COLUMNS } from '../../../constants/external-leads';
import { ADD_EXTERNAL_LEADS } from '../../../graphql/external-leads';

const AddExternalLeadsDialog = ({ open, setOpen, refetchExternalLeads }) => {
  const [newExternalLeads, setNewExternalLeads] = useState([]);
  const disabled = !newExternalLeads.length || newExternalLeads.some((newExternalLead) => (
    Object.values(newExternalLead).some((value) => value === '')
  ));
  const { addAlert } = useSnackBars();
  const [addExternalLeads, { loading }] = useMutation(
    ADD_EXTERNAL_LEADS,
    {
      onCompleted: ({ addExternalLeads: { newLeads } }) => {
        setNewExternalLeads([]);
        refetchExternalLeads();
        setOpen(false);
        addAlert({
          id: 'new-leads',
          message: `Se añadieron ${newLeads.length} leads nuevos`,
        });
      },
      // eslint-disable-next-line no-console
      onError: () => { console.log('Hubo un error maldito'); },
    },
  );

  const addNewExternalLeads = () => {
    addExternalLeads({
      variables: {
        externalLeads: newExternalLeads.map((lead) => {
          const {
            masterEntityName: name,
            masterEntityRut,
            sourceName: source,
            typeName: type,
          } = lead;
          return {
            name,
            rut: masterEntityRut.split('-')[0],
            source,
            type,
          };
        }),
      },
    });
  };

  return (
    <FingoDialog
      open={open}
      handleClose={() => setOpen(false)}
      title="Añadir nuevos leads externos"
      dialogActionButton={(
        <Button
          color="primary"
          onClick={addNewExternalLeads}
          disabled={disabled || loading}
        >
          Añadir leads
        </Button>
      )}
      maxWidth="md"
      fullWidth
    >
      <FormDataGrid
        rows={newExternalLeads}
        setRows={setNewExternalLeads}
        columns={FORM_DATA_GRID_COLUMNS}
        density="compact"
      />
    </FingoDialog>
  );
};

AddExternalLeadsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refetchExternalLeads: PropTypes.func.isRequired,
};

export default AddExternalLeadsDialog;
