import { gql } from '@apollo/client';

export const InvoiceFields = gql`
  fragment InvoiceFields on InvoiceType {
    id
    company {
      rut
    }
    folio
    confirming
    nameReceiver
    rutReceiver
    dateIssued
    dateOperation
    dateExpiration
    dateToPay
    amountWithIva
    status
    loanedStatus
    siiStatus
    loanedTo
    creditNoteStatus
    preofferSelectable @client
    preoffer {
      id
      monthlyRate
      defaultRate
      retentionRate
      preofferevaluationrequest {
        status
      }
    }
    offer {
      id
    }
    operation {
      id
    }
  }
`;

export const PreofferFields = gql`
  fragment PreofferFields on InvoiceTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    totalPages
    edges {
      cursor
      node {
        ... InvoiceFields
      }
    }
    flatInvoices @client {
      ... InvoiceFields
    }
  }
  ${InvoiceFields}
`;

export const OfferFields = gql`
  fragment OfferFields on OffersResponseType {
    offers {
      id
      company {
        rut
      }
      folio
      confirming
      nameReceiver
      rutReceiver
      dateIssued
      dateOperation
      dateExpiration
      dateToPay
      amountWithIva
      hasXml
      offer {
        id
        monthlyRate
        defaultRate
        retentionRate
        transferCondition
        fundingDaysAfterExpiration
      }
      amounts @client
    }
    debtorCreditLines {
      id
      totalAmount
      creditUsed
      approvedOverdraft
      company {
        rut
        name
      }
    }
  }
`;

export const TraceFields = gql`
  fragment TraceFields on TraceType {
    id
    eventCode
    eventTime
  }
`;

export const PurchaseFields = gql`
  fragment PurchaseFields on InvoiceType {
    id
    folio
    dateIssued
    dateExpiration
    receptionDate
    dateToPay
    status
    loanedTo
    loanedToRut
    amountWithIva
    integrationStatus
    loanedStatus
    creditNoteStatus
    siiStatus
    unreadMessages
    paymentDate
    surplusDebt
    preofferSelectable @client
    amounts @client
    pdf @include(if: $getFiles)
    certificateUrl @include(if: $getFiles)
    invoiceDocuments @include(if: $getFiles) {
      url
      documentType
    }
    dteType {
      id
      code
      name
    }
    binnacleSet{
      message
      username
      id
      mail
      username
    }
    paymentMethod @client 
    invoicedetail {
      id
      purchaseOrderFolio
    }
    references {
      id
      folio
      amountWithIva
      dteType {
        id
        code
        name
      }
    }
    reverseReferences {
      id
      folio
      amountWithIva
      dteType {
        id
        code
        name
      }
    }
    creditNotes @client {
      id
      folio
      amountWithIva
    }
    debitNotes @client {
      id
      folio
      amountWithIva
    }
    cessions {
      id
      cessionDate
      transferor {
        rut
        name
      }
      assignee {
        rut
        name
      }
    }
    traces {
      ... TraceFields
    }
    company {
      id
      rut
      hasInvoiceProviderCredentials
      masterEntity {
        id
        rut
        name
      }
    }
    receiver {
      id
      rut
      name
      company {
        id
        rut
        instructions
        hasInvoiceProviderCredentials
      }
    }
    preoffer @include(if: $getRates) {
      id
      monthlyRate
      defaultRate
      retentionRate
      preofferevaluationrequest {
        status
      }
    }
    offer @include(if: $getRates){
      id
      monthlyRate
      defaultRate
      retentionRate
      transferCondition
      fundingDaysAfterExpiration
    }
    operation {
      id
    }
    collectionManager @include(if: $inCollection) {
      id
      priority
      collector {
        id
        firstName
        lastName
      }
      actions {
        id
        comment
        actionType
        createdAt
      }
    }
    lightningPaymentAuthorized @client
    uniqueDocumentFinanceState{
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      eventTime : date 
      status{
        id
        code
        status
      }
      rejectionCode{
        id
        code
        reason
      }
      bank {
        id
        name
      }
    }
    providerTrace @client {
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      eventTime : date 
      status{
        code
        status
      }
      bank {
        id
        name
      }
    }
    providerRejectionReasons @client {
      id
      comments
      isAutomatic
      eventTime : date 
      status{
        code
        status
      }
      rejectionCode{
        code
        reason
      }
    }
  }
  ${TraceFields}
`;

export const InvoiceMessageFields = gql`
  fragment InvoiceMessageFields on InvoiceMessageType {
    id
    user {
      id
      firstName
      lastName
    }
    message
    messageType
    createdAt
    updatedAt
    invoice {
      id
    }
    trace {
      id
      eventTime
    }
  }
`;

export const PurchaseOrderFragment = gql`
  fragment PurchaseOrderFragment on OrderingPurchaseOrderType {
    id
    orderStatus
    publicationDate
    totalAmount
    currency
    orderNumber
    status
    pdfFile @include(if: $getFiles)
    simulationSelectable @client
    nonAvailableReason @client
    company {
      id
      rut
      masterEntity {
        id
        rut
        name
      }
    }
    orderingDebt {
      days
      debt
    }
    collectionManager @include(if: $inCollection) {
      id
      priority
      actions {
        id
        comment
        createdAt
        actionType
      }
    }
    orderingsimulation @include(if: $getRates) {
      id
      orderingMonthlyRate
      orderingDefaultRate
      orderingRetentionRate
      orderingAmount
      otherDiscounts
      orderingRetention
      orderingFinancedAmount
      orderingCommission
      orderingInterest
      orderingPaymentAmount
      factoringMonthlyRate
      factoringDefaultRate
      factoringRetentionRate
      factoringInterest
      factoringCommission
      factoringRetention
      factoringPaymentAmount
      invoiceIssuedDate
      invoiceDateToPay
    }
    orderingoffer @include(if: $getRates) {
      id
      createdAt
      updatedAt
      orderingMonthlyRate
      orderingDefaultRate
      orderingRetentionRate
      orderingAmount
      otherDiscounts
      orderingRetention
      orderingFinancedAmount
      orderingCommission
      orderingInterest
      orderingPaymentAmount
      factoringMonthlyRate
      factoringDefaultRate
      factoringRetentionRate
      factoringInterest
      factoringCommission
      factoringRetention
      factoringPaymentAmount
      invoiceIssuedDate
      invoiceDateToPay
      operationDate
      invoiceFundingDaysAfterExpiration
    }
    purchaser {
      id
      rut
      name
    }
  }
`;

export const ShoppingFields = gql`
  fragment ShoppingFields on ShoppingCartType {
    invoiceCount
    totalAmount
    priceDifference
    commission
    totalCosts
    retainedAmount
    depositAmount
  }
`;

export const CompanyFields = gql`
  fragment CompanyFields on CompanyType {
    rut
    name
    phoneNumber
    hasBankAccount
    isAcepta
    bankAccounts {
      id
      bankName
      accountType
      accountNumber
      accountEmail
      accountName
      active
    }
    invoiceProvider {
      id
      name
      integrated
      iconUrl
    }
    currentCommissionRules {
      id
      threshold
      variant
      value
    }
    currentCreditLine {
      id
      totalAmount
      creditUsed
      approvedOverdraft
      availableLine @client
    }
    integrationMethod
    hasSiiCredentials
    hasInvoiceProviderCredentials
    hasSupplierPortalCredentials
    hasAcceptedterms
    hasDigitalCertificate
    hasCertificateCredentials
    hasOrderingDebt
    hasFrameworkContract
    hasMissingCredentials
    currentSurplusBalanceValue
    negativeSurplusBalance @client
    showReferralCampaign
    isSenegocia
    isOrdering
    groupModules {
      id
      code
      permissions {
        id
        code
        navTitle
      }
    }
    navBarTitles @client
  }
`;

export const UserFields = gql`
  fragment UserFields on UserType {
    id
    rut
    email
    firstName
    lastName
    completeName @client
    phoneNumber
    mailProvided
    registerComplete
    defaultCompanyRut
    hasProfileInfoMissing
    isSuperuser
    isStaff
    hasDummyPassword
    requireChangePassword
    userGroups
    userRegistrationSteps {
      step
      success
    }
    userseentutorialSet {
      id
      tutorialCode
      seen
    }
    activeNpsResponse {
      id
      npsType {
        id
        name
        questions
        title
        reasons {
          category
          categoryToEs
        }
      }
    }
    companies {
      id
      rut
      name
      masterEntity {
        id
        rut
        name
      }
    }
    selectedCompany {
      ... CompanyFields
    }
    isAustralisStaff @client
    completeRut
  }
  ${CompanyFields}
`;

const NotificationTypeConnectionFragment = gql`
  fragment NotificationTypeConnectionFragment on NotificationTypeConnection {
    totalCount
    edges {
      node {
        id
        timestamp
        level
        unread
        actorStr
        verbStr
        targetStr
        redirectionPath
        redirectionSearch
      }
    }
  }
`;

export const NotificationMenuFields = gql`
  fragment NotificationMenuFields on UserType {
    notificationsUnreadCount: notifications(first: 7, unread: true) {
      ... NotificationTypeConnectionFragment
    }
    all: notifications(first: 7) {
      ... NotificationTypeConnectionFragment
    }
    mentions: notifications(first: 7, level: "mention") {
      ... NotificationTypeConnectionFragment
    }
  }
  ${NotificationTypeConnectionFragment}
`;
