import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridRowModes, DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DataGridToolbar from './DataGridToolbar';

const FormDataGrid = ({ rows, setRows, columns, boxProps, ...dataGridProps }) => {
  const [rowModesModel, setRowModesModel] = useState({});

  // eslint-disable-next-line no-param-reassign
  const handleRowEditStart = (params, event) => { event.defaultMuiPrevented = true; };

  // eslint-disable-next-line no-param-reassign
  const handleRowEditStop = (params, event) => { event.defaultMuiPrevented = true; };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => { setRows(rows.filter((row) => row.id !== id)); };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const dataGridColumns = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': { color: 'text.secondary' },
        '& .textPrimary': { color: 'text.primary' },
      }}
      {...boxProps}
    >
      <DataGrid
        rows={rows}
        columns={dataGridColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{ Toolbar: DataGridToolbar }}
        componentsProps={{ toolbar: { setRows, setRowModesModel, columns } }}
        experimentalFeatures={{ newEditingApi: true }}
        {...dataGridProps}
      />
    </Box>
  );
};

FormDataGrid.propTypes = {
  rows: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setRows: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  boxProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

FormDataGrid.defaultProps = {
  boxProps: {},
};
export default FormDataGrid;
