import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatRut, formatMoney, documentToProductName, getTimeDiffText } from '@fingo/lib/helpers';
import { useIsMobile } from '@fingo/lib/hooks';
import { ActionDrawerCell, ActionCell } from '@fingo/lib/components/cells';
import {
  Grid,
  Typography,
  Link as MuiLink,
  Avatar,
  Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import ProgressBarCell from '../../../components/dataGridCells/ProgressBarCell';

const collectionDebtorColumns = () => {
  const isMobile = useIsMobile();
  return [
    {
      field: 'name',
      headerName: 'Deudor',
      type: 'string',
      width: 240,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <MuiLink
            component={Link}
            to={{
              pathname: '/app/collection/debtors/debtor-profile',
              search: `?debtorRut=${params.row.rut}&documentType=${params.row.documentType}`,
            }}
            color="primary"
            variant="body2"
            align="left"
            rel="noopener noreferrer"
            underline="hover"
          >
            {params.row.name}
          </MuiLink>
          { !isMobile && (
            <Typography
              variant="subtitle1"
            >
              {formatRut(params.row.rut)}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'documentType',
      headerName: 'Tipo',
      type: 'string',
      width: 70,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography variant="body2">
            {documentToProductName[params.row.documentType]}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'avatar',
      headerName: 'Cobrador',
      type: 'string',
      width: 80,
      renderCell: (params) => (
        <Tooltip
          arrow
          placement="top"
          title={
            `${params.row.collector?.firstName} ${params.row.collector?.lastName}`
          }
        >
          <Grid container alignItems="center" justifyContent="center">
            <Avatar alt={params.row.collector?.firstName} src={params.row.collector?.pictureUrl} />
          </Grid>
        </Tooltip>
      ),
    },
    {
      field: 'documentsCount',
      headerName: 'Docs',
      type: 'string',
      width: 50,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="body2">
            {params.row.documentsCount}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'olderExpiration',
      headerName: 'Mayor vencimiento',
      type: 'string',
      width: 140,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start" overflow="hidden" direction="column">
          <Typography variant="body1">
            {moment(params.row.olderExpiration).format('DD-MM-YYYY')}
          </Typography>
          <Typography variant="caption">{getTimeDiffText(params.row.olderExpiration)}</Typography>
        </Grid>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total en cobranza',
      type: 'string',
      width: 120,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography variant="body1">
            ${formatMoney(params.row.totalAmount)}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'priority',
      headerName: 'Prioridad',
      type: 'number',
      width: 80,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="body1">
            {params.row.priority}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'defaultRate',
      headerName: 'Vencido',
      type: 'string',
      headerAlign: 'center',
      width: 140,
      renderCell: (params) => (
        <ProgressBarCell
          value={params.row.defaultRate}
        />
      ),
    },
    {
      field: 'lastAction',
      headerName: 'Última gestión',
      type: 'datetime',
      width: 130,
      renderCell: (params) => (
        <ActionCell
          action={params.row.lastAction}
        />
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      width: 80,
      renderCell: (params) => (
        <ActionDrawerCell
          masterEntityData={params.row}
          actions={params.row.everyCollectionAction}
        />
      ),
    },
  ];
};

export default collectionDebtorColumns;
