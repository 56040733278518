import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Box, Stack, Typography } from '@mui/material';
import FingoDataGrid from '@fingo/lib/components/dataGrids';
import useInvoicePreColumns from '@fingo/lib/constants/invoice-pre-columns';
import { grey } from '@mui/material/colors';

const COLUMNS = [
  'folio',
  'dateIssued',
  'receiver_Name',
  'receiver_Rut',
  'amountWithIva',
  'monthlyRate',
  'defaultRate',
  'dateToPay',
];

const OrderingOperationExternalCessionDialogStepOne = (props) => {
  const {
    statistics,
    invoices,
    loading,
    selectedInvoiceIds,
    setSelectedInvoiceIds,
  } = props;

  return (
    <>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem sx={{ bgColor: grey[50] }} />}
        spacing={3}
        bgcolor="background.light"
        mb={3}
        p={3}
        justifyContent="space-around"
      >
        {statistics.map(({ label, value, color }) => (
          <Box
            key={label}
            sx={{
              height: 70,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-around',
              justifyContent: 'space-around',
              textAlign: 'center',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}</Typography>
            <Typography variant="body2" sx={{ fontSize: 14, color: color || 'text' }}>{value}</Typography>
          </Box>
        ))}
      </Stack>
      <FingoDataGrid
        rows={invoices}
        columns={useInvoicePreColumns().filter((col) => COLUMNS.includes(col.field))}
        loadingWithSkeleton={loading}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(ids) => { setSelectedInvoiceIds(ids); }}
        selectionModel={selectedInvoiceIds}
        density="compact"
      />
    </>
  );
};

OrderingOperationExternalCessionDialogStepOne.propTypes = {
  statistics: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  invoices: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool.isRequired,
  selectedInvoiceIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setSelectedInvoiceIds: PropTypes.func.isRequired,
};

export default OrderingOperationExternalCessionDialogStepOne;
