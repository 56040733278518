/* eslint-disable import/prefer-default-export */
import { ApolloClient, split } from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from '@apollo/client/utilities';
import { cacheCreator } from '../apollo/cache';
import { customFetch } from './custom-fetch';
import { token } from '../apollo/reactive-variables';

const cache = cacheCreator();
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
  fetch: (uri, options) => customFetch(uri, options),
});
const wsLink = new WebSocketLink(
  new SubscriptionClient(
    process.env.REACT_APP_WEBSOCKET_SERVER || 'ws://localhost/ws/graphql/',
    {
      lazy: true,
      options: {
        reconnect: true,
      },
      inactivityTimeout: 1000,
      connectionParams: () => ({
        token: token() || '',
      }),
    },
  ),
);

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: token() ? `JWT ${token()}` : '',
  },
}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(uploadLink),
);

export const apolloClient = new ApolloClient({
  cache,
  link: splitLink,
  resolvers: {},
});
