import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import { SwapHoriz } from '@mui/icons-material';
import StatusSummaryStepperFreemium from './StatusSummaryStepperFreemium';
import StatusSummaryStepperPremium from './StatusSummaryStepperPremium';

const docTypeDict = {
  33: {},
  34: {},
  56: { color: 'success', label: 'Nota de Débito' },
  61: { color: 'error', label: 'Nota de Crédito' },
};
export default function StatusSummaryStepper({ invoice }) {
  if (!invoice) { return <></>; }
  const { color, label } = docTypeDict[invoice.dteType.code];
  return (
    <>
      {['33', '34'].includes(invoice.dteType.code)
        ? (
          /* For invoices */
          <>
            {(invoice.uniqueDocumentFinanceState.length
              || [96949830,
                96524510,
                76787060,
                76090483,
                76003885,
                77104776,
                76003557,
                76899578,
                77104786,
                76005426,
                76787110,
                96556940,
                77201556].includes(invoice.receiver.rut)) ? (
                  <StatusSummaryStepperPremium invoice={invoice} />
              )
              : <StatusSummaryStepperFreemium invoice={invoice} />}
          </>
        ) : (
          /* For documents that are not invoices */
          <Stack alignItems="center" direction="row">
            <SwapHoriz size="small" color={color} sx={{ marginRight: 1 }} />
            <Typography sx={{ fontSize: '10px' }}>{label}</Typography>
          </Stack>
        )}
    </>
  );
}

StatusSummaryStepper.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    uniqueDocumentFinanceState: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    receptionDate: PropTypes.objectOf(moment).isRequired,
    receiver: PropTypes.shape({
      rut: PropTypes.number,
    }),
    dteType: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
};
