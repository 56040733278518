/* eslint-disable consistent-return */
import { offsetLimitPagination } from '@apollo/client/utilities';

const managerPaginationCache = (keyArgs) => {
  const offsetPag = offsetLimitPagination();
  const read = (existing, options) => {
    if (!existing) return;
    const offset = options.args.offset || 0;
    const first = options.args.first || 10;
    const limit = Math.min(offset + first, existing.totalCount);
    if (limit > existing.edges.length) return;
    const newReturn = existing.edges.slice(offset, limit);
    if (newReturn.length !== Object.values(newReturn).length) {
      return;
    }
    return {
      ...existing,
      edges: newReturn,
      pageInfo: {
        ...existing.pageInfo,
        hasPreviousPage: offset > 0,
        hasNextPage: limit < existing.totalCount,
      },
      totalPages: Math.ceil(existing.totalCount / first),
    };
  };
  const merge = (existing, incoming, options) => {
    const newMerged = offsetPag.merge(existing && existing.edges, incoming.edges, options);
    return {
      ...incoming,
      edges: newMerged,
    };
  };
  const keyArgsFunction = (fields) => {
    const paginatedArgs = ['after', 'before', 'offset', 'first', 'last'];
    return Object.keys(fields).filter(
      (field) => !paginatedArgs.includes(field),
    );
  };
  return {
    keyArgs: keyArgs || keyArgsFunction,
    read,
    merge,
  };
};

export default managerPaginationCache;
