import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import FingoStack from '@fingo/lib/components/stacks/FingoStack';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import { Button } from '@mui/material';
import { Cached } from '@mui/icons-material';
import AddInvoicesToPurchaseOrdersDialog from './AddInvoicesToPurchaseOrdersDialog';

const PendingPurchaseOrdersActions = (props) => {
  const {
    refetchPurchaseOrders,
    loading,
    selectedPurchaseOrdersIds,
  } = props;
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [
    openAddInvoicesToPurchaseOrdersDialog,
    setOpenAddInvoicesToPurchaseOrdersDialog,
  ] = useState(false);

  const [orderingPurchaseOrders] = useLazyQuery(
    ORDERING_PURCHASE_ORDERS,
    {
      variables: { id_In: selectedPurchaseOrdersIds },
      onCompleted: (data) => {
        setPurchaseOrders(data.purchaseOrders.edges.map((edge) => edge.node));
      },
    },
  );

  useEffect(() => {
    if (selectedPurchaseOrdersIds.length) orderingPurchaseOrders();
  }, [selectedPurchaseOrdersIds]);

  const disabled = useMemo(
    () => !purchaseOrders.length
      || [...new Set(purchaseOrders.map((oc) => oc.company.rut))].length > 1,
    [purchaseOrders],
  );

  return (
    <FingoStack>
      <LoadingIconButton
        onClick={() => refetchPurchaseOrders()}
        sx={{ mr: { xs: '0', md: 'auto' } }}
        loading={loading}
        color="gray"
      >
        <Cached sx={{ display: { xs: 'none', md: 'inherit' } }} />
      </LoadingIconButton>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenAddInvoicesToPurchaseOrdersDialog(true)}
        disabled={disabled}
      >
        Agregar múltiples facturas
      </Button>
      <AddInvoicesToPurchaseOrdersDialog
        open={openAddInvoicesToPurchaseOrdersDialog}
        setOpen={setOpenAddInvoicesToPurchaseOrdersDialog}
        purchaseOrders={purchaseOrders}
        refetchPurchaseOrders={refetchPurchaseOrders}
      />
    </FingoStack>
  );
};

PendingPurchaseOrdersActions.propTypes = {
  refetchPurchaseOrders: PropTypes.func,
  loading: PropTypes.bool,
  selectedPurchaseOrdersIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PendingPurchaseOrdersActions.defaultProps = {
  refetchPurchaseOrders: () => {},
  loading: false,
};

export default PendingPurchaseOrdersActions;
