import PropTypes from 'prop-types';

const invoicePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.number.isRequired,
    hasInvoiceProviderCredentials: PropTypes.bool,
    masterEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rut: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  receiver: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rut: PropTypes.number.isRequired,
      instructions: PropTypes.string,
      hasInvoiceProviderCredentials: PropTypes.bool,
    }),
  }),
});

export default invoicePropTypes;
