import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { SummaryCard, SummaryContactCard } from '@fingo/lib/components/cards';
import {
  DOCUMENTS,
  INVOICE_DEBTOR_SUMMARY,
  ORDERING_DEBTOR_SUMMARY,
  ORDERING_PURCHASE_ORDERS,
} from '@fingo/lib/graphql';
import { Button, Grid, Typography } from '@mui/material';
import SelectDebtorBehaviourCard from './SelectDebtorBehaviourCard';
import AddActionButton from '../../../../components/buttons/AddActionButton';
import OperativeMailButton from '../../../../components/buttons/OperativeMailButton';

const documentTypeToSummaryQuery = {
  ordering: ORDERING_DEBTOR_SUMMARY,
  invoice: INVOICE_DEBTOR_SUMMARY,
};

const documentTypeToDocumentQuery = {
  ordering: ORDERING_PURCHASE_ORDERS,
  invoice: DOCUMENTS,
};

const DebtorProfileSummary = ({
  debtor,
  documentType,
  refetchDebtor,
  selectedDocumentIds,
  setTriggerRefetchDocument,
}) => {
  const DOCUMENTS_QUERY = documentTypeToDocumentQuery[documentType];
  const SUMMARY_QUERY = documentTypeToSummaryQuery[documentType];
  const [debtorSummaryData, setDebtorSummaryData] = useState({});
  const [documents, setDocuments] = useState([]);
  useQuery(
    DOCUMENTS_QUERY,
    {
      variables: {
        id_In: selectedDocumentIds,
        inCollection: true,
        getRates: false,
        getFiles: true,
      },
      skip: selectedDocumentIds.length === 0,
      onCompleted: (data) => setDocuments(Object.values(data)[0].edges.map((doc) => doc.node)),
    },
  );
  useQuery(
    SUMMARY_QUERY,
    {
      variables: { rut: debtor.rut },
      skip: !debtor,
      onCompleted: (data) => setDebtorSummaryData(Object.values(data)[0]),
    },
  );
  useEffect(() => {
    if (selectedDocumentIds.length === 0) {
      setDocuments([]);
    }
  }, [selectedDocumentIds]);
  return (
    <Grid container direction="row" justifyContent="space-between">
      {debtor && (
        <Grid
          columnSpacing={1}
          rowSpacing={1}
          xs={5}
          sx={{
            display: 'flex',
            ml: -2,
            py: 1,
            '& .MuiGrid-item:first-of-type': {
              pl: 0,
            },
          }}
          justifyContent="space-evenly"
        >
          <Grid xs={6}>
            <SummaryContactCard
              contacts={debtor.collectionContacts}
              companyRut={debtor.rut}
              refetchDebtor={refetchDebtor}
            />
          </Grid>
          <Grid xs={5}>
            <SelectDebtorBehaviourCard debtor={debtor} refetchDebtor={refetchDebtor} />
          </Grid>
        </Grid>
      )}
      <Grid
        direction="row"
        justifyContent="space-between"
        xs={7}
        sx={{
          display: 'flex',
          bgcolor: 'background.light',
          borderRadius: 2,
          px: 1,
          ml: 0,
          py: 1,
          '& .MuiGrid-item:first-of-type': {
            pl: 0,
          } }}
      >
        {debtorSummaryData && (
          <>
            <Grid item xs={6} lg={3} sx={{ mx: 0.5 }}>
              <SummaryCard
                title={debtorSummaryData.currentAmount}
                subtitle="Deuda pendiente"
                isMoney
              />
            </Grid>
            <Grid item xs={6} lg={3} sx={{ mx: 0.5 }}>
              <SummaryCard
                title={debtorSummaryData.debtAmount}
                subtitle="Documentos vencidas"
                isMoney
                isWarning
              />
            </Grid>
            <Grid item xs={6} lg={3} sx={{ mx: 0.5 }}>
              <SummaryCard
                title={debtorSummaryData.currentValid}
                subtitle="Documentos vigentes"
                isMoney
              />
            </Grid>
            <Grid item xs={6} lg={3} sx={{ mx: 0.5 }}>
              <Grid container sx={{ borderRadius: 2, p: 1 }} height="100%">
                <AddActionButton
                  managerIds={documents.map((doc) => doc.collectionManager.id)}
                  operationType="collection"
                  targetRut={debtor.rut}
                  setTriggerRefetchDocument={setTriggerRefetchDocument}
                />
                <OperativeMailButton
                  selectedDocuments={documents}
                  operationType="collection"
                  documentType={documentType}
                  targetRut={debtor.rut}
                  setTriggerRefetchDocument={setTriggerRefetchDocument}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ m: 0.5, height: 30, width: '100%' }}
                  disabled
                >
                  <Typography>
                    Guardar cambios
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

DebtorProfileSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocumentIds: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  debtor: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  refetchDebtor: PropTypes.func.isRequired,
  setTriggerRefetchDocument: PropTypes.func.isRequired,
};

export default DebtorProfileSummary;
