import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const DoubleInputDateRangePicker = ({ title, dateFrom, dateTo, setDateFrom, setDateTo }) => {
  const from = dateFrom ? moment(dateFrom) : dateFrom;
  const to = dateTo ? moment(dateTo) : dateTo;
  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {title}
      </Typography>
      <Stack direction="row" justifyContent="space-around">
        <DatePicker
          value={from}
          onChange={(value) => setDateFrom(value.format('YYYY-MM-DD'))}
          InputAdornmentProps={{ position: 'start' }}
          maxDate={to}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Desde:"
              variant="standard"
              sx={{ width: '40%', alignSelf: 'left' }}
            />
          )}
        />
        <DatePicker
          disablePast
          value={to}
          onChange={(value) => setDateTo(value.format('YYYY-MM-DD'))}
          InputAdornmentProps={{ position: 'start' }}
          minDate={from}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Hasta:"
              variant="standard"
              sx={{ width: '40%', alignSelf: 'left' }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

DoubleInputDateRangePicker.propTypes = {
  title: PropTypes.string,
  dateFrom: PropTypes.oneOf([PropTypes.string, null]),
  dateTo: PropTypes.oneOf([PropTypes.string, null]),
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
};
DoubleInputDateRangePicker.defaultProps = {
  title: 'Fechas:',
  dateFrom: null,
  dateTo: null,
};

export default DoubleInputDateRangePicker;
