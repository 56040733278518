import { calculateDv, prettifyRut } from 'react-rut-formatter';
import { getRutValidatorDigit } from './generic';

const validEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validPhone = (phone) => (
  phone.length === 9
);

const validPhoneWithoutPrefix = (phone) => phone.length === 9;

const validPassword = (password) => password.length >= 4;

// Full rut example: 18.585.425-6
const fullRutToOnlyHyphen = (fullRut) => fullRut.replaceAll('.', '');

const validRut = (rut) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/; // https://stackoverflow.com/questions/46770938/regex-for-chilean-rut-run-with-pcre
  const [rutWithoutDV, dv] = fullRutToOnlyHyphen(rut).split('-');
  return re.test(rut) && getRutValidatorDigit(rutWithoutDV) === dv;
};

const unformatRut = (rut) => rut.replaceAll('.', '').replace('-', '');

const formatRut = (rut) => {
  if (!rut) {
    return '';
  }
  const rutStr = `${rut.toString()}-${calculateDv(rut)}`;
  return prettifyRut(rutStr);
};

const getFormFieldError = (value, type, name) => {
  switch (type) {
    case 'email':
      if (!validEmail(value)) {
        return { isError: true, message: 'Ingresa un email válido' };
      }
      return { isError: false, message: '' };
    case 'password':
      if (value.length < 4) {
        return { isError: true, message: 'Debe tener más de 4 caracteres' };
      }
      return { isError: false, message: '' };
    case 'number':
      if (name === 'phoneNumber' && value.length !== 9) {
        return { isError: true, message: 'Debe tener 9 dígitos' };
      }
      return { isError: false, message: '' };
    case 'text':
      if ((name === 'companyRut' || name === 'rut' || name === 'userRut') && !validRut(value)) {
        return { isError: true, message: 'Rut inválido' };
      }
      return { isError: false, message: '' };
    case 'select':
      if (value === '' || value === 'choose') {
        return { isError: true, message: 'Debes realizar una selección' };
      }
      return { isError: false, message: '' };
    default:
      return { isError: false, message: '' };
  }
};

export {
  validEmail,
  validRut,
  getFormFieldError,
  validPhone,
  validPhoneWithoutPrefix,
  validPassword,
  formatRut,
  unformatRut,
  fullRutToOnlyHyphen,
};
