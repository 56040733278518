import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Typography,
  Link as MuiLink,
  Stack,
} from '@mui/material';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import { formatRut, formatMoney, getTimeDiffText } from '../helpers';
import { useIsMobile, useSelectedCompany } from '../hooks';
import { offerDateToPaySelected } from '../apollo/reactive-variables';
import { AmountWithIvaMessage } from '../components/tooltips';
import { Status, GridEditDateCell, StatusSummaryStepper } from '../components/dataGridCells';
import { UploadDocumentButton } from '../components/buttons';
import { ChatButton } from '../components/chat';
import useWidth from './document-columns-width';
import { preOfferStatusToText } from './preoffer-status-to-text';
import { CompanyCell, MasterEntityNameCell } from '../components/cells';
import LightningFastPayment from '../components/tooltips/LightningFastPayment';

const useInvoicePreColumns = () => {
  const draggableProps = (dataSearch) => (
    { draggable: true,
      onDrag: (e) => { e.preventDefault(); e.newData = dataSearch; },
    });
  const dateToPayOnChange = (row, newValue) => offerDateToPaySelected(
    { ...offerDateToPaySelected(), [row.id]: newValue },
  );
  const rateStatus = (row, offerType, rateType) => {
    if (row[offerType]) {
      if (offerType === 'preoffer' && !row.preofferSelectable.selectable) {
        return 'N/D';
      }
      return `${(row[offerType][rateType]).toFixed(2).replace('.', ',')}%`;
    }
    return 'N/D';
  };
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    rutWidth,
    iconSize,
    statusWidth,
    cessionStatusWidth,
  } = useWidth();
  return [
    {
      field: 'folio',
      headerName: 'Folio',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: isMobile ? 60 : 65,
      renderCell: (params) => (
        <>
          <MuiLink
            component={Link}
            to={{
              pathname: '/app/invoice',
              search: `?invoiceId=${params.row.id}`,
            }}
            color="primary"
            variant="body2"
            align="left"
            rel="noopener noreferrer"
            underline="hover"
            noWrap
            width="inherit"
            {...draggableProps(params.row.folio)}
          >
            {params.row.folio}
          </MuiLink>
          {selectedCompany?.rut === params.row.company.rut
            && params.row.lightningPaymentAuthorized && (
            <LightningFastPayment invoice={params.row} />
          )}
        </>
      ),
    },
    {
      field: 'dteType_Code',
      headerName: 'Tipo',
      type: 'singleSelect',
      valueOptions: [{ label: 'Factura Electronica', value: 33 },
        { label: 'Factura Exenta Electronica', value: 34 },
        // { label: 'Liquidacion Factura Electronica', value: 43 },
        // { label: 'Factura de Compra Electronica', value: 46 },
        // { label: 'Nota de Credito de Exportacion Electronica', value: 112 },
        { label: 'Nota de Credito Electronica', value: 61 },
        { label: 'Nota de Debito Electronica', value: 56 }],
      // { label: 'Guia de Despacho Electronica', value: 52 },
      // { label: 'Factura de Exportacion Electronica', value: 110 },
      // { label: 'Nota de Debito de Exportacion Electronica', value: 111 }],
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography
            variant="body2"
          >
            {params.row.dteType.name}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'receptionDate',
      width: dateWidth,
      type: 'dateTime',
      headerName: 'Recepción SII',
      renderCell: (params) => (
        <Grid container justifyContent="center" alignItems="flex-start" direction="column">
          <Typography variant="body2">{params.row.receptionDate.format('DD-MM-YYYY')}</Typography>
          <Typography variant="caption">{getTimeDiffText(params.row.receptionDate)}</Typography>
        </Grid>
      ),
    },
    {
      field: 'company_MasterEntity_Name',
      headerName: 'Emisor',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <MasterEntityNameCell
          name={params.row.company.masterEntity.name}
          loanedStatus={params.row.loanedStatus}
          loanedTo={params.row.loanedTo}
        />
      ),
    },
    {
      field: 'company_MasterEntity_Rut',
      headerName: 'Rut emisor',
      type: 'string',
      width: rutWidth,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start" {...draggableProps(params.row.company.rut)}>
          <Typography variant="body2">
            {formatRut(params.row.company.rut)}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'receiver_Name',
      headerName: 'Receptor',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <CompanyCell
          name={params.row.receiver.name}
          rut={formatRut(params.row.receiver.rut)}
        />
      ),
    },
    {
      field: 'dateToPay',
      headerName: isMobile ? 'Fecha' : 'Fecha de pago',
      ...GRID_DATE_COL_DEF,
      width: dateInputWidth,
      filterable: false,
      resizable: false,
      sortable: false,
      renderEditCell: (params) => (
        <GridEditDateCell
          {...params}
          value={offerDateToPaySelected()[params.id]}
          onChange={dateToPayOnChange}
          minDate={moment().add(15, 'days')}
          maxDate={params.row.dateExpiration.clone().add(params.row.offer.fundingDaysAfterExpiration, 'days')}
        />
      ),
      renderCell: (params) => (
        <GridEditDateCell
          {...params}
          value={offerDateToPaySelected()[params.id]}
          onChange={dateToPayOnChange}
          minDate={moment().add(15, 'days')}
          maxDate={params.row.dateExpiration.clone().add(params.row.offer.fundingDaysAfterExpiration, 'days')}
        />
      ),
    },
    {
      field: 'dateIssued',
      width: dateWidth,
      type: 'date',
      headerName: 'Emisión',
      filterable: false,
      renderCell: (params) => (
        params.row.dateIssued
          ? (
            <Grid container justifyContent="center" alignItems="flex-start" direction="column">
              <Typography variant="body2">{params.row.dateIssued.format(isMobile ? 'DD MMM' : 'DD-MM-YYYY')}</Typography>
              {!isMobile && (
              <Typography variant="caption">{getTimeDiffText(params.row.dateIssued)}</Typography>
              )}
            </Grid>
          ) : <></>
      ),
    },
    {
      field: 'amountWithIva',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center" width="100%">
          <Typography variant="body2" align="left" noWrap width="inherit">
            ${formatMoney(params.row.amountWithIva)}
          </Typography>
          <AmountWithIvaMessage
            creditNotes={params.row.creditNotes}
            debitNotes={params.row.debitNotes}
          />
        </Stack>
      ),
    },
    {
      field: 'monthlyRate',
      headerName: 'Tasa',
      type: 'number',
      sortable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2" align="left" width="100%">
          {rateStatus(params.row, 'preoffer', 'monthlyRate')}
        </Typography>
      ),
    },
    {
      field: 'defaultRate',
      headerName: 'Mora',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2" align="left">
          {rateStatus(params.row, 'preoffer', 'defaultRate')}
        </Typography>
      ),
    },
    {
      field: 'offerMonthlyRate',
      headerName: 'Tasa',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {rateStatus(params.row, 'offer', 'monthlyRate')}
        </Typography>
      ),
    },
    {
      field: 'offerDefaultRate',
      headerName: 'Mora',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {rateStatus(params.row, 'offer', 'defaultRate')}
        </Typography>
      ),
    },
    {
      field: 'siiStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: statusWidth,
      type: 'singleSelect',
      align: 'center',
      renderCell: (params) => (
        <StatusSummaryStepper
          invoice={params.row}
        />
      ),
    },
    {
      field: 'factoringStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      width: 230,
      renderCell: (params) => (
        <Typography variant="body2">
          {typeof preOfferStatusToText[params.row.preofferSelectable.status] === 'function'
            ? preOfferStatusToText[params.row.preofferSelectable.status](params.row)
            : preOfferStatusToText[params.row.preofferSelectable.status]}
        </Typography>
      ),
    },
    {
      field: 'cessionStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: cessionStatusWidth,
      align: 'center',
      renderCell: (params) => (
        <Status
          status={params.row.status}
          paymentDate={params.row.paymentDate}
          invoice={params.row}
        />
      ),
    },
    {
      field: 'chat',
      headerName: 'Chat',
      sortable: false,
      filterable: false,
      width: iconSize,
      renderCell: (params) => <ChatButton invoice={params.row} />,
    },
    {
      field: 'uploadDocuments',
      headerName: '',
      sortable: false,
      filterable: false,
      width: iconSize,
      renderCell: (params) => (
        <UploadDocumentButton invoiceId={params.row.id} />
      ),
    },
    {
      field: 'collectionPriority',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <Typography>{params.row.collectionManager?.priority}</Typography>
      ),
    },
  ];
};

export default useInvoicePreColumns;
