import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import HtmlTooltip from './HtmlTooltip';
import { toggleSiiForm } from '../../apollo/reactive-variables';

const OnboardingTooltip = ({ disabled, children }) => {
  const openSiiForm = () => toggleSiiForm(true);
  return (
    <HtmlTooltip
      title={
            disabled
              ? (
                <Stack>
                  <Typography>
                    Este módulo está disponible solo para clientes
                    que hayan ingresado su clave del SII.
                  </Typography>
                  <Button
                    size="small"
                    color="fingoWhite"
                    sx={{ justifyContent: 'right' }}
                    onClick={openSiiForm}
                  >
                    Ingresa tu clave del SII →
                  </Button>
                </Stack>
              )
              : null
          }
    >
      <span> {/* This <span/> is to allow Tooltip to work when disabled */}
        {children}
      </span>
    </HtmlTooltip>
  );
};

OnboardingTooltip.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default OnboardingTooltip;
