import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import CollectionSummary from './Summary/CollectionSummary';

const Summary = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Facturas disponibles',
        path: 'available',
        component: <CollectionSummary />,
      },
    ]}
  />
);

export default Summary;
