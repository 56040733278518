/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { InvoiceMessageFields, PurchaseFields } from '../fragments';

export const READ_NOTIFICATIONS = gql`
mutation markNotificationAsRead($notificationIds: [ID]) {
  markNotificationAsRead(notificationIds: $notificationIds) {
    success
  }
}
`;

export const CREATE_INVOICE_MESSAGE = gql`
  mutation CreateInvoiceMessage($invoiceId: Int!, $message: String!, $messageType: InvoiceMessageTypeEnum!, $userIdMentions: [Int]) {
    createInvoiceMessage(invoiceId: $invoiceId, message: $message, messageType: $messageType, userIdMentions: $userIdMentions) {
      createdMessage{
        ... InvoiceMessageFields
      }
    }
  }
  ${InvoiceMessageFields}
`;

export const NEW_INVOICE_MESSAGE = gql`
  subscription NewInvoiceMessage($invoiceId: Int!) {
    newInvoiceMessage(invoiceId: $invoiceId) {
      invoiceMessage {
        ... InvoiceMessageFields
      }
    }
  }
  ${InvoiceMessageFields}
`;

export const READ_ALL_MESSAGES = gql`
  mutation readAllMessages($invoiceId: Int!) {
    readAllMessages(invoiceId: $invoiceId) {
      invoice {
        ... PurchaseFields
      }
    }
  }
  ${PurchaseFields}
`;
