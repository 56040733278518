import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';

const CollectionSummary = () => (
  <PaperHeader
    viewTitle="Resumen de cobranza"
  />
);

export default CollectionSummary;
