import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import CollectionHistoric from './Historic/CollectionHistoric';

const Historic = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Facturas disponibles',
        path: 'available',
        component: <CollectionHistoric />,
      },
    ]}
  />
);

export default Historic;
