import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import DataGridCheckbox from './DataGridCheckbox';
import DataGridLoader from './DataGridLoader';
import DataGridNoRowsOverlay from './DataGridNoRowsOverlay';
import DataGridRenderHeader from './DataGridRenderHeader';
import { DEFAULT_ROW_HEIGHT } from '../../constants/data-grid';
import { backendFiltersTransformation } from '../../helpers';
import useAvailableHeight from '../../hooks/useAvailableHeight';

const scrollerClass = makeStyles(() => ({ virtualScroller: { overflowX: 'hidden' } }));

const FingoDataGrid = (props) => {
  const {
    rows,
    columns,
    boxHeight,
    loadingWithSkeleton,
    noRowsMessage,
    serverFilters,
    setColumnFilter,
    setOrderBy,
    rowHeight,
    ...restProps
  } = props;
  const classes = scrollerClass();
  const boxRef = useRef();
  const [filterModel, setFilterModel] = useState();
  const [sortModel, _setSortModel] = useState();
  const height = useAvailableHeight(boxRef?.current);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'), { defaultMatches: true });
  const setSortModel = (model) => {
    setOrderBy((prev) => (
      model?.length ? `${model[0].sort === 'asc' ? '' : '-'}${model[0].field}` : prev
    ));
    _setSortModel(model);
  };

  const checkboxWidth = isMobile ? 18 : 30;
  const checkboxDef = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    minWidth: checkboxWidth,
    width: checkboxWidth,
    type: 'string',
  };
  const fingoColumns = columns.map((column) => ({ ...column, renderHeader: DataGridRenderHeader }));
  const minHeight = Math.min(height || 10, rowHeight * 7);
  const serverProps = {
    paginationMode: 'server',
    sortingMode: 'server',
    filterMode: 'server',
    sortModel,
    filterModel,
    onFilterModelChange: setFilterModel,
    onSortModelChange: setSortModel,
  };

  useEffect(() => {
    setColumnFilter(backendFiltersTransformation(filterModel?.items?.[0]));
  }, [filterModel, setColumnFilter]);
  return (
    <Box
      ref={boxRef}
      sx={{
        height: boxHeight || Math.max(height, minHeight),
        width: '100%',
        '& .littleHeader': {
          fontSize: 11,
        },
        minHeight: rowHeight,
      }}
      id="datagrid-box"
    >
      <DataGrid
        columns={[checkboxDef, ...fingoColumns]}
        rows={loadingWithSkeleton ? [] : rows}
        rowsPerPageOptions={[15, 25, 50, 100]}
        rowHeight={rowHeight}
        scrollbarSize={0}
        loading={loadingWithSkeleton}
        components={{
          LoadingOverlay: DataGridLoader,
          BaseCheckbox: DataGridCheckbox,
          NoRowsOverlay: DataGridNoRowsOverlay,
        }}
        componentsProps={{ noRowsOverlay: { message: noRowsMessage } }}
        classes={{ virtualScroller: classes.virtualScroller }}
        {...(serverFilters && serverProps)}
        {...restProps}
      />
    </Box>

  );
};

FingoDataGrid.propTypes = {
  columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  rows: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  loadingWithSkeleton: PropTypes.bool,
  noRowsMessage: PropTypes.element,
  serverFilters: PropTypes.bool,
  setColumnFilter: PropTypes.func,
  setOrderBy: PropTypes.func,
  rowHeight: PropTypes.number,
  boxHeight: PropTypes.element,
};

FingoDataGrid.defaultProps = {
  loadingWithSkeleton: false,
  noRowsMessage: <Typography variant="body1" align="center">Sin filas</Typography>,
  serverFilters: false,
  rowHeight: DEFAULT_ROW_HEIGHT,
  boxHeight: null,
  setColumnFilter: () => {},
  setOrderBy: () => {},
};

export default FingoDataGrid;
