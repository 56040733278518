/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoDataGrid from '@fingo/lib/components/dataGrids';
import { useIsMobile, useSnackBars } from '@fingo/lib/hooks';
import ExternalLeadsActions from './external-leads/ExternalLeadsActions';
import { GET_EXTERNAL_LEADS, ASSIGN_EXTERNAL_LEADS } from '../../graphql/external-leads';
import { DATA_GRID_COLUMNS, HEADER_TITLE } from '../../constants/external-leads';

const ExternalLeads = ({ executives, loading }) => {
  const [externalLeads, setExternalLeads] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const isMobile = useIsMobile();
  const { addAlert } = useSnackBars();
  const { loading: externalLeadsLoading, refetch: refetchExternalLeads } = useQuery(
    GET_EXTERNAL_LEADS,
    {
      variables: {
        first: pageSize,
        offset: page * pageSize,
      },
      onCompleted: ({ getExternalLeads }) => {
        setExternalLeads(getExternalLeads.edges.map((externalLead) => externalLead.node));
        setRowCount(getExternalLeads.totalCount);
      },
    },
  );
  const [assignExternalLeads, { loading: assignExternalLeadsLoading }] = useMutation(
    ASSIGN_EXTERNAL_LEADS,
    {
      onCompleted: ({ assignExternalLeads: { assignedLeads } }) => {
        assignedLeads.forEach((lead) => {
          const { executiveAssigned } = lead.masterEntity.company;
          const executiveFullName = `${executiveAssigned.firstName} ${executiveAssigned.lastName}`;
          addAlert({
            id: lead.id,
            message: `Se asignó el lead ${lead.masterEntity.name} a ${executiveFullName}`,
          });
        });
        refetchExternalLeads();
      },
    },
  );

  const assignExecutive = ({ id: externalLeadId, value: executiveId }) => {
    if (executiveId) {
      assignExternalLeads({
        variables: {
          executiveId,
          externalLeadIds: [externalLeadId],
        },
      });
    }
  };

  const columns = [
    ...DATA_GRID_COLUMNS.map((column) => ({ ...column, width: isMobile ? 60 : 90 })),
    {
      field: 'masterEntity_Company_ExecutiveAssigned_Id',
      headerName: 'Ejecutivo Asignado',
      type: 'singleSelect',
      editable: !assignExternalLeadsLoading,
      align: 'center',
      flex: 1,
      valueOptions: () => executives
        ?.filter((executive) => executive.active)
        ?.map((executive) => ({
          label: `${executive.user.firstName} ${executive.user.lastName}`,
          value: executive.user.id,
        }))
      || [],
      valueGetter: ({ row }) => row.masterEntity.company?.executiveAssigned?.id || '',
      valueFormatter: ({ value }) => {
        if (!value) return 'Sin asignación';

        const executive = executives?.find(({ user }) => user.id === value);
        return `${executive?.user?.firstName || 'Sin'} ${executive?.user?.lastName || 'asignación'}`;
      },
    },
  ];
  const selectedExternalLeads = externalLeads?.filter(({ id }) => selectedIds.includes(id));

  return (
    <>
      <PaperHeader viewTitle={HEADER_TITLE} />
      <ExternalLeadsActions
        executives={executives}
        loading={loading}
        externalLeads={selectedExternalLeads}
        setSelectedExternalLeads={setSelectedIds}
        refetchExternalLeads={refetchExternalLeads}
      />
      <FingoDataGrid
        rows={externalLeads}
        columns={columns}
        loadingWithSkeleton={externalLeadsLoading || assignExternalLeadsLoading}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(ids) => { setSelectedIds(ids); }}
        selectionModel={selectedIds}
        density="compact"
        onCellEditCommit={(params) => assignExecutive(params)}
        pagination
        paginationMode="server"
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={rowCount}
      />
    </>
  );
};

ExternalLeads.propTypes = {
  executives: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
};

ExternalLeads.defaultProps = {
  executives: [],
  loading: true,
};

export default ExternalLeads;
