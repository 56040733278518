/* Here we can declare new validation types */
/* eslint-disable import/prefer-default-export */
import { checkRut } from 'react-rut-formatter';
import { validEmail, validPhone } from './forms';

export const validationTypes = (value) => ({
  email: { message: 'Ingrese un email en formato correcto', truthFunction: () => validEmail(value) },
  phone: { message: 'El número debe tener 9 dígitos', truthFunction: () => validPhone(String(value)) },
  text: { message: '', truthFunction: () => true },
  rut: { message: 'Ingrese un rut correcto', truthFunction: () => checkRut(value) },
});
