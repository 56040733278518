import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MinHeightBox } from '@fingo/lib/components/boxes';
import { useTheme } from '@mui/styles';
import LucilaNavbar from './LucilaNavbar';

const Layout = ({ children }) => {
  const theme = useTheme();
  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.dark;
  }, [theme]);

  return (
    <>
      <LucilaNavbar />
      <MinHeightBox>
        {children}
      </MinHeightBox>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
