import React from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { ContactList } from '@fingo/lib/components/lists';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Grid, MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SelectRadioGroup } from '../selects';
import InvoicesAttachableFiles from '../attach/InvoicesAttachableFiles';

const OperativeStepperDialog = ({
  open,
  onClose,
  selectedOption,
  selectedContactsIds,
  selectedDocuments,
  firstStepOptions,
  managersAndAttachs,
  currentStep,
  documentType,
  setOptions,
  setCurrentStep,
  setSelectedContactsIds,
  setManagersAndAttachs,
  targetRut,
  operationType,
  lastStepComponent,
  onSubmit,
  submitButtonText,
  loadingSubmit,
  stepToTitle,
}) => {
  const innerOnClose = () => {
    setCurrentStep(0);
    onClose();
  };
  return (
    <FingoDialog
      open={open}
      onClose={innerOnClose}
      title={stepToTitle[currentStep]}
      fullWidth
      PaperProps={{ sx: { borderRadius: 4, maxWidth: 770 } }}
    >
      {currentStep === 0 && (
        <Grid container direction="row" justifyContent="left">
          <Grid item xs={5}>
            <SelectRadioGroup
              options={firstStepOptions}
              setOption={setOptions}
              selectedOption={selectedOption}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          {documentType === 'invoice' && (
            <Grid item marginLeft={3} xs={5}>
              <InvoicesAttachableFiles
                documents={selectedDocuments}
                managersAndAttachs={managersAndAttachs}
                setManagersAndAttachs={setManagersAndAttachs}
              />
            </Grid>
          )}
        </Grid>
      )}
      {currentStep === 1 && (
        <ContactList
          companyRut={targetRut}
          contactsType={operationType}
          setSelectedContactsIds={setSelectedContactsIds}
        />
      )}
      {currentStep === 2 && (
        lastStepComponent
      )}
      <MobileStepper
        steps={3}
        variant="dots"
        position="static"
        activeStep={currentStep}
        nextButton={currentStep === 2 ? (
          <LoadingButton
            size="small"
            onClick={onSubmit}
            loading={loadingSubmit}
            disabled={false}
            variant="contained"
            color="primary"
          >
            {submitButtonText}
          </LoadingButton>
        ) : (
          <Button
            size="small"
            onClick={() => setCurrentStep((prevActiveStep) => prevActiveStep + 1)}
            disabled={
              (currentStep === 0 && !selectedOption)
              || (currentStep === 1 && selectedOption !== 'OTHER' && selectedContactsIds.length === 0)
            }
          >
            Siguiente
            <KeyboardArrowRight />
          </Button>
        )}
        backButton={(
          <Button
            size="small"
            onClick={() => setCurrentStep((prevActiveStep) => prevActiveStep - 1)}
            disabled={currentStep === 0}
          >
            <KeyboardArrowLeft />
            Atrás
          </Button>
        )}
      />
    </FingoDialog>
  );
};

OperativeStepperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstStepOptions: PropTypes.array,
  setOptions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocuments: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  managersAndAttachs: PropTypes.array.isRequired,
  targetRut: PropTypes.number.isRequired,
  operationType: PropTypes.string.isRequired,
  setSelectedContactsIds: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setManagersAndAttachs: PropTypes.func.isRequired,
  currentStep: PropTypes.bool.isRequired,
  documentType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedContactsIds: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  lastStepComponent: PropTypes.element.isRequired,
  loadingSubmit: PropTypes.bool,
  submitButtonText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  stepToTitle: PropTypes.object,
};

OperativeStepperDialog.defaultProps = {
  firstStepOptions: [],
  loadingSubmit: false,
  stepToTitle: {
    0: 'Paso 1',
    1: 'Paso 2',
    3: 'Paso 3',
  },
  submitButtonText: 'Aceptar',
};

export default OperativeStepperDialog;
