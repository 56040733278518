import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const CompanyCell = ({ name, rut }) => {
  const draggableProps = (dataSearch) => (
    {
      draggable: true,
      onDrag: (e) => { e.preventDefault(); e.newData = dataSearch; },
    }
  );

  return (
    <Stack width="100%" alignItems="flex-start" justifyContent="center" {...draggableProps(name)}>
      <Typography variant="body2" width="inherit" noWrap>{name}</Typography>
      <Typography variant="caption" width="inherit" noWrap>{rut}</Typography>
    </Stack>
  );
};

CompanyCell.propTypes = {
  name: PropTypes.string.isRequired,
  rut: PropTypes.string,
};

CompanyCell.defaultProps = {
  rut: '',
};

export default CompanyCell;
