import React from 'react';
import PropTypes from 'prop-types';
import LoadingIconButton from './LoadingIconButton';
import { DownloadExcelIcon } from '../icons';

const DownloadExcelButton = ({ downloadFunction, loading }) => (
  <LoadingIconButton
    onClick={() => downloadFunction()}
    loading={loading}
    size="small"
    color="excel"
    id="export-excel"
  >
    <DownloadExcelIcon />
  </LoadingIconButton>
);

DownloadExcelButton.propTypes = {
  downloadFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DownloadExcelButton;
