import { useOfferShoppingCart, useOrderingShoppingCart, usePreofferShoppingCart } from './useShoppingCartHooks';

const useShoppingCart = ({ cartType }) => {
  if (cartType === 'available-offers') {
    return useOfferShoppingCart();
  }
  if (cartType === 'ordering') {
    return useOrderingShoppingCart();
  }
  if (cartType === 'available-factoring') {
    return usePreofferShoppingCart();
  }
  return {};
};

export default useShoppingCart;
