import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PropTypes } from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'underscore';
import { UPDATE_COLLECTION_CONTACT, CREATE_COLLECTION_CONTACT, GET_COMPANY_COLLECTION_CONTACTS } from '../../graphql';
import { useBooleanState, useSnackBars } from '../../hooks';
import { FingoDialog } from '../dialogs';
import { useContactColumns } from '../../constants';
import FormDataGrid from '../dataGrids/FormDataGrid';

const SummaryContactCard = ({ companyRut, refetchDebtor }) => {
  const [open, toggleDialog, setTrue] = useBooleanState();
  const [loadingSubmit, setLoadingSubmit] = useState();
  const [completedCount, setCompletedCount] = useState(0);
  const [originalContacts, setOriginalContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const { addAlert } = useSnackBars();

  const { loading, refetch } = useQuery(GET_COMPANY_COLLECTION_CONTACTS, {
    variables: { companyRut },
    skip: !companyRut,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setOriginalContacts(data.getCompanyCollectionContacts);
      setContacts(data.getCompanyCollectionContacts);
    },
  });

  const newContacts = contacts.filter((contact) => {
    const currentContact = originalContacts.find((cont) => cont.id === contact.id);
    if (currentContact === undefined) return true;
    return false;
  });

  const updatedContacts = contacts.filter((contact) => {
    const currentContact = originalContacts.find((cont) => cont.id === contact.id);
    if (currentContact === undefined) return false;
    return !_.isEqual(currentContact, contact);
  });

  const buttonDisabled = () => newContacts.length === 0 && updatedContacts.length === 0;

  const [createCollectionContact] = useMutation(CREATE_COLLECTION_CONTACT, {
    onCompleted: () => {
      setCompletedCount((prevState) => prevState + 1);
    },
  });
  const [updateCollectionContact] = useMutation(UPDATE_COLLECTION_CONTACT, {
    onCompleted: () => {
      setCompletedCount((prevState) => prevState + 1);
    },
  });

  const handleSubmitMutations = () => {
    if (newContacts.length > 0) {
      newContacts.forEach((newContact) => {
        createCollectionContact({
          variables: {
            companyRut,
            firstName: newContact.firstName,
            lastName: newContact.lastName,
            position: newContact.position,
            phoneNumber: newContact.phoneNumber,
            email: newContact.email,
            source: newContact.source,
          },
        });
      });
    }
    if (updatedContacts.length > 0) {
      updatedContacts.forEach((updatedContact) => {
        updateCollectionContact({
          variables: {
            contactId: updatedContact.id,
            firstName: updatedContact.firstName,
            lastName: updatedContact.lastName,
            position: updatedContact.position,
            phoneNumber: updatedContact.phoneNumber,
            email: updatedContact.email,
          },
        });
      });
    }
  };
  const handleSubmit = () => {
    setLoadingSubmit(true);
    handleSubmitMutations();
  };

  useEffect(() => {
    if (
      completedCount !== 0
      && completedCount === newContacts.length + updatedContacts.length
    ) {
      refetchDebtor();
      setLoadingSubmit(false);
      refetch();
      toggleDialog();
      setCompletedCount(0);
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Contactos actualizados',
      });
    }
  }, [completedCount]);

  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: 'white',
          borderRadius: 2,
          p: 2,
          cursor: 'pointer',
          '&:hover': { boxShadow: 1, borderRadius: 2 },
        }}
        height="100%"
        onClick={setTrue}
      >
        {contacts.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="h5">
              Información de contacto
            </Typography>
            <Typography>
              Nombre: {contacts[0].firstName}
            </Typography>
            <Typography>
              Cargo: {contacts[0].position}
            </Typography>
            <Typography>
              Email: {contacts[0].email}
            </Typography>
            <Typography>
              Telefono: {contacts[0].phoneNumber}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} justifyContent="center">
            <Typography>
              Sin contactos
            </Typography>
            <Typography variant="h5">
              + Nuevo contacto
            </Typography>
          </Grid>
        )}
      </Grid>
      <FingoDialog
        open={open}
        handleClose={toggleDialog}
        title="Lista de contactos"
        PaperProps={{ sx: { borderRadius: 4, maxWidth: 800 } }}
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            disabled={buttonDisabled()}
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            Guardar cambios
          </LoadingButton>
        )}
      >
        <FormDataGrid
          rows={contacts}
          setRows={setContacts}
          columns={useContactColumns()}
          loadingWithSkeleton={loading}
          boxProps={{
            sx: { height: 300 },
          }}
          hideFooter
        />
      </FingoDialog>
    </>
  );
};

SummaryContactCard.propTypes = {
  companyRut: PropTypes.number,
  refetchDebtor: PropTypes.func,
};

SummaryContactCard.defaultProps = {
  companyRut: 0,
  refetchDebtor: () => null,
};

export default SummaryContactCard;
