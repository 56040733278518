import React from 'react';
import PropTypes from 'prop-types';
import TabNavLink from './TabNavlink';

const Tabs = ({ menuOptions }) => (
  <>
    {menuOptions.map(({ id, label, path, quantity }, index) => (
      <TabNavLink id={id} key={label} label={label} path={path} quantity={quantity} index={index} />
    ))}
  </>
);

Tabs.propTypes = {
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
    component: PropTypes.element,
    quantity: PropTypes.number,
  })).isRequired,
};

export default Tabs;
