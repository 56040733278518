/* eslint-disable import/prefer-default-export */
import theme from '@fingo/lib/theme/theme';

export const handleBehaviourColor = (debtorPaymentBehaviour) => {
  if (debtorPaymentBehaviour === 'GOOD' || debtorPaymentBehaviour === 'VERY_GOOD') {
    return theme.palette.success.main;
  } if (debtorPaymentBehaviour === 'IN_PROBLEMS' || debtorPaymentBehaviour === 'IN_SEVERE_PROBLEMS') {
    return theme.palette.error.main;
  } if (debtorPaymentBehaviour === 'BAD_LATE_PAYER' || debtorPaymentBehaviour === 'NEW') {
    return theme.palette.warning.main;
  }
  return '';
};
