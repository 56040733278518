import React from 'react';
import PropTypes from 'prop-types';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const BooleanCell = ({ value }) => (
  value
    ? <CheckCircleRoundedIcon color="success" fontSize="small" />
    : <CancelRoundedIcon color="error" fontSize="small" />
);

BooleanCell.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default BooleanCell;
