import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { Close, CloudUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import UploadDropZoneDialogContainer from '../containers/UploadDropZoneDialogContainer';

const UploadDropZoneDialog = (props) => {
  const {
    open,
    setOpen,
    file,
    setFile,
    message,
    mutation,
    mutationLoading,
    downloadTemplate,
    mutationVariables,
  } = props;
  const [error, setError] = useState(null);

  const onClose = () => { setOpen(false); setFile({ name: '' }); setError(''); };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{ background: 'primary' }}
      >
        Carga de Documentos
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ width: '440px', height: '320px', paddingBottom: 0 }}>
        <Typography paddingY="18px" variant="caption" component="div">{message}</Typography>
        <UploadDropZoneDialogContainer
          file={file}
          setFile={setFile}
          errorMutation={error}
          setErrorMutation={setError}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>
        {file.size
          ? (
            <LoadingButton
              variant="contained"
              onClick={() => mutation({ variables: { file, ...mutationVariables } })}
              loading={mutationLoading}
              loadingPosition="start"
              startIcon={<CloudUpload />}
              disabled={mutationLoading}
            >
              Subir carga masiva
            </LoadingButton>
          )
          : (
            <Button onClick={() => { downloadTemplate(); }}>
              Descarga la plantilla en .XLSX
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

UploadDropZoneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mutation: PropTypes.func.isRequired,
  mutationLoading: PropTypes.bool.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mutationVariables: PropTypes.object,
};

UploadDropZoneDialog.defaultProps = {
  mutationVariables: {},
};

export default UploadDropZoneDialog;
