import { gql } from '@apollo/client';
import { OfferFields } from '../fragments';

export const GET_BANK_ACCOUNTS = gql`
  query GetCompanyBankAccounts($ruts: [Int]!) {
    getCompanyBankAccounts(ruts: $ruts) {
      id
      accountEmail
      accountName
      accountNumber
      accountRut
      accountType
      bankName
      active
    }
  }
`;

export const GET_BANK_ACCOUNT_TYPES = gql`
  query GetBankAccountTypes {
    getBankAccountTypes
  }
`;

export const GET_COMPANY_DATA = gql`
  query GetCompanyData(
    $companyId: Int!,
  ) {
    selectedCompanyRut @client @export(as: "companyId")
    getOffers(ruts: [$companyId]) {
      ... OfferFields
    }
    getCompanyCommissionRules(rut: $companyId) {
      id
      variant
      value
      threshold
    }
    getCompanyValidationVariables(rut: $companyId) {
      creditLine {
        id
        totalAmount
        creditUsed
        approvedOverdraft
      }
      debtorsExposition {
        company {
          rut
          name
        }
        exposedAmount
      }
    }
    getCompanySurplusBalance(rut: $companyId)
  }
  ${OfferFields}
`;

export const CHECK_DIGITAL_CERTIFICATE = gql`
  query CheckDigitalCertificate($companyRut: Int!) {
    selectedCompanyRut @client @export(as: "companyRut")
    checkDigitalCertificateUploaded(companyRut: $companyRut)
    checkDigitalCertificateInAcepta(companyRut: $companyRut)
  }
`;

export const GET_FRAMEWORK_CONTRACT = gql`
  query frameworkContract($rut: Int!) {
    getCompanyFrameworkContract(rut: $rut)
  }
`;
export const PORTFOLIO_OPTIONS = gql`
  query portfolioOptions {
    getPortfolioTypeEnum {
      value
      label
    }
    getPortfolioFormatEnum {
      value
      label
    }
  }
`;

export const GET_INVOICE_PROVIDER = gql`
  query geInvoiceProvider($credentialProviderType_Name: String) {
    getInvoiceProvider(credentialProviderType_Name: $credentialProviderType_Name) {
      id
      name
      integrated
      iconUrl
      credentialProviderType {
        name
        description
      }
    }
  }
`;

export const SOPHIA_TRACKER = gql`
  query SophiaTracker ($companyRut: Int!) {
    selectedCompanyRut @client @export(as: "companyRut")
    sophiaTracker(companyRut: $companyRut) {
      companyRut
      sales
      salesPast
      salesCount
      salesCountPast
      shop
      shopPast
      shopCount
      shopCountPast
      cessions
      cessionsPast
      cessionsCount
      cessionsCountPast
      cessionsShop
      cessionsShopPast
      cessionsShopCount
      cessionsShopCountPast
      shopExpires
      salesExpires
    }
  }
`;

export const SOPHIA_GRAPHS = gql`
  query SophiaGraphs($companyRut: Int!, $accumulated: Boolean!, $group: String!) {
    selectedCompanyRut @client @export(as: "companyRut")
    sophiaPurchasesGraph(companyRut: $companyRut, accumulated: $accumulated, group: $group){
      money
      year
      month
      dateIssued
    }
    sophiaSalesGraph(companyRut: $companyRut, accumulated: $accumulated, group: $group){
      money
      year
      month
      dateIssued
    }
  }
`;

export const GET_CONTACTS = gql`
query contacts($companySuppliers: Int!, $first: Int, $offset: Int, $globalFilter: String) {
  getMasterEntities(companySuppliers: $companySuppliers, first: $first, offset: $offset, globalFilter: $globalFilter) {
    totalPages
    totalCount
    pageInfo {
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        name
        rut
        company {
          rut
          contacts {
            id
            name
            position
            email
            phoneNumber
            userInstance {
              id
            }
          }
        }
      }
    }
  }
}`;

export const PROVIDER_EXCEL_TEMPLATE = gql`
  query exportTemplateContacts{
    exportTemplateContacts
  } 
`;

export const GET_TERMS = gql`
  query GetTermsAndConditions {
    getTermsAndConditions {
      id
      text
      sectionType
    }
  }
`;

export const INVOICE_DEBTOR_SUMMARY = gql`
  query invoiceDebtorSummary (
    $rut: Int!
  ) {
    receiverSummary (
      rut: $rut
    ) {
      debtCount
      debtAmount
      currentValid
      currentAmount
    }
  }
`;

export const ORDERING_DEBTOR_SUMMARY = gql`
  query orderingDebtorSummary (
    $rut: Int!
  ) {
    operationsSummary (
      rut: $rut
    ) {
      debtCount
      debtAmount
      currentValid
      currentAmount
    }
  }
`;

export const GET_MASTER_ENTITY = gql`
  query masterEntity (
    $rut: Int!
  ) {
    getMasterEntity (
      rut: $rut
    ) {
      id
      rut
      name
      debtor {
        debtorType
      }
      collectionContacts {
        id
        firstName
        lastName
        phoneNumber
        email
        position
        source
      }
    }
  }
`;

export const GET_MASTER_ENTITIES = gql`
  query masterEntities (
    $first: Int,
    $offset: Int,
    $globalFilter: String,
    $inCollection: Boolean = false,
    $inRatification: Boolean = false,
    $inEvaluation: Boolean = false,
  ) {
    getMasterEntities (
      first: $first,
      offset: $offset,
      globalFilter: $globalFilter,
      inCollection: $inCollection,
      inRatification: $inRatification,
      inEvaluation: $inEvaluation,
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          rut
          name
          debtor {
            collector {
              firstName
              lastName
              pictureUrl
            }
          }
          invoicesInCollection @include(if: $inCollection) {
            folio
            amountWithIva
            status
            dateToPay
            company {
              name
              rut
            }
            surplusDebt
            collectionManager {
              priority
              actions {
                id
                createdAt
                comment
                actionType
              }
            }
          }
          company {
            rut
            orderingInCollection @include(if: $inCollection) {
              orderNumber
              totalAmount
              status
              orderingoffer {
                invoiceIssuedDate
                orderingFinancedAmount
              }
              orderingDebt {
                days
                debt
              }
              collectionManager {
                priority
                actions {
                  id
                  createdAt
                  comment
                  actionType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DEBTOR_PAYMENT_BEHAVIOUR_ENUM = gql`
  query getDebtorPaymentBehaviourEnum {
    getDebtorPaymentBehaviourEnum{
      value
      label
    }
  }
`;

export const GET_COMPANY_COLLECTION_CONTACTS = gql`
  query GetCompanyCollectionContacts($companyRut: Int!) {
    getCompanyCollectionContacts(companyRut: $companyRut) {
      id
      firstName
      phoneNumber
      email
      source
      lastName
      position
    }
  }
`;

export const GET_COMPANY_RATIFICATION_CONTACTS = gql`
  query GetCompanyRatificationContacts($companyRut: Int!) {
    getCompanyRatificationContacts(companyRut: $companyRut) {
      id
      firstName
      lastName
      position
      source
      email
      phoneNumber
      verified
      ratifies
      ratificationContactStats {
        mails
        mailsResponded
        mailsResponseRate
        phoneCalls
        phoneCallsResponded
        phoneCallsResponseRate
      }
    }
  }
`;

export const COMPANY_PROFILE_PICTURE = gql`
  query companyProfilePicture($companyId: Int!) {
    company(companyId: $companyId) {
      rut
      companyfiles{
        profilePictureUrl
      }
    }
  }`;
