import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER } from '@fingo/lib/graphql';
import { Home, Collection, Commercial, Risk } from '../views';
import collectionRoutes from '../features/collection/routes';
import commercialRoutes from '../features/commercial/routes';
import riskRoutes from '../features/risk/routes';

const routeSetter = (user) => [
  {
    label: 'Home',
    path: '/app/home',
    shouldShow: () => user?.isStaff,
    children: <Home />,
    subRoutes: [],
  },
  {
    label: 'Comercial',
    path: '/app/comercial',
    shouldShow: () => user?.isStaff,
    children: <Commercial />,
    subRoutes: commercialRoutes,
  },
  {
    label: 'Cobranza',
    path: '/app/collection',
    shouldShow: () => user?.isStaff,
    children: <Collection />,
    subRoutes: collectionRoutes,
  },
  {
    label: 'Riesgo',
    path: '/app/risk',
    shouldShow: () => user?.isStaff,
    children: <Risk />,
    subRoutes: riskRoutes,
  },
];

const useLucilaRoutes = () => {
  /* In order to change fewer code we define a base routing case */
  const [routes, setRoutes] = useState([{ path: '/app/home', shouldShow: () => true, subRoutes: [] }]);
  useQuery(GET_USER, { onCompleted: ({ getUser }) => setRoutes(routeSetter(getUser)) });
  return routes.filter((route) => route.shouldShow());
};

export default useLucilaRoutes;
