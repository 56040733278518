import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { Debtors, Historic, Summary } from '../components';

const collectionRoutes = [
  { label: 'Inicio', path: 'summary', icon: <DescriptionOutlinedIcon />, component: <Summary /> },
  { label: 'Deudores', path: 'debtors', icon: <DescriptionOutlinedIcon />, component: <Debtors /> },
  { label: 'Gestión histórica', path: 'historic', icon: <PaidOutlinedIcon />, component: <Historic /> },
];

export default collectionRoutes;
