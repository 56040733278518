import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import moment from 'moment';
import {
  refreshToken,
  selectedCompanyRut,
  showReferralCampaignSelectedCompany,
  updateVar,
  topMessage,
  preofferSelected,
  offerSelected,
  offerDateToPaySelected,
  orderingSelected,
  orderingInvoiceIssuedSelected,
  orderingOfferSelected,
  shoppingCartShow,
} from '../reactive-variables';
import managerPaginationCache from './managerPaginationCache';

const MAX_FINANCING_DAYS_ALLOWED = 180;

export default () => new InMemoryCache({
  typePolicies: {
    InvoiceTypeConnection: {
      fields: {
        flatInvoices: {
          read(_, { readField }) {
            return readField('edges').map((edge) => edge.node);
          },
        },
      },
    },
    CessionType: {
      fields: {
        cessionDate: {
          read(existing) {
            return moment(existing);
          },
        },
      },
    },
    UserType: {
      fields: {
        completeName: {
          read(_, { readField }) {
            const firstName = readField('firstName');
            const lastName = readField('lastName');
            return `${firstName} ${lastName}`;
          },
        },
        isAustralisStaff: {
          read(_, { readField }) {
            const userId = Number(readField('id'));
            const australisUserIds = [58594, 58595, 58596, 58597, 58598, 58599];
            return australisUserIds.includes(userId);
          },
        },
        selectedCompany: {
          read(company, { readField }) {
            if (company) {
              updateVar('selectedCompanyRut', readField('rut', company));
            }
            return company;
          },
        },
      },
    },
    MasterEntityType: {
      keyFields: ['rut'],
    },
    CompanyType: {
      keyFields: ['rut'],
      fields: {
        name: {
          read(serverName, { readField }) {
            if (serverName === '') return readField('rut');
            return serverName;
          },
        },
        bankAccounts: {
          read(bankAccounts, { readField }) {
            return [...bankAccounts].sort((a, b) => {
              const activeOne = readField('active', a);
              const activeTwo = readField('active', b);
              if (activeOne === activeTwo) {
                return readField('bankName', a) > readField('bankName', b);
              }
              if (activeOne) return -1;
              return 1;
            });
          },
        },
        negativeSurplusBalance: {
          read(_, { readField }) {
            const surplusBalanceValue = readField('currentSurplusBalanceValue');
            if (surplusBalanceValue < 0) {
              return -surplusBalanceValue;
            }
            return 0;
          },
        },
        navBarTitles: {
          read(_, { readField }) {
            const groups = readField('groupModules');
            const permissions = new Set();
            groups.forEach((group) => {
              const groupPermissions = readField('permissions', group);
              groupPermissions.forEach((perm) => permissions.add(readField('code', perm)));
            });
            return permissions;
          },
        },
      },
    },
    Query: {
      fields: {
        invoiceMessages: relayStylePagination(['invoiceId', 'messageType']),
        getPreoffers: managerPaginationCache(),
        purchaseOrders: managerPaginationCache(),
        invoices: managerPaginationCache(),
        selectedCompanyRut: {
          read() {
            return selectedCompanyRut();
          },
        },
        isLoggedIn: {
          read() {
            return !!refreshToken();
          },
        },
        selectedPreofferIds: {
          read() {
            return preofferSelected();
          },
        },
        selectedOfferIds: {
          read() {
            return offerSelected();
          },
        },
        selectedOfferDateToPay: {
          read() {
            return offerDateToPaySelected();
          },
        },
        selectedOrderingIds: {
          read() {
            return orderingSelected();
          },
        },
        selectedOfferOrderingIds: {
          read() {
            return orderingOfferSelected();
          },
        },
        orderingInvoiceIssuedSelected: {
          read() {
            return orderingInvoiceIssuedSelected();
          },
        },
        topMessage: {
          read() {
            return topMessage();
          },
        },
        showReferralCampaignSelectedCompany: {
          read() {
            return showReferralCampaignSelectedCompany();
          },
        },
        showShoppingCart: {
          read() {
            return shoppingCartShow();
          },
        },
      },
    },
    CompanyCreditLineType: {
      fields: {
        availableLine: {
          read(_, { readField }) {
            const totalAmount = readField('totalAmount');
            const creditUsed = readField('creditUsed');
            return totalAmount - creditUsed;
          },
        },
      },
    },
    OrderingPurchaseOrderType: {
      fields: {
        publicationDate: {
          read(publishDateString) {
            return moment(publishDateString);
          },
        },
        simulationSelectable: {
          read(_, { readField }) {
            const status = readField('status');
            if (status === 'EVALUATION') {
              return { selectable: false, status: 'Evaluación' };
            }
            if (status === 'SIMULATION') {
              return { selectable: true, status: 'Disponible' };
            }
            return { selectable: false, status: '-' };
          },
        },
        nonAvailableReason: {
          read(_, { readField }) {
            const status = readField('status');
            const publicationDate = readField('publicationDate');
            const amount = readField('totalAmount');
            const currency = readField('currency');
            const diffDate = moment().startOf('day').diff(publicationDate, 'days') > 120;
            if (diffDate) {
              return 'Su orden de compra posee más de 120 días desde su fecha de emisión';
            }
            if (currency !== 'CLP') {
              return 'Su orden de compra no se encuentra en pesos chilenos';
            }
            if (amount < 60000) {
              return 'Su orden de compra posee un monto menor a $60.000';
            }
            if (amount > 1000000000) {
              return 'Su orden de compra posee un monto mayor a $100.000.000';
            }
            if (status === 'SIMULATION') {
              return '';
            }
            if (status === 'EVALUATION') {
              return 'Su orden de compra elegida ya fue enviada a evaluar, y nuestro equipo está trabajando para aceptar su factura';
            }
            if (status === 'OFFERED') {
              if (diffDate) {
                return 'Su orden de compra se le hizo oferta, pero ya pasaron más de 120 días desde su fecha de publicación';
              }
              return 'Su orden de compra posee oferta. Acéptela en la pantalla de ofertas de órdenes de compra';
            }
            if (status === 'REJECTED') {
              return 'Su solicitud de ordering fue rechazada por nuestro equipo';
            }
            const operatedStatus = [
              'PENDINGASSIGNMENTDOCUMENT',
              'PENDINGSIGNATURE',
              'PENDINGTRANSFER',
              'PENDINGCOLLECTION',
              'PENDINGINVOICES',
              'FACTORING',
              'FINISHED',
            ];
            if (operatedStatus.includes(status)) {
              return 'Su ordering ya fue operado. Revise su pestaña de operaciones activas para ver el estado de tu ordering';
            }
            return '';
          },
        },
      },
    },
    OrderingOfferType: {
      fields: {
        invoiceIssuedDate: {
          read(invoiceIssuedString) {
            return moment(invoiceIssuedString);
          },
        },
      },
    },
    DocumentFinanceStateType: {
      fields: {
        paymentDate: {
          read(paymentDate) {
            return paymentDate && moment(paymentDate);
          },
        },
      },
    },
    InvoiceType: {
      fields: {
        creditNotes: {
          read(_, { readField }) {
            const references = readField('references');
            if (!references) return [];
            return references.filter((ref) => readField('code', readField('dteType', ref)) === '61');
          },
        },
        debitNotes: {
          read(_, { readField }) {
            const references = readField('references');
            if (!references) return [];
            return references.filter((ref) => readField('code', readField('dteType', ref)) === '56');
          },
        },
        providerTrace: {
          read(_, { readField }) {
            const uniqueDocumentFinanceState = readField('uniqueDocumentFinanceState');
            if (!uniqueDocumentFinanceState) return [];
            return uniqueDocumentFinanceState.filter((ref) => readField('code', readField('status', ref)) !== '1') || [];
          },
        },
        providerRejectionReasons: {
          read(_, { readField }) {
            const uniqueDocumentFinanceState = readField('uniqueDocumentFinanceState');
            if (!uniqueDocumentFinanceState) return {};
            return uniqueDocumentFinanceState.filter((ref) => readField('code', readField('status', ref)) === '1') || [];
          },
        },
        companyRut: {
          read(_, { readField }) {
            const company = readField('company');
            const rut = readField('rut', company);
            return rut;
          },
        },
        dateIssued: {
          read(dateString) {
            return moment(dateString);
          },
        },
        dateExpiration: {
          read(dateString) {
            return moment(dateString);
          },
        },
        dateToPay: {
          read(dateString) {
            return moment(dateString);
          },
        },
        dateOperation: {
          read(dateString) {
            return moment(dateString);
          },
        },
        paymentMethod: {
          read(_, { readField }) {
            const invoicedetail = readField('invoicedetail');
            if (!invoicedetail) {
              const siiStatus = readField('siiStatus');
              if (siiStatus === 'ALREADY_PAYED') {
                return 'Contado';
              }
              return 'Crédito';
            }
            // In case there is no FmaPago in XML, default value for payment method is credit
            // https://www.sii.cl/factura_electronica/formato_dte.pdf
            const paymentMethod = readField('paymentMethod', invoicedetail) || 2;
            return { 1: 'Contado', 2: 'Crédito', 3: 'Sin Costo' }[paymentMethod];
          },
        },
        receptionDate: {
          read(dateString, { readField }) {
            if (dateString) {
              return moment(dateString);
            }
            return readField('dateIssued');
          },
        },
        preofferSelectable: {
          read(_, { readField }) {
            const siiStatus = readField('siiStatus');
            const loanedStatus = readField('loanedStatus');
            const loanedToRut = readField('loanedToRut');
            const creditNoteStatus = readField('creditNoteStatus');
            const dateIssued = readField('dateIssued');
            const preoffer = readField('preoffer');
            const offer = readField('offer');
            const operation = readField('operation');
            if (operation) {
              return { selectable: false, status: 'HAS_OPERATION' };
            }
            if (['LOANED'].includes(loanedStatus) && selectedCompanyRut() !== loanedToRut) {
              return { selectable: false, status: loanedStatus };
            }
            if (offer) {
              return { selectable: false, status: 'HAS_OFFER' };
            }
            if (preoffer && readField('preofferevaluationrequest', preoffer)) {
              const evaluationRequest = readField('preofferevaluationrequest', preoffer);
              const evaluationStatus = readField('status', evaluationRequest);
              return { selectable: false, status: evaluationStatus };
            }
            if (['HAS_CREDIT_NOTE'].includes(creditNoteStatus)) {
              return { selectable: false, status: creditNoteStatus };
            }
            if ([
              'REJECTED_BY_SII',
              'REJECTED_RECEIVER',
              'TOTAL_CLAIM',
              'ALREADY_PAYED',
              'REJECTED_FOR_CONTENT',
              'REJECTED_FOR_TOTAL',
              'REJECTED_FOR_PARTIAL'].includes(siiStatus)) {
              return { selectable: false, status: siiStatus };
            }
            if (dateIssued < moment().subtract(2, 'months')) {
              return { selectable: false, status: 'EXPIRED' };
            }
            if (!preoffer) {
              return { selectable: false, status: siiStatus };
            }
            return { selectable: true, status: siiStatus };
          },
        },
        amounts: {
          read(_, { readField }) {
            const offerRef = readField('offer');
            const retentionRate = readField('retentionRate', offerRef) || 0;
            const amountWithIva = readField('amountWithIva');
            const monthlyRate = readField('monthlyRate', offerRef);
            const dateExpiration = readField('dateExpiration');
            const startOfDay = moment().startOf('day');
            const daysUntilExpiration = dateExpiration.diff(startOfDay, 'days');
            const dateToPay = daysUntilExpiration > MAX_FINANCING_DAYS_ALLOWED ? startOfDay.clone().add(180, 'days') : dateExpiration.clone();
            const days = dateToPay.diff(moment().startOf('day'), 'days');
            const effectiveRate = (monthlyRate / 100) * (days / 30);
            const retentionAmount = parseInt((retentionRate / 100) * amountWithIva, 10);
            const advanceAmount = amountWithIva - retentionAmount;
            const amountMinusInterests = parseInt(advanceAmount * (1 - effectiveRate), 10);
            const priceDifference = advanceAmount - amountMinusInterests;
            return {
              dateToPay: null,
              effectiveRate,
              retentionAmount,
              advanceAmount,
              amountMinusInterests,
              priceDifference,
            };
          },
        },
        lightningPaymentAuthorized: {
          read(_, { readField }) {
            const dateIssued = readField('dateIssued');
            const states = readField('uniqueDocumentFinanceState');
            if (moment().diff(dateIssued, 'days') > 15) return false;
            if (!states.length) return false;
            const payedState = states.find((state) => {
              const status = readField('status', state);
              const code = readField('code', status);
              return code === '5';
            });
            return !!payedState;
          },
        },
      },
    },
    ContactType: {
      fields: {
        id: {
          read(id) {
            return parseInt(id, 10);
          },
        },
      },
    },
  },
});
