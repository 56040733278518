import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDebounce } from '../../hooks';
import { FingoTextField } from '../fields';

const HeaderSearcherInput = ({ finder }) => {
  const debounce = useDebounce(finder.onFinderChange, 500);
  const [_finderValue, setFinderValue] = useState(finder && finder.finderValue);
  const handleChange = (event) => {
    setFinderValue(event.target.value);
    debounce(event.target.value);
  };
  const handleDrop = (event) => {
    finder.onFinderChange(event.newData);
    setFinderValue(event.newData);
  };
  return (
    <Grid
      item
      sx={(theme) => ({
        width: '40%',
        maxWidth: '350px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          maxWidth: '100%',
          mt: 2,
        },
      })}
    >
      <FingoTextField
        value={_finderValue}
        onChange={handleChange}
        onDrop={handleDrop}
        onDragOver={(e) => { e.preventDefault(); }}
        placeholder={finder.searchPlaceHolder}
        canPasteExcel={finder.canPasteExcel}
        setColumnFilter={finder.setColumnFilter}
        setFinderValue={setFinderValue}
      />
    </Grid>
  );
};

HeaderSearcherInput.propTypes = {
  finder: PropTypes.shape({
    onFinderChange: PropTypes.func.isRequired,
    searchPlaceHolder: PropTypes.string.isRequired,
    finderValue: PropTypes.string.isRequired,
    canPasteExcel: PropTypes.bool,
    setColumnFilter: PropTypes.func,
  }).isRequired,
};

export default HeaderSearcherInput;
