import React from 'react';
import { CompanyCell } from '@fingo/lib/components/cells';
import { formatMoney } from '@fingo/lib/helpers';

export const PURCHASE_ORDER_DATA_GRID_COLUMNS = [
  {
    field: 'orderNumber',
    headerName: 'Número de OC',
  },
  {
    field: 'company_MasterEntity_Rut',
    headerName: 'Empresa',
    renderCell: ({ row }) => (
      <CompanyCell
        name={row.company.masterEntity.name}
        rut={row.company.rut}
      />
    ),
    flex: 1,
  },
  {
    field: 'purchaser_Rut',
    headerName: 'Comprador',
    renderCell: ({ row }) => <CompanyCell name={row.purchaser.name} rut={row.purchaser.rut} />,
    flex: 1,
  },
  {
    field: 'totalAmount',
    headerName: 'Monto OC',
    valueGetter: ({ row }) => `$${formatMoney(row.totalAmount)}`,
  },
  {
    field: 'publicationDate',
    type: 'date',
    headerName: 'Fecha de publicación',
    valueGetter: ({ row }) => new Date(row.publicationDate),
  },
  {
    field: 'orderingoffer_OrderingFinancedAmount',
    headerName: 'Monto financiado',
    valueGetter: ({ row }) => `$${formatMoney(row.orderingoffer?.orderingFinancedAmount)}`,
  },
  {
    field: 'orderingoffer_OperationDate',
    type: 'date',
    headerName: 'Fecha última solicitud',
    valueGetter: ({ row }) => (row.orderingoffer ? new Date(row.orderingoffer.operationDate) : 'n/a'),
  },
];

export const INVOICE_DATA_GRID_COLUMNS = [
  'folio',
  'dateIssued',
  'receiver_Name',
  'receiver_Rut',
  'amountWithIva',
  'monthlyRate',
  'defaultRate',
  'dateToPay',
];

export const HEADER_TITLE = 'Órdenes de compra pendientes de factura';
