import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  DEBTOR_PAYMENT_BEHAVIOUR_ENUM,
  SET_DEBTOR_PAYMENT_BEHAVIOUR,
} from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import { handleBehaviourColor } from '../../../../helpers/behavior-to-color';

const SelectDebtorBehaviourCard = ({ debtor, refetchDebtor }) => {
  const [debtorBehave, setDebtorBehave] = useState(debtor.debtor.debtorType);
  const { addAlert } = useSnackBars();
  const [
    setDebtorPaymentBehaviour,
    { loading: loadingUpdateBehaviour },
  ] = useMutation(SET_DEBTOR_PAYMENT_BEHAVIOUR, {
    onCompleted: () => {
      refetchDebtor();
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Deudor actualizado con éxito',
      });
    },
  });

  const {
    data: debtorPaymentBehaviourEnum,
    loading: loadingDebtorPaymentBehaviourEnum,
  } = useQuery(DEBTOR_PAYMENT_BEHAVIOUR_ENUM);

  const handleBehaviourChange = async (newBehaviour) => {
    setDebtorBehave(newBehaviour);
  };

  const handleUpdateBehaviour = () => {
    setDebtorPaymentBehaviour({
      variables: {
        newBehaviour: debtorBehave,
        masterEntityId: debtor.rut,
      },
    });
  };
  return (
    <Grid
      container
      sx={{ bgcolor: 'white', borderRadius: 2, p: 2, pt: 3 }}
      height="100%"
      width="100%"
      direction="column"
      wrap="nowrap"
    >
      <Typography>Tipo de deudor</Typography>
      <Select
        id="debtorPaymentBehaviour"
        value={debtorBehave}
        onChange={(event) => handleBehaviourChange(event.target.value)}
        variant="outlined"
        sx={{ color: handleBehaviourColor(debtorBehave), width: '100%' }}
      >
        <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un tipo</MenuItem>
        {!loadingDebtorPaymentBehaviourEnum && (
          debtorPaymentBehaviourEnum.getDebtorPaymentBehaviourEnum.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )))}
      </Select>
      <LoadingButton
        color="primary"
        variant="contained"
        sx={{ my: 1, height: '90%' }}
        loading={loadingUpdateBehaviour}
        onClick={handleUpdateBehaviour}
        disabled={debtorBehave === debtor.debtor?.debtorType}
      >
        <Typography
          variant="body1"
        >
          Guardar cambio
        </Typography>
      </LoadingButton>
    </Grid>
  );
};

SelectDebtorBehaviourCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  debtor: PropTypes.object.isRequired,
  refetchDebtor: PropTypes.func.isRequired,
};

export default SelectDebtorBehaviourCard;
