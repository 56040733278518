import React, { useState } from 'react';
import { Menu, Grid, useTheme, Button } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FingoDateRangePicker } from '../datePickers';

const DatesFilter = ({ anchorEl, close, dates, setDates }) => {
  const theme = useTheme();
  const [datesState, setDatesState] = useState([{
    startDate: dates.dateIssued_Gte.toDate(),
    endDate: dates.dateIssued_Lte.toDate(),
    key: 'range',
    color: theme.palette.primary.main,
  }]);
  const save = () => {
    setDates({
      dateIssued_Gte: moment(datesState[0].startDate),
      dateIssued_Lte: moment(datesState[0].endDate),
    });
    close();
  };
  const dateChange = ({ range }) => {
    setDatesState([{ ...range }]);
  };
  return (
    <Menu
      id="date-filter"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={close}
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <FingoDateRangePicker
            onChange={dateChange}
            ranges={datesState}
            direction="horizontal"
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" margin="4px">
          <Button
            id="save-date-filter"
            variant="contained"
            color="primary"
            onClick={save}
            size="small"
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Menu>
  );
};

DatesFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  close: PropTypes.func.isRequired,
  dates: PropTypes.shape({
    dateIssued_Gte: PropTypes.instanceOf(moment),
    dateIssued_Lte: PropTypes.instanceOf(moment),
  }).isRequired,
  setDates: PropTypes.func.isRequired,
};
DatesFilter.defaultProps = {
  anchorEl: null,
};

export default DatesFilter;
