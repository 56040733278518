import { Stack } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const DataGridNoRowsOverlay = ({ message }) => (
  <Stack mt={4} width="100%" alignItems="center">{message}</Stack>
);

DataGridNoRowsOverlay.propTypes = {
  message: PropTypes.element.isRequired,
};

export default DataGridNoRowsOverlay;
