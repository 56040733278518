import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, IconButton } from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Stack } from '@mui/system';
import { HeaderSearcherInput } from '../inputs';

const PaperHeader = ({ type, viewTitle, finder, endComponent, openJoyride }) => (
  <Grid
    container
    justifyContent="space-between"
    columnSpacing={2}
    alignItems="flex-end"
    sx={{ mb: 2 }}
  >
    <Grid item>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h1" color="primary.main">
          {viewTitle}
        </Typography>
        {openJoyride && (
          <IconButton onClick={openJoyride} id={`${type}-help`}>
            <HelpRoundedIcon />
          </IconButton>
        )}
      </Stack>
    </Grid>
    {finder && <HeaderSearcherInput finder={finder} />}
    {endComponent && <Grid item>{endComponent}</Grid>}
  </Grid>
);

PaperHeader.propTypes = {
  type: PropTypes.string,
  viewTitle: PropTypes.string.isRequired,
  finder: PropTypes.shape({
    onFinderChange: PropTypes.func.isRequired,
    searchPlaceHolder: PropTypes.string.isRequired,
    finderValue: PropTypes.string.isRequired,
    canPasteExcel: PropTypes.bool,
    setColumnFilter: PropTypes.func,
  }),
  endComponent: PropTypes.element,
  openJoyride: PropTypes.func,
};
PaperHeader.defaultProps = {
  type: 'undefined',
  finder: null,
  endComponent: null,
  openJoyride: null,
};

export default PaperHeader;
