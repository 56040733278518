/* eslint-disable no-restricted-syntax */

import moment from 'moment';

/* eslint-disable guard-for-in */
const getCollectionActionsWithInvoicesAux = (invoicesInCollection) => {
  const everyCollectionAction = [];
  for (const inv of invoicesInCollection) {
    for (const action of inv.collectionManager.actions) {
      if (!everyCollectionAction.some((elem) => elem.id === action.id)) {
        everyCollectionAction.push({ ...action,
          invoices: [{
            folio: inv.folio,
            companyName: inv.company.name,
            companyRut: inv.company.rut,
          }],
        });
      } else {
        const actionToUpdate = everyCollectionAction.find((act) => act.id === action.id);
        actionToUpdate.invoices.push({
          folio: inv.folio,
          companyName: inv.company.name,
          companyRut: inv.company.rut,
        });
      }
    }
  }
  return everyCollectionAction;
};

const getCollectionActionsWithOrderingAux = (orderingInCollection, preRow) => {
  const everyCollectionAction = [];
  for (const oc of orderingInCollection) {
    for (const action of oc.collectionManager.actions) {
      if (!everyCollectionAction.some((elem) => elem.id === action.id)) {
        everyCollectionAction.push({ ...action,
          purchaseorders: [{
            orderNumber: oc.orderNumber,
            companyName: preRow.name,
            companyRut: preRow.rut,
          }],
        });
      } else {
        const actionToUpdate = everyCollectionAction.find((act) => act.id === action.id);
        actionToUpdate.purchaseorders.push({
          orderNumber: oc.orderNumber,
          companyName: preRow.name,
          companyRut: preRow.rut,
        });
      }
    }
  }
  return everyCollectionAction;
};

const createMasterEntityInvoiceRow = (preRow, invoicesInCollection) => {
  const totalAmount = invoicesInCollection.reduce((prev, currentDoc) => (
    prev + currentDoc.amountWithIva
  ), 0);
  const olderExpiration = moment.max(...invoicesInCollection.map((inv) => inv.dateToPay));
  const priority = Math.max(...invoicesInCollection.map((inv) => inv.collectionManager.priority));
  const defaultAmount = invoicesInCollection.reduce((prev, currentDoc) => (
    currentDoc.status === 'DEBT' ? prev + currentDoc.amountWithIva : prev
  ), 0);
  const everyCollectionAction = getCollectionActionsWithInvoicesAux(invoicesInCollection);
  const row = {
    ...preRow,
    documentType: 'invoice',
    documents: invoicesInCollection,
    documentsCount: invoicesInCollection.length,
    defaultRate: 100 * (defaultAmount / totalAmount),
    lastAction: everyCollectionAction.length > 0 ? (
      everyCollectionAction.reduce(
        (prevAct, currAct) => (prevAct.createdAt > currAct.createdAt ? prevAct : currAct),
      )
    ) : (null),
    priority,
    everyCollectionAction,
    totalAmount,
    olderExpiration,
    defaultAmount,
  };
  return row;
};

const createMasterEntityOrderingRow = (preRow, orderingInCollection) => {
  const totalAmount = orderingInCollection.reduce((prev, currentDoc) => (
    prev + currentDoc.orderingoffer.orderingFinancedAmount
  ), 0);
  const olderExpiration = moment.max(...orderingInCollection.map(
    (oc) => oc.orderingoffer.invoiceIssuedDate,
  ));
  const priority = Math.max(...orderingInCollection.map((oc) => oc.collectionManager.priority));
  const defaultAmount = orderingInCollection.reduce((prev, currentDoc) => (
    currentDoc.orderingDebt?.days > 0
      ? prev + currentDoc.orderingoffer.orderingFinancedAmount : prev
  ), 0);
  const everyCollectionAction = getCollectionActionsWithOrderingAux(orderingInCollection, preRow);
  const row = {
    ...preRow,
    documentType: 'ordering',
    documents: orderingInCollection,
    documentsCount: orderingInCollection.length,
    defaultRate: 100 * (defaultAmount / totalAmount),
    lastAction: everyCollectionAction.length > 0 ? (
      everyCollectionAction.reduce(
        (prevAct, currAct) => (prevAct.createdAt > currAct.createdAt ? prevAct : currAct),
      )
    ) : (null),
    priority,
    everyCollectionAction,
    totalAmount,
    olderExpiration,
    defaultAmount,
  };
  return row;
};

const collectionDebtorSetRow = (data) => {
  const newRows = [];
  for (const edge of data.getMasterEntities.edges) {
    let invoiceRow = null;
    let orderingRow = null;
    const preRow = {
      id: edge.node.id,
      name: edge.node.name,
      rut: edge.node.rut,
      collector: edge.node.debtor.collector,
    };
    if (edge.node.invoicesInCollection.length > 0) {
      invoiceRow = createMasterEntityInvoiceRow(preRow, edge.node.invoicesInCollection);
      newRows.push(invoiceRow);
    }
    if (edge.node.company?.orderingInCollection.length > 0) {
      orderingRow = createMasterEntityOrderingRow(preRow, edge.node.company.orderingInCollection);
      newRows.push(orderingRow);
    }
  }
  return newRows;
};

export default collectionDebtorSetRow;
