import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ProgressiveImage from 'react-progressive-graceful-image';
import FingoDialog from './FingoDialog';

const LoadingDialog = ({ open, title, handleClose, img, description }) => (
  <FingoDialog
    open={open}
    handleClose={handleClose}
    title={title}
  >
    <Grid
      sx={{
        width: { xs: 300, sm: 400 },
        mr: 'auto',
        ml: 'auto',
      }}
      elevation={4}
    >
      <Stack alignItems="center" spacing={2} width="100%">
        <ProgressiveImage
          src={img}
          placeholder=""
        >
          {(src, loading) => {
            if (loading) {
              return <CircularProgress color="gray" />;
            }
            return (
              <Box
                component="img"
                src={src}
                alt="alt"
                sx={{
                  width: { xs: '300px', md: '400px' },
                }}
              />
            );
          }}
        </ProgressiveImage>
        <Typography variant="h5" align="center">{description}</Typography>
      </Stack>
    </Grid>
  </FingoDialog>
);

LoadingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LoadingDialog;
