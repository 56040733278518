import { gql } from '@apollo/client';

export const GET_COMPANY_SURPLUS_AMOUNTS = gql`
  query GetCompanySurplusAmounts($rut: Int!) {
    getCompanySurplusBalance(rut: $rut)
  }
`;

export const CESSIONS_SUMMARY = gql`
  query cessionsSummary(
    $companyId: Int!
  ) {
    cessionsSummary(companyId: $companyId) {
      amount
      invoiceCount
      debtCount
      debtAmount
      availableSurplus
      cantWithdrawSurplus
    }
  }
`;
