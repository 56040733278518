import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DocumentList } from '@fingo/lib/components/lists';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import useOrderingLucilaPreColumns from '@fingo/lib/constants/ordering-lucila-pre-columns';
import DebtorProfileSummary from './DebtorProfileSummary';

const DebtorProfileOrdering = ({ debtor, refetchDebtor }) => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    if (updateTrigger) {
      setSelectedDocumentIds([]);
      setUpdateTrigger(false);
    }
  }, [updateTrigger]);

  return (
    <DocumentList
      trackerId="COLLECTION_ORDERING"
      type="collection-debtor-profile"
      headerTitle={debtor.name}
      queryDocument={ORDERING_PURCHASE_ORDERS}
      allCompaniesOptions
      checkboxSelection
      onSelectionModelChange={(ids) => setSelectedDocumentIds(ids)}
      serverFilters={false}
      customVariables={{
        companyId_In: debtor.rut,
        inCollection: true,
        receiver_Rut: null,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
      }}
      includeHeaders={[
        'orderNumber',
        'purchaser_Name',
        'collectionPriority',
        'orderingoffer_InvoiceIssuedDate',
        'actionsDrawer',
        'publicationDate',
        'orderingoffer_OrderingFinancedAmount',
        'totalAmount',
      ]}
      mobileHeaders={[
        'orderNumber',
        'purchaser_Name',
        'orderingOperationStatus',
      ]}
      initialOrderBy="-publicationDate"
      getRowId={(row) => row.id}
      updateTrigger={updateTrigger}
      setUpdateTrigger={setUpdateTrigger}
      onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
      onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => (edge.node))}
      customSummaryElement={(
        <DebtorProfileSummary
          debtor={debtor}
          documentType="ordering"
          refetchDebtor={refetchDebtor}
          setTriggerRefetchDocument={setUpdateTrigger}
          selectedDocumentIds={selectedDocumentIds}
        />
    )}
      preColumns={useOrderingLucilaPreColumns()}
    />
  );
};

DebtorProfileOrdering.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  debtor: PropTypes.object.isRequired,
  refetchDebtor: PropTypes.func.isRequired,
};

export default DebtorProfileOrdering;
